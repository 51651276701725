import React, { ReactElement, useContext, useState } from 'react';
import 'yup-phone';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Button from '../../components/Button/Button';
import FormServerError from '../../components/Form/FormServerError';
import Modal from '../../components/Modal/Modal';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchList/ChurchListContextProvider';
import './ChurchDeleteModal.scss';
import supabase from '../../supabaseClient';

export default function ChurchDeleteModal(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_DELETE_MODAL');

  const { state, dispatch } = useContext(AppContext);
  const { dispatch: churchListDispatch } = useContext(ChurchListContext);

  const churchId = state.churchToDelete?.churchId ?? null;

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<Error | null>(null);

  const handleDelete = async () => {
    setIsSubmitted(true);

    try {
      await supabase.from('churches').delete().eq('church_id', churchId);
      dispatch({ type: AppActionTypes.CloseDeleteChurchModal });
      churchListDispatch({ type: ChurchListActionTypes.TriggerFetch });
    } catch (error) {
      setErrorResponse(error);
    } finally {
      setIsSubmitted(false);
    }
  };

  return (
    <Modal
      open={state.isDeleteChurchModalOpened}
      toggleClose={() => dispatch({ type: AppActionTypes.CloseDeleteChurchModal })}
      className="church-delete-modal"
      title={t('TEXT_DELETE_CHURCH')}
    >
      <>
        <div className="church-delete-modal-message">
          {t('TEXT_ARE_YOU_SURE', { name: state.churchToDelete?.name })}
        </div>
        <div className="church-delete-modal-actions">
          <Button
            onClick={() => dispatch({ type: AppActionTypes.CloseDeleteChurchModal })}
            color="blue-gray"
            type="button"
          >
            {t('BUTTON_CANCEL')}
          </Button>
          <Button onClick={handleDelete} color="red" isLoading={isSubmitted} type="button">
            {t('BUTTON_DELETE')}
          </Button>
        </div>
        {errorResponse && <FormServerError errorMessage={errorResponse.message} />}
      </>
    </Modal>
  );
}
