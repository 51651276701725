import React, { ReactElement, ReactNode } from 'react';
import './ReviewBoxHolder.scss';

export default function ReviewBoxHolder({ children }: { children: ReactNode }): ReactElement {
  return (
    <div className="review-box-holder">
      <div className="review-box">
        {children}
      </div>
    </div>
  );
}
