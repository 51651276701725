import { Expose, Type } from 'class-transformer';
import { Demographics } from './demographics';
import { Element } from './element';
import { KidsReport } from './kids-report';
import { ChurchSurveyFormReportQuestion } from './question';

export class Report {
  @Type(() => Demographics)
  @Expose()
  public demographics!: Demographics;

  @Type(() => Element)
  @Expose({ name: 'spiritual_leadership' })
  public spiritualLeadership!: Element;

  @Type(() => Element)
  @Expose({ name: 'personal_growth' })
  public personalGrowth!: Element;

  @Type(() => Element)
  @Expose({ name: 'missions_focus' })
  public missionsFocus!: Element;

  @Type(() => Element)
  @Expose({ name: 'loving_community' })
  public lovingCommunity!: Element;

  @Type(() => Element)
  @Expose({ name: 'worship_gathering' })
  public worshipGathering!: Element;

  @Type(() => Element)
  @Expose({ name: 'vision_alignment' })
  public visionAlignment!: Element;

  @Type(() => Element)
  @Expose({ name: 'alliance_partnership' })
  public alliancePartnership!: Element;

  @Type(() => Element)
  @Expose({ name: 'financial_stewardship' })
  public financialStewardship!: Element;

  @Type(() => Element)
  @Expose({ name: 'effective_organization' })
  public effectiveOrganization!: Element;

  @Type(() => Element)
  @Expose({ name: 'leadership_team_evaluation' })
  public leadershipTeamEvaluation!: Element;

  @Type(() => Element)
  @Expose({ name: 'volunteer_engagement' })
  public volunteerEngagement!: Element;

  @Type(() => KidsReport)
  @Expose({ name: 'peak_kids' })
  public peakKids!: KidsReport;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'adult_respondents' })
  public adultRespondents!: ChurchSurveyFormReportQuestion;

  constructor(data: Partial<Report> = {}) {
    Object.assign(this, data);
  }
}
