import React, { ReactElement, ReactNode } from 'react';
import { FieldInputProps } from 'formik/dist/types';
import FormCheckbox from './FormCheckbox';
import classNames from 'classnames';
import './Form.scss';
import FormLabel from './FormLabel';

interface Props {
  field: FieldInputProps<string | number>;
  children?: ReactNode;
  checked?: boolean;
  label?: string;
}

export default function FormCheckboxGroup({ field, children, checked, label }: Props): ReactElement {
  return(
    <div className={classNames('form-group', 'form-group-checkbox')}>
      {label && <FormLabel>{label}</FormLabel>}
      <div className={classNames({
        'form-control-container': !!label
      })}>
        <FormCheckbox {...field} checked={checked}>
          {children}
        </FormCheckbox>
      </div>
    </div>
  );
}
