import React, { ReactElement, ReactNode } from 'react';
import AppHeader from '../../containers/AppHeader/AppHeader';
import './PageLayout.scss';

export interface Props {
  children: ReactNode;
}

export default function MainPageLayout(props: Props): ReactElement {
  return (
    <div className="page-layout page-layout-main">
      <AppHeader />

      <div className="page-layout-main-content">
        {props.children}
      </div>
    </div>
  );
}
