import React, { ReactElement, ReactNode } from 'react';
import './Box.scss';

export interface Props {
  children: ReactNode;
}

export default function Box({ children }: Props): ReactElement {
  return (
    <div className="box">
      {children}
    </div>
  );
}
