import { Exclude, Expose, Type } from 'class-transformer';

export class Element {
  @Type(() => Element)
  @Expose()
  public children?: Array<Element>;

  @Expose()
  public label!: string;

  @Expose({ name: 'question_id'})
  public questionID?: string;
  
  @Expose()
  public get value(): number {

    return this._value ?? 0;
  }

  public set value(value: number) {
    this._value = value;
  }

  @Exclude()
  private _value?: number;

  constructor(data: Partial<Element> = {}) {
    Object.assign(this, data);
  }
}
