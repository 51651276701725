import { Expose } from 'class-transformer';

export class ChurchDistrict {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  constructor(model: Partial<ChurchDistrict> = {}) {
    Object.assign(this, model);
  }
}
