import React, { ReactElement } from 'react';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import ChurchListTableRows from '../ChurchListTableRows/ChurchListTableRows';
import './ChurchListTable.scss';

export default function ChurchListTable(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_TABLE');
  
  return (
    <div className="church-table">
      <div className="church-table-header">
        <h1>
          {t('TEXT_PEAK_PROFILE_DASHBOARD')}
        </h1>
      </div>
      <div className="church-table-content">
        <ChurchListTableRows />
      </div>
    </div>
  );
}
