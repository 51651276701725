import React, { ReactElement } from 'react';
import AuthPageLayout from '../../components/PageLayout/AuthPageLayout';
import LoginForm from '../../containers/AuthForm/AuthForm';

export default function LoginPage(): ReactElement {
  return (
    <div className="login">
      <AuthPageLayout>
        <LoginForm />
      </AuthPageLayout>
    </div>
  );
}
