import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import './ChurchReportModalPanel.scss';

export enum ReportPanelType {
  LEADERSHIP_DEMOGRAPHICS = 'leadershipDemographics',
  CONGREGATION_DEMOGRAPHICS = 'congregationDemographics',
  SUMMARY_REVIEW = 'summaryReview',
  ROLES_REVIEW = 'rolesReview',
  SPIRITUAL_LEADERSHIP = 'spiritualLeadership',
  PERSONAL_GROWTH = 'personalGrowth',
  MISSIONS_FOCUS = 'missionsFocus',
  LOVING_COMMUNITY = 'lovingCommunity',
  WORSHIP_GATHERING = 'worshipGathering',
  VISION_ALIGNMENT = 'visionAlignment',
  ALLIANCE_PARTNERSHIP = 'alliancePartnership',
  FINANCIAL_STEWARDSHIP = 'financialStewardship',
  EFFECTIVE_ORGANIZATION = 'effectiveOrganization',
  VOLUNTEER_ENGAGEMENT = 'volunteerEngagement',
  LEADERSHIP_TEAM_EVALUATION = 'leadershipTeamEvaluation',
  ADULT_RESPONDENTS = 'adultRespondents',
  KIDS_REVIEW = 'kidsReview',
  SURVEY_RESULTS = 'surveyResults'
}

interface IChurchReportModalPanelProperties {
  panelItems: Array<IReportPanelItem>;
  defaultPanelItemIndex: number;
  onSelect(reportPanelItem: IReportPanelItem): void;
}

interface IChurchReportModalPanelState {
  selectedPanelItemIndex: number; 
}

export interface IReportPanelItem {
  textKey: string;
  type: ReportPanelType;
  component: ReactElement;
  respondents?: number;
}

export default function ChurchReportModalPanel({ onSelect, panelItems, defaultPanelItemIndex }: IChurchReportModalPanelProperties): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL.TITLES');
  const [state, setState] = useState<IChurchReportModalPanelState>({ selectedPanelItemIndex: defaultPanelItemIndex });

  const onPanelItemClick = (value: IReportPanelItem, index: number): void => {
    onSelect(value); 
    setState({
      selectedPanelItemIndex: index
    });
  };
  
  return (
    <div className="church-report-modal-panel">
      {
        panelItems.map((value: IReportPanelItem, index: number): ReactNode => 
          {
            const panelItemClassName = classNames('church-report-modal-panel-item', (index === state.selectedPanelItemIndex) ? 'selected' : '');
            
            return (
              <p 
                key={index} 
                className={panelItemClassName} 
                onClick={(): void => { 
                  onPanelItemClick(value, index);
                }}
              >
                {t(value.textKey)}
              </p>
            );
          }
        )
      }
    </div>
  );
}
