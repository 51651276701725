import { DateTime } from 'luxon';
import { SurveyQuestion } from './Question';

export class Survey {
  public churchName: string;
  public formName: string;
  public dateCreated: DateTime;
  public analyseURL: string;
  public questionsWithAnswers: Array<SurveyQuestion>;

  constructor(model: Partial<Survey> = {}) {
    Object.assign(this, model);
  }
}
