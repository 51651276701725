import classNames from 'classnames/bind';
import React, { createElement, forwardRef, ReactElement, ReactNode, RefObject } from 'react';
import './Button.scss';

export interface Props {
  children: ReactNode;
  color: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: ReactNode;
  onClick?: () => void;
  href?: string;
  isBlock?: boolean;
  type?: 'button' | 'submit',
  isDisabled?: boolean;
  isLoading?: boolean;
}

export type ButtonColor = 'blue-gray' | 'lightest-grey' | 'green' | 'red';
export type ButtonVariant = 'outline' | 'filled';
export type ButtonSize = 'small' | 'normal';

const Button = forwardRef(({ ...props }: Props, ref: RefObject<any>): ReactElement => {
  const classes = classNames([
    'button',
    `button-${props.variant ?? 'filled'}`,
    `button-${props.color}`,
    `button-${props.size ?? 'normal'}`,
    {
      'button-block': props.isBlock,
      'button-disabled': props.isDisabled,
      'button-loading': props.isLoading,
      'button-with-icon': !!props.icon
    }
  ]);

  return createElement(
    (props.href) ? 'a' : 'button',
    {
      onClick: props?.onClick && props.onClick,
      href: props?.href,
      ref,
      type: props.type || 'button',
      className: classes
    },
    <>
      {(!!props.icon) && <span className="button-icon">{props.icon}</span>}
      {props.children}
    </>

  );
});

export default Button;
