import { TransformationType } from 'class-transformer';
import { DateTime } from 'luxon';

export default function DateTransformer(
  value: string | DateTime | { _seconds: number; _nanoseconds: number } | undefined | any,
  _: unknown,
  transformationType: TransformationType
): DateTime | string | '' {

  if (!value) {
    return '';
  }

  // Handle timestamp object format
  if (typeof value === 'object' && '_seconds' in value) {
    return DateTime.fromSeconds(value._seconds, { zone: 'utc' }).toUTC();
  }

  // Handle ISO string format and DateTime object
  switch (transformationType) {
    case TransformationType.PLAIN_TO_CLASS:
      if (typeof value === 'string') {
        return DateTime.fromISO(value, { zone: 'utc' });
      }
      break;
    case TransformationType.CLASS_TO_PLAIN:
      if (DateTime.isDateTime(value)) {
        return value.toISO(); // Changed to `toISO` for full datetime string
      }
      break;
  }

  return '';
}
