import { Field, FormikProps, FormikValues } from 'formik';
import { capitalize, debounce } from 'lodash';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContextProvider';
import Button from '../../../components/Button/Button';
import Form from '../../../components/Form/Form';
import FormButtons from '../../../components/Form/FormButtons';
import FormGroup from '../../../components/Form/FormGroup';
import Col from '../../../components/Grid/Col';
import Row from '../../../components/Grid/Row';
import Icon from '../../../components/Icon/Icon';
import { ChurchStatus } from '../../../enums/ChurchStatus';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import { ChurchDistrict } from '../../../models/church/ChurchDistrict';
import { ChurchesFilters } from '../../../models/church/ChurchesFilters';
import { SelectItem } from '../../../models/SelectItem';
import { SurveyMonkeyDistrict } from '../../../models/SurveyMonkeyDistrict';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchListContextProvider';
import ChurchListFiltersModal from '../ChurchListFiltersModal/ChurchListFiltersModal';
import './ChurchListFilters.scss';
import supabase from '../../../supabaseClient';

export default function ChurchListFilters(): ReactElement {
  const { state, dispatch } = useContext(ChurchListContext);
  const { state: appState } = useContext(AppContext);
  const [districts, setDistricts] = useState<Array<SelectItem<ChurchDistrict>>>([]);
  const [countOfAppliedAdditionalFilters, setCountOfAppliedAdditionalFilters] = useState<number>(0);
  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_FILTERS');

  useEffect(() => {
    prepareDistricts();
  }, []);

  useEffect(() => {
    updateCountOfAppliedAdditionalFilters();
  }, [state.filters]);

  const updateCountOfAppliedAdditionalFilters = (): void => {
    let count = 0;

    if (state.filters?.averageAttendance?.length) {
      count += 1;
    }

    if (state.filters?.isCompletedCoaching) {
      count += 1;
    }

    if (state.filters?.tags?.length) {
      count += 1;
    }

    setCountOfAppliedAdditionalFilters(count);
  };

  const prepareDistricts = async (): Promise<void> => {
    const { data: districtsData, error } = await supabase
      .from('districts')
      .select('*') // Adjust if you need specific fields
      .order('name', { ascending: true });

    if (error) throw error;

    const districtsSelectItems = districtsData.map((item) => {
      // Directly use the item data assuming it matches the SurveyMonkeyDistrict structure.
      const surveyMonkeyDistrict = new SurveyMonkeyDistrict(item);
      const district = new ChurchDistrict({
        name: surveyMonkeyDistrict.name,
        id: item.id
      });

      return new SelectItem<ChurchDistrict>('district', district.name, district);
    });

    setDistricts(districtsSelectItems);
  };

  const getStatuses = (): Array<SelectItem> => [
    new SelectItem('status', t('TEXT_ALL_STATUSES'), ''),
    ...Object.values(ChurchStatus).map((item) => new SelectItem('status', capitalize(item), item))
  ];

  const getAvailableDistrictSelectValues = (): Array<SelectItem<ChurchDistrict | string>> => [
    new SelectItem('district', t('TEXT_ALL_DISTRICTS'), new ChurchDistrict()),
    ...districts
  ];

  const updateNameFilter = debounce(async (formProps: FormikProps<FormikValues>): Promise<void> => {
    await formProps.submitForm();
  }, 500);

  const applyFilters = (values) => {
    dispatch({ type: ChurchListActionTypes.SetCurrentPage, payload: { page: 1 } });
    const filters = new ChurchesFilters({
      ...state.filters,
      ...values
    });

    if (values.name) {
      filters.averageAttendance = [];
      filters.orderBy = null;
    }
    dispatch({ type: ChurchListActionTypes.SetIsLoading, payload: { value: true } });
    dispatch({ type: ChurchListActionTypes.SetFilters, payload: { filters } });
    dispatch({ type: ChurchListActionTypes.TriggerFetch });
  };

  return (
    <Form initialValues={state.filters} onSubmit={applyFilters} className="church-filters-form">
      {(props: FormikProps<FormikValues>): ReactElement => (
        <Row>
          <Col size={status ? 55 : 30} tabletSize={status ? 100 : 50}>
            <Field
              name="name"
              component={FormGroup}
              onChange={() => updateNameFilter(props)}
              type="text"
              size="small"
              icon={<Icon variant="search" />}
              placeholder={t('TEXT_SEARCH')}
              error={props.errors.name}
              touched={props.touched.name}
              value={props.values.name}
            />
          </Col>
          {!appState.userClaims?.isDistrictUser && (
            <Col size={25} tabletSize={50}>
              <Field
                name="district"
                component={FormGroup}
                onChange={props.submitForm}
                type="select"
                selectedByDefaultItemIndex={0}
                options={getAvailableDistrictSelectValues()}
                size="small"
                error={props.errors.district}
                touched={props.touched.district}
                value={props.values.district}
              />
            </Col>
          )}
          <Col size={45} tabletSize={100}>
            <Row>
              <Col size={50}>
                <Field
                  name="status"
                  component={FormGroup}
                  onChange={props.submitForm}
                  type="select"
                  selectedByDefaultItemIndex={0}
                  options={getStatuses()}
                  size="small"
                  error={props.errors.status}
                  touched={props.touched.status}
                  value={props.values.status}
                />
              </Col>
              <Col size={50}>
                <FormButtons>
                  <Button
                    variant={countOfAppliedAdditionalFilters ? 'filled' : 'outline'}
                    color={countOfAppliedAdditionalFilters ? 'green' : 'lightest-grey'}
                    size="small"
                    icon={<Icon variant="filter" />}
                    onClick={() => dispatch({ type: ChurchListActionTypes.OpenApplyFiltersModal })}
                    isBlock
                  >
                    {countOfAppliedAdditionalFilters
                      ? `${countOfAppliedAdditionalFilters} ${t('BUTTON_FILTERS_APPLIED')}`
                      : t('BUTTON_APPLY_FILTERS')}
                  </Button>

                  <ChurchListFiltersModal />
                </FormButtons>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  );
}
