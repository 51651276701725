import { Type } from 'class-transformer';
import { Element } from './element';

export class KidsReport {
  @Type(() => Element)
  public children!: Element;

  @Type(() => Element)
  public youth!: Element;

  constructor(data: Partial<KidsReport> = {}) {
    Object.assign(this, data);
  }
}
