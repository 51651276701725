import React, { ReactElement, useEffect } from 'react';
import { Form as FormikForm, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import classNames from 'classnames';
import './Form.scss';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';

export interface Props {
  children: (_props: FormikProps<FormikValues>) => ReactElement | Array<ReactElement>;
  initialValues: FormikValues;
  onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void;
  validationSchema?: ObjectSchema<ObjectShape>;
  updateErrorResponse?: (_value: Error) => void;
  className?: string;
  isDisabled?: boolean;
}

export default function Form({
  children,
  initialValues,
  updateErrorResponse,
  validationSchema,
  onSubmit,
  className,
  isDisabled
}: Props): ReactElement {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props: FormikProps<FormikValues>): ReactElement => {
        const { values } = props;

        useEffect(() => {
          updateErrorResponse && updateErrorResponse(null);
        }, [values]);

        return (
          <FormikForm
            className={classNames('form', className ?? '', { 'form-disable': isDisabled })}
          >
            {children(props)}
          </FormikForm>
        );
      }}
    </Formik>
  );
}
