import React, { ReactElement, ReactNode } from 'react';
import './Table.scss';
import classNames from 'classnames';

export interface Props {
  children: ReactNode;
  withLink?: true;
  rowOnClick?: () => void;
  isSelected?: boolean;
}

export default function TableRow({ children, withLink, rowOnClick, isSelected }: Props): ReactElement {
  return (
    <div className="table-row">
      {withLink &&
        <a
          className={classNames(
            'table-link',
            { 'table-link-selected': isSelected }
          )}
          onClick={() => rowOnClick()}
        />
      }
      {children}
    </div>
  );
}
