import { ChurchSurveyFormReportAnswer } from '../../../models/report/answer';
import { ChartData } from '../../../models/report/chart-data';
import { Demographics } from '../../../models/report/demographics';

interface IDemographicChartData {
  gender: Array<ChartData>;
  age: Array<ChartData>;
  origin: Array<ChartData>;
  familySystem: Array<ChartData>;
  isMember: Array<ChartData>;
  yearsAsChristian: Array<ChartData>;
  yearsAsAttend: Array<ChartData>;
  commute: Array<ChartData>;
}

function mapReportDataForSimpleChart({ data, color }: { data: Array<ChurchSurveyFormReportAnswer>; color?: string | Array<string>; }, isCompact?: boolean): Array<ChartData> {
  if (!data) {
    return [];
  }

  return data.map((value: ChurchSurveyFormReportAnswer, index: number): ChartData => {
    const { count, text } = value;

    return {
      value: count,
      label: (isCompact)
        ? text.replace('more', '>')
          .replace('less', '<')
          .replace(/[^\d-<>]/g, '')
          .replace(/>(\d+)/, '$1+')
        : text,
      color: typeof color === 'object' ? color[index] : color
    };
  });
}

export function mapDemographicReportData({ demographics }: { demographics: Demographics }): IDemographicChartData {
  const {
    gender,
    age,
    countryOfOrigin,
    martialStatus,
    isMembership,
    howLongHaveBeenChristian,
    howLongHaveUouAttendedThisChurch,
    howLongIsYourCommuteToChurch
  } = demographics;

  const yearsAsAttendData = howLongHaveUouAttendedThisChurch
    ? mapReportDataForSimpleChart({
      data: howLongHaveUouAttendedThisChurch.answerOptions,
      color: '#f0b237'
    }, true)
    : [];

  return {
    gender: mapReportDataForSimpleChart({ data: gender.answerOptions, color: ['#415159', '#7e8246'] }),
    age: mapReportDataForSimpleChart({ data: age.answerOptions, color: '#7e8246' }),
    origin: mapReportDataForSimpleChart({ data: countryOfOrigin.answerOptions }).map((item) => ({ ...item, label: item.label.replace('- Please specify below', '') })),
    familySystem: mapReportDataForSimpleChart({ data: martialStatus.answerOptions }),
    isMember: mapReportDataForSimpleChart({ data: isMembership.answerOptions, color: ['#415159', '#7e8246'] }),
    yearsAsChristian: mapReportDataForSimpleChart({ data: howLongHaveBeenChristian.answerOptions, color: '#dbd3d3' }, true),
    yearsAsAttend: howLongHaveUouAttendedThisChurch.answerOptions
      ? yearsAsAttendData.slice(0, howLongHaveUouAttendedThisChurch.answerOptions.length - 1)
      : [],
    commute: mapReportDataForSimpleChart({ data: howLongIsYourCommuteToChurch.answerOptions, color: '#7e8246' }, true)
  };
}
