import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Grid.scss';

export interface Props {
  size: ColSizes;
  children?: ReactNode;
  tabletSize?: TabletColSizes;
  mobileSize?: MobileColSizes;
}

export type ColSizes = 17 | 20 | 25 | 30 | 33 | 40 | 45 | 50 | 55 | 60 | 66 | 70 | 75 | 80;
export type TabletColSizes = 17 | 25 | 33 | 50 | 66 | 75 | 100;
export type MobileColSizes = 17 | 25 | 33 | 50 | 66 | 75;

export default function Col({ children, size, tabletSize, mobileSize }: Props): ReactElement {
  return (
    <div
      className={
        classNames(
          'col',
          `col-${size}`,
          tabletSize && `col-tablet-${tabletSize}`,
          mobileSize && `col-mobile-${mobileSize}`
        )
      }
    >
      {children}
    </div>
  );
}
