import React, { ReactElement, useContext, useState } from 'react';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Col from '../../components/Grid/Col';
import Row from '../../components/Grid/Row';
import Modal from '../../components/Modal/Modal';
import DemographicBox from '../../components/Report/DemographicBox/DemographicBox';
import ElementBox from '../../components/Report/ElementBox';
import ElementBoxHolder from '../../components/Report/holders/ElementBoxHolder/ElementBoxHolder';
import ReviewBoxHolder from '../../components/Report/holders/ReviewBoxHolder/ReviewBoxHolder';
import KidsChartBox from '../../components/Report/KidsChartBox';
import KidsInfoBox from '../../components/Report/KidsInfoBox';
import ReviewBox from '../../components/Report/ReviewBox';
import { ElementUseFor } from '../../components/Svg/Svg';
import { getCalculatedReportData, ICalculatedReport } from '../../helpers/ChurchReport/ChurchReportMapper';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { ChurchListContext } from '../ChurchList/ChurchListContextProvider';
import './ChurchReportModal.scss';
import ChurchReportModalComment from './ChurchReportModalComment/ChurchReportModalComment';
import ChurchReportModalPanel, { IReportPanelItem } from './ChurchReportModalPanel/ChurchReportModalPanel';
import { ReportPanelType } from './ChurchReportModalPanel/ChurchReportModalPanel';

export default function ChurchReportModal(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL');
  const tTitle = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL.TITLES');
  const tDescription = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL.DESCRIPTIONS');

  const { dispatch: appContextDispatch } = useContext(AppContext);
  const { state } = useContext(ChurchListContext);

  const USE_FOR: ElementUseFor = ElementUseFor.BROWSER;
  
  const reportData: ICalculatedReport = state.selectedChurch ? getCalculatedReportData({ church: state.selectedChurch }) : {} as ICalculatedReport;

  const panelItems: Array<IReportPanelItem> = [
    {
      textKey: 'TEXT_LEADERSHIP_DEMOGRAPHIC',
      respondents: reportData.leadershipDemographics.gender.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0),
      type: ReportPanelType.LEADERSHIP_DEMOGRAPHICS,
      component: <DemographicBox demographics={reportData.leadershipDemographics} useFor={USE_FOR}/>
    }, 
    {
      textKey: 'TEXT_CONGREGATION_DEMOGRAPHIC',
      respondents: reportData.congregationDemographics.gender.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0),
      type: ReportPanelType.CONGREGATION_DEMOGRAPHICS,
      component: <DemographicBox demographics={reportData.congregationDemographics} useFor={USE_FOR}/>
    }, 
    {
      textKey: 'TEXT_SUMMARY_REVIEW',
      type: ReportPanelType.SUMMARY_REVIEW,
      component: 
        <ReviewBoxHolder>
          <ReviewBox values={reportData.summaryReview?.values} labels={reportData.summaryReview?.labels} useFor={USE_FOR}/>
        </ReviewBoxHolder>
    }, 
    {
      textKey: 'TEXT_ROLES_REVIEW',
      type: ReportPanelType.ROLES_REVIEW,
      component: 
        <ReviewBoxHolder>
          <ReviewBox values={reportData.rolesReview?.values} labels={reportData.rolesReview?.labels} useFor={USE_FOR}/>
        </ReviewBoxHolder>
    }, 
    {
      textKey: 'TEXT_SPIRITUAL_LEADERSHIP',
      type: ReportPanelType.SPIRITUAL_LEADERSHIP,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.spiritualLeadershipElement} useFor={USE_FOR} />
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_PERSONAL_GROWTH',
      type: ReportPanelType.PERSONAL_GROWTH,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.personalGrowthElement} useFor={USE_FOR} />
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_MISSION_FOCUS',
      type: ReportPanelType.MISSIONS_FOCUS,
      component:
        <ElementBoxHolder>
          <ElementBox element={reportData.missionsFocusElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_LOVING_COMMUNITY',
      type: ReportPanelType.LOVING_COMMUNITY,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.lovingCommunityElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_WORSHIP_GATHERING',
      type: ReportPanelType.WORSHIP_GATHERING,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.worshipGatheringElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_VISION_ALIGNMENT',
      type: ReportPanelType.VISION_ALIGNMENT,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.visionAlignmentElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_ALLIANCE_PARTNERSHIP',
      type: ReportPanelType.ALLIANCE_PARTNERSHIP,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.alliancePartnershipElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_FINANCIAL_STEWARDSHIP',
      type: ReportPanelType.FINANCIAL_STEWARDSHIP,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.financialStewardshipElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_EFFECTIVE_ORGANIZATION',
      type: ReportPanelType.EFFECTIVE_ORGANIZATION,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.effectiveOrganizationElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_VOLUNTEER_ENGAGEMENT',
      type: ReportPanelType.VOLUNTEER_ENGAGEMENT,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.volunteerEngagementElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }, 
    {
      textKey: 'TEXT_ADULT_RESPONDENTS',
      type: ReportPanelType.ADULT_RESPONDENTS,
      component:
        <ReviewBoxHolder>
          <KidsInfoBox values={reportData.adultRespondents} useFor={USE_FOR}/>
        </ReviewBoxHolder>
    }, 
    {
      textKey: 'TEXT_KIDS_REVIEW',
      type: ReportPanelType.KIDS_REVIEW,
      component: 
        <ReviewBoxHolder>
          <KidsChartBox values={reportData.kidsReview?.values} labels={reportData.kidsReview?.labels} useFor={USE_FOR}/>
        </ReviewBoxHolder>
    }, 
    {
      textKey: 'TEXT_LEADERSHIP_TEAM_EVALUATION',
      type: ReportPanelType.LEADERSHIP_TEAM_EVALUATION,
      component: 
        <ElementBoxHolder>
          <ElementBox element={reportData.leadershipTeamEvaluationElement} useFor={USE_FOR}/>
        </ElementBoxHolder>
    }
    // {
    //   textKey: 'SURVEY_RESULTS',
    //   type: ReportPanelType.SURVEY_RESULTS,
    //   component: 
    //     <ElementBoxHolder>
    //       <ChurchSurveyDetails />
    //     </ElementBoxHolder>
    // }
  ];

  const [currentPanelItem, setCurrentPanelItem] = useState<IReportPanelItem>(panelItems[0]);

  return (
    <Modal 
      open={true} 
      toggleClose={(): void => {
        appContextDispatch({ type: AppActionTypes.CloseChurchReportModal });
      }}
      title={`${t('TEXT_TITLE')}: ${state?.selectedChurch?.name}`}
      className="church-report-modal"
    >
      <div className="church-report-modal-box">
        <Row>
          <Col size={20}>
            <div className="panel-holder">
              <ChurchReportModalPanel
                panelItems={panelItems}
                defaultPanelItemIndex={0} 
                onSelect={(reportPanelItem): void => {
                  setCurrentPanelItem(reportPanelItem);
                }}
              />
            </div>
          </Col>
          <Col size={80}>
            <div className="report-holder">
              <p className="report-holder-title">
                {tTitle(currentPanelItem.textKey)}
              </p>
              <p className="report-holder-description">
                { currentPanelItem.respondents ? `${tDescription('TEXT_RESPONDENTS')}: ${currentPanelItem.respondents}` : tDescription(currentPanelItem.textKey)}
              </p>
              {currentPanelItem.component}
            </div>
          </Col>
        </Row>
        <div className="comment-holder">
          <ChurchReportModalComment church={state.selectedChurch} type={currentPanelItem.type} />
        </div>
      </div>
    </Modal>
  );
}
