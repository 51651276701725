import React, { ReactElement } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Box from '../../components/Box/Box';
import ChurchDeleteModal from '../ChurchDeleteModal/ChurchDeleteModal';
import ChurchEditModal from '../ChurchEditModal/ChurchEditModal';
import SurveyViewModal from '../SurveyViewModal/SurveyViewModal';
import './ChurchList.scss';
import { ChurchListContextProvider } from './ChurchListContextProvider';
import ChurchListDetails from './ChurchListDetails/ChurchListDetails';
import ChurchListFilters from './ChurchListFilters/ChurchListFilters';
import ChurchListPagination from './ChurchListPagination/ChurchListPagination';
import ChurchListTable from './ChurchListTable/ChurchListTable';

export function ChurchList(): ReactElement {
  return (
    <ChurchListContextProvider>
      <div className="church-list">
        <div className="church-list-filters">
          <Box>
            <ChurchListFilters />
          </Box>
        </div>
        <div className="church-list-content">
          <Scrollbars style={{ width: '100%', height: '100%' }} autoHide autoHideTimeout={100}>
            <Box>
              <ChurchListTable />
            </Box>
            <ChurchListPagination />
          </Scrollbars>
        </div>
        <div className="church-list-info">
          <Scrollbars style={{ width: '100%', height: '100%' }} autoHide autoHideTimeout={100}>
            <Box>
              <ChurchListDetails />
            </Box>
          </Scrollbars>
        </div>
      </div>
      <ChurchEditModal />
      <ChurchDeleteModal />
      <SurveyViewModal />
    </ChurchListContextProvider>
  );
}
