import { Expose, Type } from 'class-transformer';
import { ChurchFormQuestionSubType } from '../../enums/ChurchFormQuestionSubType';
import { ChurchFormQuestionType } from '../../enums/ChurchFormQuestionType';
import { ChurchFormAnswerOption } from './ChurchFormAnswerOption';

export class ChurchFormQuestion {
  @Expose()
  public id!: string;

  @Expose({ name: 'origin_id' })
  public originID!: string;

  @Expose({ name: 'page_id' })
  public pageID!: string;

  @Expose({ name: 'page_position' })
  public pagePosition!: number;

  @Expose()
  public position!: number;

  @Expose()
  public question!: string;

  @Expose({ name: 'answer_options' })
  @Type(() => ChurchFormAnswerOption)
  public answerOptions!: Array<ChurchFormAnswerOption> | null;

  @Expose({ name: 'sub_questions' })
  @Type(() => ChurchFormQuestion)
  public subQuestions!: Array<ChurchFormQuestion> | null;

  @Expose()
  public type!: ChurchFormQuestionType;

  @Expose({ name: 'sub_type' })
  public subType!: ChurchFormQuestionSubType;

  constructor(data: Partial<ChurchFormQuestion> = {}) {
    Object.assign(this, data);
  }
}
