import React, { ReactElement, ReactNode } from 'react';
import './Table.scss';

export interface Props {
  children: ReactNode;
}

export default function TableHead({ children }: Props): ReactElement {
  return (
    <div className="table-head">
      {children}
    </div>
  );
}
