import React, { ReactElement } from 'react';
import AuthPageLayout from '../../components/PageLayout/AuthPageLayout';
import PasswordResetConfirm from './PasswordResetConfirm';

export default function PasswordResetConfirmPage(): ReactElement {
  return (
    <div className="password-reset-confirm">
      <AuthPageLayout>
        <PasswordResetConfirm />
      </AuthPageLayout>
    </div>
  );
}
