import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import Text from '../../../Svg/Elements/Text';
import View from '../../../Svg/Elements/View';
import { ElementUseFor } from '../../../Svg/Svg';
import './DemographicCard.scss';

export default function DemographicCard({ text, children, useFor = ElementUseFor.PDF }: {
  text: string;
  children: ReactElement;
  useFor?: ElementUseFor;
}): ReactElement {
  const pdfStyles = StyleSheet.create({
    card: {
      flexGrow: 0,
      flexShrink: 0,
      padding: '15px',
      height: 235,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cardTitle: {
      color: '#222222',
      fontWeight: 500,
      fontSize: 16,
      height: 55,
      textAlign: 'center',
      width: 150
    },
    cardBody: {
      height: 190
    }
  });

  const browserStyles = {
    ...pdfStyles,
    card: {
      ...pdfStyles.card,
      height: '100%',
      boxSizing: 'border-box'
    },
    cardBody: {
      ...pdfStyles.cardBody,
      height: 'auto',
      width: '100%',
      textAlign: 'center',
      top: 'calc(50% - 55px)',
      transform: 'translateY(-50%)'
    }
  };

  const styles = useFor === ElementUseFor.PDF ? pdfStyles : browserStyles;

  return (
    <View className="demographics-card" style={styles.card} useFor={useFor}>
      <Text style={styles.cardTitle} useFor={useFor} insideSVG={false}>
        {text}
      </Text>
      <View style={styles.cardBody} useFor={useFor}>
        {children}
      </View>
    </View>
  );
}
