import { Expose } from 'class-transformer';

export class ChurchCollector {
  @Expose()
  public id: string;

  @Expose()
  public url: string;

  constructor(data: Partial<ChurchCollector> = {}) {
    Object.assign(this, data);
  }
}
