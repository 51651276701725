import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './Icon.scss';

export interface Props {
  variant: IconVariant;
  mask?: Mask;
}

export type IconVariant = (
  'user' | 'lock' | 'search' | 'filter' | 'arrow-down' |
  'arrow-up' | 'arrow-right' | 'arrow-left' | 'calendar' | 'en' | 'es'
);
export type Mask = 'black' | 'light-gray' | 'blue-gray' | 'primary';

export default function Icon(props: Props): ReactElement {
  return (
    <span className={classNames(
      'icon',
      `icon-${props.variant}`,
      {
        'icon-mask': !!props.mask,
        [`icon-mask-${props.mask}`]: !!props.mask
      }
    )} />
  );
}
