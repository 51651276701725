import { Church } from '../../models/church/Church';
import { DemographicsData } from '../../models/report/demographics-data';
import { ElementData } from '../../models/report/element-data';
import { IAdultRespondentsData, mapAdultRespondentsData } from './Functions/mapAdultRespondentsData';
import { mapDemographicReportData } from './Functions/mapDemographicReportData';
import { mapKidsReviewReportData } from './Functions/mapKidsReviewReportData';
import { mapReportDataForBarChart, mapReportDataForSingleBarChart } from './Functions/mapReportDataForBarChart';
import { ISummaryData, mapSummaryReportData } from './Functions/mapSummaryReportData';

export interface ICalculatedReport {
  leadershipDemographics: DemographicsData;
  congregationDemographics: DemographicsData;
  summaryReview: ISummaryData;
  rolesReview: ISummaryData;
  spiritualLeadershipElement: ElementData;
  personalGrowthElement: ElementData;
  missionsFocusElement: ElementData;
  lovingCommunityElement: ElementData;
  worshipGatheringElement: ElementData;
  visionAlignmentElement: ElementData;
  alliancePartnershipElement: ElementData;
  financialStewardshipElement: ElementData;
  effectiveOrganizationElement: ElementData;
  volunteerEngagementElement: ElementData;
  kidsReview: ISummaryData;
  adultRespondents: IAdultRespondentsData;
  leadershipTeamEvaluationElement: ElementData;
}

export function getCalculatedReportData({ church }: { church: Church }): ICalculatedReport {
  const { leaderReport, congregationReport, combinedReport } = church;

  return {
    leadershipDemographics: mapDemographicReportData({ demographics: leaderReport.demographics }),
    congregationDemographics: mapDemographicReportData({ demographics: congregationReport.demographics }),
    summaryReview: mapSummaryReportData({ 
      data: [combinedReport], colors: ['#7e8246'], labels: ['Summary'] 
    }),
    rolesReview: mapSummaryReportData({ 
      data: [leaderReport, congregationReport], 
      colors: ['#7e8246', '#415159'], 
      labels: ['Leadership', 'Non-Leadership'] 
    }),
    spiritualLeadershipElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.spiritualLeadership, 
        congregation: congregationReport.spiritualLeadership 
      }
    }),
    personalGrowthElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.personalGrowth, 
        congregation: congregationReport.personalGrowth 
      }
    }),
    missionsFocusElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.missionsFocus, 
        congregation: congregationReport.missionsFocus 
      }
    }),
    lovingCommunityElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.lovingCommunity, 
        congregation: congregationReport.lovingCommunity 
      }
    }),
    worshipGatheringElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.worshipGathering, 
        congregation: congregationReport.worshipGathering 
      }
    }),
    visionAlignmentElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.visionAlignment, 
        congregation: congregationReport.visionAlignment 
      }
    }),
    alliancePartnershipElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.alliancePartnership, 
        congregation: congregationReport.alliancePartnership 
      }
    }),
    financialStewardshipElement: mapReportDataForBarChart({
      data:{ 
        leader: leaderReport.financialStewardship, 
        congregation: congregationReport.financialStewardship 
      }
    }),
    effectiveOrganizationElement: mapReportDataForBarChart({
      data: { 
        leader: leaderReport.effectiveOrganization, 
        congregation: congregationReport.effectiveOrganization 
      }
    }),
    volunteerEngagementElement: mapReportDataForSingleBarChart({
      data: congregationReport.volunteerEngagement,
      label: 'Non-Leadership',
      color: '#415159'
    }),
    kidsReview: mapKidsReviewReportData(combinedReport.peakKids),
    adultRespondents: {
      congregation: mapAdultRespondentsData(congregationReport.adultRespondents),
      leadership: mapAdultRespondentsData(leaderReport.adultRespondents)
    },
    leadershipTeamEvaluationElement: mapReportDataForSingleBarChart({
      data: leaderReport.leadershipTeamEvaluation,
      label: 'Leadership',
      color: '#7e8246'
    })
  };
}
