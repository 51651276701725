import { StyleSheet } from '@react-pdf/renderer';
import { range } from 'lodash';
import React, { ReactElement } from 'react';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import { ChartArrayData } from '../../../models/report/chart-array-data';
import Legends from '../Legends';
import G from '../../Svg/Elements/G';
import Line from '../../Svg/Elements/Line';
import Rect from '../../Svg/Elements/Rect';
import Text from '../../Svg/Elements/Text';
import View from '../../Svg/Elements/View';
import Svg, { ElementUseFor } from '../../Svg/Svg';
import './GraphChart.scss';
import { LegendAlign } from '../../../enums/LegendAlign';

export default function GraphChart({ values, labels, useFor }: {
  values: Array<ChartArrayData>;
  labels: Array<string>;
  useFor: ElementUseFor;
}): ReactElement {
  const t = useTranslationByKey('COMPONENTS.REPORT.GRAPH_CHART');
  const PADDING = 15;
  const HEIGHT_BAR = useFor === ElementUseFor.PDF ? 9 : 13;
  const SCALE_GRAPH = useFor === ElementUseFor.PDF ? 90 : 100;
  const SCALE_NUMBER = 5;
  const GAP_BETWEEN_RELATED_BAR = 2;
  const GAP_BETWEEN_BAR = useFor === ElementUseFor.PDF ? 5 : 10;
  const STEP_GRAPH = (HEIGHT_BAR * values.length) + (GAP_BETWEEN_RELATED_BAR * (values.length - 1)) + GAP_BETWEEN_BAR;
  const VALUE_LABEL_PADDING = 2;
  const LEFT_BAR_WIDTH = useFor === ElementUseFor.PDF ? 400 : '50%';
  const RIGHT_BAR_WIDTH = useFor === ElementUseFor.PDF ? 'auto' : 'auto';
  const EXTRA_SCALE = 0.4;

  const maxHeight = values[0].values.length * STEP_GRAPH;

  const pdfStyles = StyleSheet.create({
    element: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: useFor === ElementUseFor.PDF ? 'flex-end' : 'flex-start'
    },
    top: {
      fontSize: 8,
      height: 50
    },
    leftBar: {
      display: 'flex',
      paddingTop: PADDING,
      width: LEFT_BAR_WIDTH,
      marginTop: 62
    },
    label: {
      height: STEP_GRAPH - GAP_BETWEEN_BAR,
      textAlign: 'right',
      marginBottom: GAP_BETWEEN_BAR,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    rightBar: {
      width: RIGHT_BAR_WIDTH
    },
    graph: {
      marginTop: 10
    },
    topTitle: {
      marginTop: 15,
      marginBottom: 10,
      paddingLeft: PADDING
    },
    scale: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: PADDING
    },
    scaleLabel: {
      fontSize: 8,
      textAnchor: 'middle'
    },
    none: {
      width: SCALE_GRAPH,
      borderBottom: '8px solid #eaeaea',
      paddingBottom: 10
    },
    low: {
      width: SCALE_GRAPH * 1.4,
      borderBottom: '8px solid #cacaca',
      paddingBottom: 10
    },
    moderate: {
      width: SCALE_GRAPH * 0.6,
      borderBottom: '8px solid #a0a0a0',
      paddingBottom: 10
    },
    strong: {
      width: SCALE_GRAPH,
      borderBottom: '8px solid #858585',
      paddingBottom: 10
    },
    value: {
      fontSize: useFor === ElementUseFor.PDF ? 7 : 11,
      fontWeight: 400,
      textAnchor: 'end',
      dominantBaseline: 'text-before-edge'
    }
  });

  const browserStyle = {
    ...pdfStyles,
    leftBar: {
      ...pdfStyles.leftBar,
      paddingTop: 2 * PADDING,
      flexDirection: 'column'
    },
    label: {
      ...pdfStyles.label,
      display: 'grid'
    }
  };

  const styles = useFor === ElementUseFor.PDF ? pdfStyles : browserStyle;

  const graphWidth = SCALE_GRAPH * 4;
  const paddingBoth = PADDING * 2;

  const svgWidth = graphWidth + paddingBoth;
  const svgHeight = maxHeight + paddingBoth + (PADDING - HEIGHT_BAR);

  function getXPosition(position: number): number {
    let extraValue = 0;

    if (position === 2) {
      extraValue += EXTRA_SCALE;
    }

    return ((position + extraValue) * SCALE_GRAPH) + PADDING;
  }

  function getYPosition(position: number, order: number): number {
    return PADDING + (STEP_GRAPH * position) + (HEIGHT_BAR * order) + (GAP_BETWEEN_RELATED_BAR * order) + GAP_BETWEEN_RELATED_BAR;
  }

  function getBarWidth(value: number): number {
    // console.log(value);
    
    return graphWidth * (value - 1) / (SCALE_NUMBER - 1);
  }

  function getBottomScaleLabelPosition(position: number): { x: number, y: number } {
    return {
      x: getXPosition(position),
      y: maxHeight + paddingBoth
    };
  }

  function getScaleLinePoints(position: number): { x1: number, y1: number, x2: number, y2: number } {
    return {
      x1: getXPosition(position),
      y1: PADDING,
      x2: getXPosition(position),
      y2: maxHeight + PADDING
    };
  }

  function getBarProps(value: number, position: number, order: number): { x: number, y: number, width: number, height: number} {
    return {
      x: PADDING,
      y: getYPosition(position, order),
      width: getBarWidth(value),
      height: HEIGHT_BAR
    };
  }

  function getBarValuePosition(value: number, position: number, order: number): { x: number, y: number } {
    return {
      x: getBarWidth(value) + (PADDING / 2),
      y: getYPosition(position, order) + VALUE_LABEL_PADDING
    };
  }
  
  return (
    <View wrap={false} style={styles.element} useFor={useFor}>
      <View style={styles.leftBar} useFor={useFor}>
        {labels.map((item) => <View key={item} className="graph-chart-label" style={styles.label} useFor={useFor}>
          <Text useFor={useFor} insideSVG={false}>{item}</Text>
        </View>)}
      </View>
      <View style={styles.rightBar} useFor={useFor}>
        <View style={styles.top} useFor={useFor}>
          <Text style={styles.topTitle} useFor={useFor} insideSVG={false}>
            {t('TEXT_DEVELOPMENT')}
          </Text>
          <View style={styles.scale} useFor={useFor}>
            <View style={styles.none} useFor={useFor}>
              <Text useFor={useFor} insideSVG={false}>
                {t('TEXT_NONE')}
              </Text>
            </View>
            <View style={styles.low} useFor={useFor}>
              <Text useFor={useFor} insideSVG={false}>
                {t('TEXT_LOW')}
              </Text>
            </View> 
            <View style={styles.moderate} useFor={useFor}>
              <Text useFor={useFor} insideSVG={false}>
                {t('TEXT_MODERATE')}
              </Text>
            </View> 
            <View style={styles.strong} useFor={useFor}>
              <Text useFor={useFor} insideSVG={false}>
                {t('TEXT_STRONG')}
              </Text>
            </View> 
          </View>
        </View>
        <View style={styles.graph} useFor={useFor}>
          <Svg height={svgHeight} width={svgWidth} useFor={useFor}>
          {range(1,6).map((item, i) => <G key={item} useFor={useFor}>
            <Line {...getScaleLinePoints(i)} stroke="#979797" useFor={useFor}/>
            <Text {...getBottomScaleLabelPosition(i)} style={styles.scaleLabel} useFor={useFor}>{item === 3 ? item + EXTRA_SCALE : item}</Text>
          </G>)}
            {values.map((group, j) => <G key={j} useFor={useFor}>
              {group.values.map((item, i) => <G  key={i} useFor={useFor}>
                <Rect {...getBarProps(item, i, j)} fill={group.color} useFor={useFor}/>
                <Text {...getBarValuePosition(item, i ,j)} fill="white" style={styles.value} useFor={useFor}>{item.toFixed(1)}</Text>
              </G>)}
            </G>)}
          </Svg>
        </View>
        <Legends legends={values} align={LegendAlign.START} useFor={useFor}/>
      </View>
    </View>
  );
}
