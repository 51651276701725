import { compact, startCase } from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactElement, useContext, useState } from 'react';
import { AppActionTypes, AppContext } from '../../../AppContextProvider';
import Button from '../../../components/Button/Button';
import ExternalLink from '../../../components/Link/ExternalLink';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import { Church } from '../../../models/church/Church';
import { ChurchForm } from '../../../models/church/ChurchForm';
import { ChurchFormAnswer } from '../../../models/church/ChurchFormAnswer';
import { ChurchFormQuestion } from '../../../models/church/ChurchFormQuestion';
import { SurveyQuestion } from '../../../models/survey/Question';
import { Survey } from '../../../models/survey/Survey';
import { ChurchListContext } from '../ChurchListContextProvider';
import ChurchListDetailsItem from '../ChurchListDetailsItem/ChurchListDetailsItem';
import ChurchListDetailsName from '../ChurchListDetailsItem/ChurchListDetailsName';
import './ChurchListDetails.scss';
import ChurchReportModal from '../../ChurchReportModal/ChurchReportModal';
import { ChurchStatus } from '../../../enums/ChurchStatus';
import ChurchCloseSurveyModal from '../../ChurchCloseSurveyModal/ChurchCloseSurveyModal';
import { reGenerateReport } from '../../../hooks/re-generate-report';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import supabase from '../../../supabaseClient';
import LanguageDropdown from '../../../components/LanguageDropdown/LanguageDropdown';

export default function ChurchListDetails(): ReactElement {
  const [selectedLanguage, setSelectedLanguage] = useState('EN');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleDownloadReport = (language: string) => {
    const url = getChurchReportLink(language);
    window.open(url, '_blank');
  };

  // Handler for report comments
  const handleDownloadReportComments = (language: string) => {
    const url = getChurchReportCommentLink(language);
    window.open(url, '_blank');
  };

  const { state } = useContext(ChurchListContext);
  const { state: appState, dispatch } = useContext(AppContext);
  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_DETAILS');

  const selectedChurch: Church = state.selectedChurch ? state.selectedChurch : null;

  const formatDate = (date: DateTime): string => date.isValid && date.toFormat('M/d/yyyy');

  const hasBlankIntakeForm = !!selectedChurch?.intakeForm?.collector?.url;
  const hasCompletedIntakeForm = !!selectedChurch?.intakeForm?.result?.responses?.length;
  const hasBlankSurveyForm = !!selectedChurch?.surveyForm?.collector?.url;

  const parseQuestion = (question: ChurchFormQuestion, answer: ChurchFormAnswer): SurveyQuestion =>
    new SurveyQuestion({
      type: question.type,
      text: question.question,
      answers: answer?.texts ?? null,
      subQuestions: compact(
        answer?.answersToSubQuestions?.map((item) => {
          const subQuestion = question.subQuestions.find(
            (subQuestion) => subQuestion.id === item.questionID
          );

          return subQuestion ? parseQuestion(subQuestion, item) : null;
        })
      )
    });

  const getSurveyFromChurchByResponseID = (
    church: Church,
    formType: string,
    responseID: string
  ): Survey => {
    console.log('response id:', responseID);
    const form = church[formType] as ChurchForm;
    const questions = form.questions;
    const response = form.result.responses.find((item) => item.id === responseID);

    console.log('church:', church);
    console.log('form:', form);

    return new Survey({
      churchName: church.name,
      formName: startCase(formType),
      dateCreated: response.dateCreated,
      analyseURL: response.analyzeUrl,
      questionsWithAnswers: questions.map((question) => {
        const answer = response.answers.find((answer) => answer.questionID === question.id);

        return parseQuestion(question, answer);
      })
    });
  };

  const getChurchReportLink = (lang: string): string =>
    `${process.env.REACT_APP_PDF_APP_URL}/download/${encodeURIComponent(
      selectedChurch.name.toLowerCase()
    )}/${encodeURIComponent(selectedChurch.churchId)}/${lang}`;

  const getChurchReportCommentLink = (lang: string): string =>
    `${process.env.REACT_APP_PDF_APP_URL}/download/comment/${encodeURIComponent(
      selectedChurch.name.toLowerCase()
    )}/${encodeURIComponent(selectedChurch.churchId)}/${lang}`;

  const getChurchReportIntakeLink = (): string =>
    `${process.env.REACT_APP_PDF_APP_URL}/download/intake/${encodeURIComponent(
      selectedChurch.name.toLowerCase()
    )}/${encodeURIComponent(selectedChurch.churchId)}`;

  const openChurchReportModal = async (): Promise<void> => {
    dispatch({
      type: AppActionTypes.OpenChurchReportModal,
      payload: { church: state.selectedChurch }
    });
  };

  const closeChurchSurvey = (): void => {
    dispatch({
      type: AppActionTypes.OpenChurchCloseSurveyModal,
      payload: { church: state.selectedChurch }
    });
  };

  const reRunChurchReport = async (): Promise<void> => {
    await supabase.from('progress').delete().eq('church', state.selectedChurch?.name);

    dispatch({
      type: AppActionTypes.ReRunChurchReport,
      payload: { church: state.selectedChurch }
    });
    dispatch({ type: AppActionTypes.OpenProgressBarModal });

    await reGenerateReport(
      selectedChurch.churchId.toString(),
      selectedChurch.surveyForm.id,
      selectedChurch.surveyForm.collector.id,
      false
    );

    dispatch({
      type: AppActionTypes.ReRunChurchReport,
      payload: { church: state.selectedChurch }
    });
  };
  return (
    <div className="church-details">
      <div className="church-details-header">
        <h1 className="church-details-title">{t('TEXT_MORE_INFO')}</h1>
        {selectedChurch &&
          (appState.userClaims?.isAdmin || appState.userClaims?.isFullAccessUser) && (
            <div className="church-details-actions">
              <Button
                onClick={() =>
                  dispatch({
                    type: AppActionTypes.OpenEditChurchModal,
                    payload: { church: selectedChurch }
                  })
                }
                color="blue-gray"
                size="small"
              >
                {t('BUTTON_EDIT')}
              </Button>
              <Button
                onClick={() =>
                  dispatch({
                    type: AppActionTypes.OpenDeleteChurchModal,
                    payload: { church: selectedChurch }
                  })
                }
                color="red"
                size="small"
              >
                {t('BUTTON_DELETE')}
              </Button>
            </div>
          )}
      </div>
      {selectedChurch && (
        <div className="church-details-content">
          <ChurchListDetailsName title={t('TEXT_CHURCH_NAME')}>
            {selectedChurch.name}
          </ChurchListDetailsName>

          <ChurchListDetailsItem title={t('TEXT_CONTACT_NAME')}>
            {selectedChurch.contactDataFirstName} {selectedChurch.contactDataLastName}
          </ChurchListDetailsItem>

          <ChurchListDetailsItem title={t('TEXT_CONTACT_EMAIL')}>
            {selectedChurch.contactDataEmail}
          </ChurchListDetailsItem>

          {selectedChurch.contactDataPhone && (
            <ChurchListDetailsItem title={t('TEXT_CONTACT')}>
              {selectedChurch.contactDataPhone}
            </ChurchListDetailsItem>
          )}

          {selectedChurch.surveyFormOpenDate && (
            <ChurchListDetailsItem title={t('TEXT_SURVEY_OPEN')}>
              {formatDate(selectedChurch.surveyFormOpenDate)}
            </ChurchListDetailsItem>
          )}

          {selectedChurch.surveyFormCloseDate && (
            <ChurchListDetailsItem title={t('TEXT_SURVEY_CLOSE')}>
              {formatDate(selectedChurch.surveyFormCloseDate)}
            </ChurchListDetailsItem>
          )}

          {selectedChurch.inquiryForm?.result?.responses?.length && (
            <ChurchListDetailsItem title={t('TEXT_INQUIRY')}>
              <ExternalLink
                onClick={() =>
                  dispatch({
                    type: AppActionTypes.OpenSurveyViewModal,
                    payload: {
                      survey: getSurveyFromChurchByResponseID(
                        selectedChurch,
                        'inquiryForm',
                        selectedChurch.inquiryForm.result.responses[0].id
                      )
                    }
                  })
                }
              >
                {t('TEXT_COMPLETED_FORM')}
              </ExternalLink>
            </ChurchListDetailsItem>
          )}

          {(hasBlankIntakeForm || hasCompletedIntakeForm) && (
            <ChurchListDetailsItem title={t('TEXT_INTAKE_FORM')}>
              {hasBlankIntakeForm && !hasCompletedIntakeForm && (
                <ExternalLink href={selectedChurch.intakeForm.collector.url} target="_blank">
                  {t('TEXT_BLANK_FORM')}
                </ExternalLink>
              )}
              {hasCompletedIntakeForm && (
                <ExternalLink
                  onClick={() =>
                    dispatch({
                      type: AppActionTypes.OpenSurveyViewModal,
                      payload: {
                        survey: getSurveyFromChurchByResponseID(
                          selectedChurch,
                          'intakeForm',
                          selectedChurch.intakeForm.result.responses[0].id
                        )
                      }
                    })
                  }
                >
                  {t('TEXT_COMPLETED_FORM')}
                </ExternalLink>
              )}
              {selectedChurch?.intakeForm?.result?.responses?.length > 0 && (
                <>
                  {' '}
                  <ExternalLink target="_blank" href={getChurchReportIntakeLink()}>
                    {t('TEXT_REPORT_INTAKE')}
                  </ExternalLink>
                </>
              )}
            </ChurchListDetailsItem>
          )}

          {hasBlankSurveyForm && (
            <ChurchListDetailsItem title={t('TEXT_SURVEY')}>
              <ExternalLink href={selectedChurch.surveyForm.collector.url} target="_blank">
                {t('TEXT_BLANK_FORM')}
              </ExternalLink>

              {selectedChurch.status === ChurchStatus.SURVEY && (
                <>
                  <ExternalLink onClick={closeChurchSurvey} target="_blank" variant="red">
                    {t('TEXT_CLOSE_SURVEY')}
                  </ExternalLink>
                  {appState.isChurchCloseSurveyModalOpened ? <ChurchCloseSurveyModal /> : <></>}
                </>
              )}
              {(process.env.REACT_APP_WEB_URL === 'https://peak-testing.web.app' ||
                process.env.REACT_APP_WEB_URL === 'https://peak-staging-340913.web.app' ||
                ((selectedChurch.status === ChurchStatus.COACHING ||
                  selectedChurch.status === ChurchStatus.SURVEY) &&
                  (appState.userClaims?.isAdmin || appState.userClaims?.isFullAccessUser))) &&
                (appState.isReRunningChurchReport &&
                appState.churchToRunReport === state.selectedChurch ? (
                  t('TEXT_RERUN_REPORT_RUNNING')
                ) : (
                  <ExternalLink onClick={reRunChurchReport}>{t('TEXT_RERUN_REPORT')}</ExternalLink>
                ))}
            </ChurchListDetailsItem>
          )}
          {
            <ChurchListDetailsItem title={t('TEXT_REPORT')}>
              {selectedChurch.leaderReport?.adultRespondents?.answerOptions &&
                selectedChurch.congregationReport?.adultRespondents?.answerOptions &&
                selectedChurch.combinedReport?.adultRespondents?.answerOptions && (
                  <>
                    <ExternalLink onClick={openChurchReportModal}>
                      {t('TEXT_SHOW_REPORT')}
                    </ExternalLink>
                    {appState.isChurchReportModalOpened && <ChurchReportModal />}
                  </>
                )}
              <div style={{ marginTop: '16px' }}>
                {selectedChurch.leaderReport?.adultRespondents?.answerOptions &&
                  selectedChurch.congregationReport?.adultRespondents?.answerOptions &&
                  selectedChurch.combinedReport?.adultRespondents?.answerOptions && (
                    <>
                      <LanguageDropdown
                        buttonLabel={t('TEXT_REPORT_COMMENTS')}
                        onSelect={handleDownloadReportComments}
                      />
                      <LanguageDropdown
                        buttonLabel={t('TEXT_DOWNLOAD_REPORT')}
                        onSelect={handleDownloadReport}
                      />
                    </>
                  )}
              </div>
            </ChurchListDetailsItem>
          }

          {selectedChurch?.coachName && (
            <ChurchListDetailsItem title={t('TEXT_COACH')}>
              {selectedChurch.coachName}
            </ChurchListDetailsItem>
          )}
          {appState.isProgressBarModalOpened && <ProgressBar />}
        </div>
      )}
    </div>
  );
}
