import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Grid.scss';

export interface Props {
  children: ReactNode;
  isNoIndent?: boolean;
  isReverse?: boolean;
}

export default function Row({ children, isNoIndent, isReverse }: Props): ReactElement {
  return (
    <div
      className={
        classNames(
          'row',
          {
            'row-no-indent': isNoIndent,
            'row-reverse': isReverse
          }
        )
      }
    >
      {children}
    </div>
  );
}
