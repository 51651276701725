import { ChartArrayData } from '../../../models/report/chart-array-data';
import { Report } from '../../../models/report/report';

export interface ISummaryData {
  values: Array<ChartArrayData>;
  labels: Array<string>;
}

function splitString({ value }: { value: string }): string {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').map((data): string => `${data[0].toUpperCase()}${data.slice(1)}`).join(' ');
}

export function mapSummaryReportData({ data, colors, labels }: { data: Array<Report>; colors: Array<string>; labels: Array<string>; }): ISummaryData {
  const chartLabels: Array<string> = [];
  const chartValues = data.map((report: Report, index: number): ChartArrayData => {
    const color = colors[index];
    const label = labels[index];

    const reportKeys: Array<string> = Object.keys(report).filter(
      (reportKey: string) => 
        reportKey !== 'demographics' && 
        reportKey !== 'peakKids' && 
        reportKey !== 'leadershipTeamEvaluation' && 
        reportKey !== 'volunteerEngagement' && 
        reportKey !== 'adultRespondents' && 
        report[reportKey]
    );

    const values: Array<number> = reportKeys.map((reportKey: string): number => {
      if (index === 0) {
        chartLabels.push(splitString({ value: reportKey }));
      }

      return Number(report[reportKey]?.value?.toFixed(1));
    });

    return {
      color,
      label,
      values
    };
  });

  return { values: chartValues, labels: chartLabels };
}
