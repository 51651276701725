type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const callSupabaseEdgeFunction = async (
    functionName: string,
    method: HttpMethod,
    body?: Record<string, unknown>
): Promise<any> => {
    const projectId = process.env.REACT_APP_SUPABASE_PROJECT_REF_ID;
    const url = `https://${projectId}.supabase.co/functions/v1/${functionName}`;

    const options: RequestInit = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_SUPABASE_ANON_KEY,
            'Content-Type': body ? 'application/json' : 'text/plain'
        },
        body: body ? JSON.stringify(body) : undefined
    };

    const response = await fetch(url, options);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};

export { callSupabaseEdgeFunction };
