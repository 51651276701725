import React, { ReactElement, ReactNode, useMemo, useReducer } from 'react';
import { ChurchStatus } from '../../enums/ChurchStatus';
import { ActionMap } from '../../helpers/ActionMap';
import { ChurchDistrict } from '../../models/church/ChurchDistrict';
import { ChurchesFilters } from '../../models/church/ChurchesFilters';
import { ContextReducer } from '../../types/ContextReducer';
import { Church } from '../../models/church/Church';

export enum ChurchListActionTypes {
  SetSelectedChurch = 'SET_SELECTED_CHURCH',
  SetFilters = 'SET_FILTERS',
  SetChurches = 'SET_CHURCHES',
  SetCurrentPage = 'SET_CURRENT_PAGE',
  SetTotalPages = 'SET_TOTAL_PAGES',
  OpenEditChurchModal = 'OPEN_EDIT_CHURCH_MODAL',
  CloseEditChurchModal = 'CLOSE_EDIT_CHURCH_MODAL',
  OpenApplyFiltersModal = 'OPEN_APPLY_FILTERS_MODAL',
  CloseApplyFiltersModal = 'CLOSE_APPLY_FILTERS_MODAL',
  CloseApplyFiltersModalWithoutUpdate = 'CLOSE_APPLY_FILTERS_MODAL_WITHOUT_UPDATE',
  SetIsLoading = 'SET_IS_LOADING',
  UpdateSelectedCurch = 'UPDATE_SELECTED_CHURCH',
  TriggerFetch = 'TRIGGER_FETCH'
}

type ChurchListState = {
  selectedChurch: Church;
  filters: ChurchesFilters;
  churches: Array<Church>;
  isApplyFiltersModalOpened: boolean;
  isLoading: boolean;
  isMoreLoading: boolean;
  areAllChurchesLoaded: boolean;
  currentPage: number;
  churchesPerPage: number;
  fetchTrigger: number;
  totalPages: number;
};

type ChurchListPayload = {
  [ChurchListActionTypes.SetSelectedChurch]: {
    church: Church;
  };
  [ChurchListActionTypes.SetFilters]: {
    filters: ChurchesFilters;
  };
  [ChurchListActionTypes.SetChurches]: {
    churches: Array<Church>;
  };
  [ChurchListActionTypes.OpenApplyFiltersModal]: null;
  [ChurchListActionTypes.CloseApplyFiltersModalWithoutUpdate]: null;
  [ChurchListActionTypes.CloseApplyFiltersModal]: {
    filters?: ChurchesFilters;
  };
  [ChurchListActionTypes.SetIsLoading]: {
    value: boolean;
  };
  [ChurchListActionTypes.UpdateSelectedCurch]: {
    church?: Church;
  };
  [ChurchListActionTypes.SetCurrentPage]: {
    page: number;
  };
  [ChurchListActionTypes.TriggerFetch]: undefined;
  [ChurchListActionTypes.SetTotalPages]: {
    totalPages: number;
  };
};

type ChurchListActions = ActionMap<ChurchListPayload>[keyof ActionMap<ChurchListPayload>];

type Props = {
  children: ReactNode;
};

const initialState: ChurchListState = {
  selectedChurch: null,
  filters: new ChurchesFilters({
    name: '',
    status: '' as ChurchStatus,
    district: new ChurchDistrict(),
    limit: 8
  }),
  churches: [],
  isApplyFiltersModalOpened: false,
  isLoading: true,
  isMoreLoading: false,
  areAllChurchesLoaded: false,
  currentPage: 1, // Start from page 1
  churchesPerPage: 8, // Default number of churches per page
  fetchTrigger: 0,
  totalPages: 0
};

export const ChurchListContext = React.createContext<
  ContextReducer<ChurchListState, ChurchListActions>
>({
  state: initialState,
  dispatch: () => null
});

export function ChurchListContextProvider({ children }: Props): ReactElement {
  const [state, dispatch] = useReducer((state: ChurchListState, action: ChurchListActions) => {
    switch (action.type) {
      case ChurchListActionTypes.SetSelectedChurch:
        return {
          ...state,
          selectedChurch: action.payload.church
        };
      case ChurchListActionTypes.SetFilters:
        return {
          ...state,
          filters: action.payload.filters
        };
      case ChurchListActionTypes.SetChurches:
        return {
          ...state,
          churches: action.payload.churches
        };
      case ChurchListActionTypes.OpenApplyFiltersModal:
        return {
          ...state,
          isApplyFiltersModalOpened: true
        };
      case ChurchListActionTypes.CloseApplyFiltersModal:
        return {
          ...state,
          isApplyFiltersModalOpened: false,
          filters: action?.payload?.filters ?? state.filters,
          churches: action?.payload?.filters ? [] : state.churches,
          isLoading: true
        };
      case ChurchListActionTypes.CloseApplyFiltersModalWithoutUpdate:
        return {
          ...state,
          isApplyFiltersModalOpened: false
        };
      case ChurchListActionTypes.SetIsLoading:
        return {
          ...state,
          isLoading: action.payload.value
        };
      case ChurchListActionTypes.UpdateSelectedCurch:
        return {
          ...state,
          selectedChurch: action.payload?.church
        };
      case ChurchListActionTypes.SetCurrentPage:
        return {
          ...state,
          currentPage: action.payload.page
        };
      case ChurchListActionTypes.SetTotalPages:
        return {
          ...state,
          totalPages: action.payload.totalPages
        };
      case ChurchListActionTypes.TriggerFetch:
        return {
          ...state,
          fetchTrigger: state.fetchTrigger + 1
        };
      default:
        return state;
    }
  }, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <ChurchListContext.Provider value={contextValue}>{children}</ChurchListContext.Provider>;
}
