import { Expose } from 'class-transformer';

export class SurveyMonkeyDistrict {
  @Expose()
  public name: string;

  @Expose({ name: 'survey_monkey_answer_id' })
  public surveyMonkeyAnswerId: string;

  constructor(model: Partial<SurveyMonkeyDistrict> = {}) {
    Object.assign(this, model);
  }
}
