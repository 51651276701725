import { ChurchSurveyFormReportQuestion } from '../../../models/report/question';
import { SchoolData } from '../../../models/report/school-data';

export interface IAdultRespondentsData {
  congregation: SchoolData;
  leadership: SchoolData;
}

// eslint-disable-next-line unused-imports/no-unused-vars-ts
export function mapAdultRespondentsData(data: ChurchSurveyFormReportQuestion): SchoolData {
  const { answerOptions } = data;
  if (!Array.isArray(answerOptions)) {
    return { preSchool: 0, elementary: 0, middleSchool: 0, highSchool: 0 };
  }

  const [preSchool, elementary, middleSchool, highSchool] = answerOptions;
  return {
    preSchool: preSchool?.count || 0,
    elementary: elementary?.count || 0,
    middleSchool: middleSchool?.count || 0,
    highSchool: highSchool?.count || 0
  };
}
