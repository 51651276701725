import { Expose } from 'class-transformer';

export class ChurchFormAnswerOption {
  @Expose()
  public id!: string;

  @Expose({ name: 'origin_id' })
  public originID!: string;

  @Expose()
  public position!: number;

  @Expose()
  public text!: string;

  constructor(data: Partial<ChurchFormAnswerOption> = {}) {
    Object.assign(this, data);
  }
}
