import React, { AnchorHTMLAttributes, ReactElement } from 'react';
import { LinkVariant } from './Link';
import './Link.scss';
import classNames from 'classnames';

export interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: LinkVariant;
}

export default function ExternalLink({ variant, children, ...linkProps }: Props): ReactElement {
  return (
    <a
      {...linkProps}
      className={classNames(
        'link',
        `link-${variant ?? 'blue'}`
      )}
    >
      <span className="link-underline" />
      {children}
    </a>
  );
}
