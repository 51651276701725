import React, { ReactElement } from 'react';
import './Tag.scss';

export interface Props {
  tagText: string
}

export default function Tag({ tagText }: Props): ReactElement {
  return <span className="tag">{tagText}</span>;
}
