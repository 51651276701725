import { Expose } from 'class-transformer';
import { ReportCommentData } from './comment-data';

export class ReportComment {
  @Expose()
  public leadershipDemographics!: Array<ReportCommentData> | null;
  
  @Expose()
  public congregationDemographics!: Array<ReportCommentData> | null;
  
  @Expose()
  public summaryReview!: Array<ReportCommentData> | null;
  
  @Expose()
  public rolesReview!: Array<ReportCommentData> | null;
  
  @Expose()
  public spiritualLeadership!: Array<ReportCommentData> | null;
  
  @Expose()
  public personalGrowth!: Array<ReportCommentData> | null;
  
  @Expose()
  public missionsFocus!: Array<ReportCommentData> | null;
  
  @Expose()
  public lovingCommunity!: Array<ReportCommentData> | null;
  
  @Expose()
  public worshipGathering!: Array<ReportCommentData> | null;
  
  @Expose()
  public visionAlignment!: Array<ReportCommentData> | null;
  
  @Expose()
  public alliancePartnership!: Array<ReportCommentData> | null;
  
  @Expose()
  public financialStewardship!: Array<ReportCommentData> | null;
  
  @Expose()
  public effectiveOrganization!: Array<ReportCommentData> | null;
  
  @Expose()
  public volunteerEngagement!: Array<ReportCommentData> | null;
  
  @Expose()
  public leadershipTeamEvaluation!: Array<ReportCommentData> | null;
  
  @Expose()
  public adultRespondents!: Array<ReportCommentData> | null;
  
  @Expose()
  public kidsReview!: Array<ReportCommentData> | null;

  constructor(data: Partial<ReportComment> = {}) {
    Object.assign(this, data);
  }
}
