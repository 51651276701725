import { Line as ReactPDFLine } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ElementUseFor, ISVG } from '../Svg';

export interface ISVGLineProperties extends ISVG<any> {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  strokeDasharray?: string;
  fillRule?: any;
  textAnchor?: any;
  visibility?: any;
  dominantBaseline?: any;
}

export default function Line(props: ISVGLineProperties): ReactElement {
  const { useFor, ...restProperties } = props;

  return useFor === ElementUseFor.PDF ? <ReactPDFLine {...restProperties} /> : <line {...restProperties} />;
}
