import classNames from 'classnames';
import { FieldInputProps, FormikProps } from 'formik/dist/types';
import React, { ChangeEvent, InputHTMLAttributes, ReactElement, ReactNode, useEffect, useState } from 'react';
import './Form.scss';
import { FormikValues } from 'formik';

interface Props {
  field?: FieldInputProps<string | number>;
  input?: InputHTMLAttributes<HTMLInputElement>;
  type?: string;
  onChange?: (_value: string | number) => void;
  form?: FormikProps<FormikValues>;
  isError?: boolean;
  placeholder?: string;
  size?: FormControlSize;
  icon?: ReactNode;
}

export type FormControlSize = 'small' | 'normal';

export default function FormControl({ field, input, isError, size, icon, form, onChange, ...inputProps }: Props): ReactElement {
  const [isFilled, setFilled] = useState(false);

  useEffect(
    () => setFilled(!!field.value)
  );

  const onChangeFormValue = (event: ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue(field.name, event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="form-control-container">
      {!!icon && <div className={classNames('form-control-icon', 'form-control-icon-left')}>{icon}</div>}
      <input
        {...field}
        {...input}
        {...inputProps}
        onChange={onChangeFormValue}
        className={classNames(
          'form-control',
          `form-control-${size ?? 'normal'}`,
          {
            'form-control-filled': isFilled,
            'form-control-error': isError
          }
        )}
      />
    </div>
  );
}
