import { Expose } from 'class-transformer';
import 'reflect-metadata';

export class ChurchTag {
  @Expose()
  public id: number;

  @Expose({ name: 'church_id' })
  public churchId: number;

  @Expose({ name: 'tag_id' })
  public tagId: number;

  @Expose()
  public name: string;

  constructor(model: Partial<ChurchTag> = {}) {
    Object.assign(this, model);
  }
}
