export class SurveyQuestion {
  public type: string;
  public text: string;
  public answers: Array<string>;
  public subQuestions: Array<SurveyQuestion>;

  constructor(model: Partial<SurveyQuestion> = {}) {
    Object.assign(this, model);
  }
}
