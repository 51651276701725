import React, { ReactElement, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { useSupabaseUser } from '../../useSupabaseUser';

interface Props extends RouteProps {
  component: React.ComponentType<any>;
  title: string;
}

function PublicRoute(props: Props): ReactElement {
  const { component: PageComponent, title } = props;
  const user = useSupabaseUser();

  useEffect(() => {
    document.title = title;
  }, []);

  return user ? null : <PageComponent {...props} />;
}

export default PublicRoute;
