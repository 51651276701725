export class Angle {
  public get percent(): number {
    return (this._rad * 100) / (2 * Math.PI);
  }

  public get deg(): number {
    return (this._rad * 360) / (2 * Math.PI);
  }

  public get rad(): number {
    return this._rad;
  }

  private _rad: number;

  constructor (data: Partial<Angle>) {
    if ('percent' in data) {
      this._rad = (2 * Math.PI * data.percent) / 100;
    } else if ('deg' in data) {
      this._rad = (2 * Math.PI * data.deg) / 360;
    } else if ('rad' in data) {
      this._rad = data.rad;
    } else {
      throw new Error('Unknown value');
    }
  }
}
