import React, { InputHTMLAttributes, ReactElement, useEffect, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik/dist/types';
import { FormikValues } from 'formik';
import classNames from 'classnames';
import { SelectItem } from '../../models/SelectItem';
import { FormControlSize } from './FormControl';
import Icon from '../Icon/Icon';
import { find } from 'lodash';
import Select, {
  components,
  IndicatorProps,
  OptionsType,
  PlaceholderProps,
  ValueContainerProps,
  ControlProps, MenuListComponentProps, MenuProps, OptionProps
} from 'react-select';
import './Form.scss';

interface Props {
  field?: FieldInputProps<typeof SelectItem.prototype.value>;
  input?: InputHTMLAttributes<HTMLInputElement>;
  form?: FormikProps<FormikValues>;
  error?: string;
  touched?: boolean;
  upward?: boolean;
  onChange?: (_value: typeof SelectItem.prototype.value) => void;
  options?: OptionsType<SelectItem>;
  value?: typeof SelectItem.prototype.value;
  loading?: boolean;
  selectedByDefaultItemIndex?: number;
  isError?: boolean;
  placeholder?: string;
  size?: FormControlSize;
}

export default function FormSelectControl({
  field, form, options, size, isError, selectedByDefaultItemIndex, placeholder, ...props
}: Props): ReactElement {
  const [isFilled, setFilled] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  useEffect(
    () => setFilled(!!field.value)
  );

  const Control = ({ children, ...props }: ControlProps<SelectItem, false>): ReactElement => (
    <components.Control
      {...props}
      className={classNames(
        'form-control',
        'form-control-select',
        `form-control-${size ?? 'normal'}`,
        {
          'form-control-filled': isFilled,
          'form-control-error': isError,
          'form-control-focus': onFocus
        }
      )}
    >
      {children}
    </components.Control>
  );

  const DropdownIndicator = (props: IndicatorProps<SelectItem, false>): ReactElement => (
    <components.DropdownIndicator
      {...props}
      className={classNames('form-control-icon', 'form-control-icon-right')}
    >
      <Icon variant="arrow-down" mask={(onFocus) ? null : 'light-gray'} />
    </components.DropdownIndicator>
  );

  const ValueContainer = ({ children, ...props }: ValueContainerProps<SelectItem, false>): ReactElement => (
    <components.ValueContainer {...props} className="form-control-select-value">
      {children}
    </components.ValueContainer>
  );

  const Placeholder = ({ children, ...props }: PlaceholderProps<SelectItem, false>): ReactElement => (
    <components.Placeholder {...props} className="form-control-select-placeholder">
      {children}
    </components.Placeholder>
  );

  const MenuList = ({ children, ...props }: MenuListComponentProps<SelectItem, false>): ReactElement => (
    <components.MenuList {...props} className="form-control-select-dropdown-list">
      {children}
    </components.MenuList>
  );

  const Menu = ({ children, ...props }: MenuProps<SelectItem, false>): ReactElement => (
    <components.Menu {...props} className="form-control-select-dropdown">
      {children}
    </components.Menu>
  );

  const Option = ({ children, ...props }: OptionProps<SelectItem, false>): ReactElement => (
    <components.Option
      {...props}
      className={classNames(
        'form-control-select-dropdown-list-item',
        { active: props.isSelected }
      )}
    >
      {children}
    </components.Option>
  );

  const onChangeFormValue = (option: SelectItem): void => {
    setOnFocus(false);
    form.setFieldValue(field.name, option.value);

    if (props.onChange) {
      props.onChange(option.value);
    }
  };

  const getValue = (): SelectItem => (options) ? find(options, { value: field.value }) : null;

  return (
    <Select
      {...field}
      {...props}
      options={options}
      components={{
        Control,
        DropdownIndicator,
        ValueContainer,
        Placeholder,
        Menu,
        MenuList,
        Option,
        IndicatorSeparator: () => null
      }}
      className="form-control-container"
      defaultValue={options?.[selectedByDefaultItemIndex]}
      isClearable={false}
      isSearchable={false}
      placeholder={placeholder}
      value={getValue()}
      onMenuOpen={() => setOnFocus(true)}
      onMenuClose={() => setOnFocus(false)}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      onChange={onChangeFormValue}
    />
  );
}
