import React, { LegacyRef, PropsWithChildren, ReactElement } from 'react';
import { Link as ReactLink, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import './Link.scss';

export interface Props extends Omit<LinkProps, 'component'> {
  variant?: LinkVariant;
  children: string;
}

export type LinkVariant = 'blue' | 'light-gray' | 'red';

export default function Link({ variant, children, ...linkProps }: Props): ReactElement {
  const FancyLink = React.forwardRef((props: PropsWithChildren<{ href: string }>, ref: LegacyRef<HTMLAnchorElement>) => (
    <a
      href={props.href}
      ref={ref}
      className={classNames(
        'link',
        `link-${variant ?? 'blue'}`
      )}
    >
      <span className="link-underline" />
      {props.children}
    </a>
  ));

  return (
    <ReactLink
      {...linkProps}
      component={FancyLink}
    >
      {children}
    </ReactLink>
  );
}
