import { Expose, Type } from 'class-transformer';

export class ChurchSurveyFormReportAnswer {
  @Expose()
  public id!: string;

  @Expose({ name: 'origin_id' })
  public originID!: string;

  @Expose()
  public position!: number;

  @Expose()
  public text!: string;

  @Type(() => Number)
  @Expose()
  public count!: number;

  @Expose()
  public rate!: number | null;

  constructor(data: Partial<ChurchSurveyFormReportAnswer> = {}) {
    Object.assign(this, data);
  }
}
