import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './Loader.scss';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';

interface Props {
  size?: 'small' | 'large';
  inFullScreen?: boolean;
}

export default function Loader({ size, inFullScreen }: Props): ReactElement {
  const t = useTranslationByKey('COMPONENTS.LOADER');

  return (
    <div className={classNames(
      'loader-container',
      { 'loader-container-full-screen': inFullScreen }
    )}>
      <div
        className={classNames(
          'loader',
          `loader-${size ?? 'small'}`
        )}
      >
        {t('TEXT_LOADING')}
      </div>
    </div>
  );
}
