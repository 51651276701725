import { Field, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Animation from '../../../components/Animation/Animation';
import Button from '../../../components/Button/Button';
import Form from '../../../components/Form/Form';
import FormButtons from '../../../components/Form/FormButtons';
import FormCheckboxesGroups from '../../../components/Form/FormCheckboxesGroups';
import FormCheckboxGroup from '../../../components/Form/FormCheckboxGroup';
import Col from '../../../components/Grid/Col';
import Row from '../../../components/Grid/Row';
import Modal from '../../../components/Modal/Modal';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import { ChurchesFilters } from '../../../models/church/ChurchesFilters';
import { ChurchTag } from '../../../models/church/ChurchTag';
import { SelectItem } from '../../../models/SelectItem';
import { Tag } from '../../../models/Tag';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchListContextProvider';
import './ChurchListFiltersModal.scss';
import supabase from '../../../supabaseClient';

export default function ChurchListFiltersModal(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_FILTERS_MODAL');

  const { state, dispatch } = useContext(ChurchListContext);
  const [tags, setTagsInState] = useState<Array<ChurchTag>>([]);

  const getInitialValues = (): ChurchesFilters => ({
    ...state.filters,
    tags: state.filters?.tags ? [...state.filters.tags] : [],
    isCompletedCoaching: state.filters?.isCompletedCoaching || false,
    averageAttendance: state.filters?.averageAttendance ? [...state.filters.averageAttendance] : []
  });

  const [initialValues, setInitialValues] = useState<ChurchesFilters>(getInitialValues());
  const [isClearButtonVisible, setIsClearButtonVisible] = useState<boolean>(false);

  useEffect(() => {
    setTags();
  }, []);

  useEffect(() => {
    setIsClearButtonVisible(
      !!(
        initialValues.averageAttendance.length ||
        initialValues.tags.length ||
        initialValues.isCompletedCoaching
      )
    );
  }, [initialValues]);

  const setTags = async (): Promise<void> => {
    try {
      const { data: tagsData, error } = await supabase
        .from('tags')
        .select('*')
        .order('name', { ascending: true }); // Order by name in ascending order

      if (error) {
        throw error;
      }

      // Map over the fetched tags data to create ChurchTag instances
      const tags = tagsData.map((item) => {
        const tag = new Tag(item); // Directly use item if it aligns with the Tag class structure
        return new ChurchTag({
          name: tag.name,
          id: item.id
        });
      });

      setTagsInState(tags); // Set the mapped tags in state
    } catch (error) {
      console.error('Error fetching tags:', error);
      // Handle error appropriately
    }
  };

  const getAverageAttendanceSelectValues = (): Array<SelectItem<[number, number]>> =>
    (
      [
        [0, 50],
        [50, 100],
        [100, 200],
        [200, null]
      ] as Array<[number, number]>
    ).map((item) => {
      const label = item[1] ? item.join('-') : `${item[0]}+`;
      return new SelectItem('average-attendance', label, item);
    });

  const updateClearFiltersButtonVisibility = (values: FormikValues): void => {
    const isVisible =
      values.tags.length || values.averageAttendance.length || values.isCompletedCoaching;

    setIsClearButtonVisible(isVisible);
  };

  const getTagsSelectValues = (): Array<SelectItem<ChurchTag>> =>
    tags
      .filter((filteredtag) => filteredtag.name !== 'Auto' && filteredtag.name !== null)
      .map((tag): SelectItem<ChurchTag> => new SelectItem<ChurchTag>('tag', tag.name, tag));

  const closeModal = (): void => {
    dispatch({ type: ChurchListActionTypes.CloseApplyFiltersModalWithoutUpdate });
    setInitialValues(getInitialValues());
  };

  const resetForm = (setFieldValue: FormikHelpers<FormikValues>['setFieldValue']) => {
    setFieldValue('tags', []);
    setFieldValue('averageAttendance', []);
    setFieldValue('isCompletedCoaching', false);

    setIsClearButtonVisible(false);
  };

  const onSubmit = ({ tags, averageAttendance, isCompletedCoaching }: FormikValues): void => {
    const filters = new ChurchesFilters({
      ...state.filters,
      tags,
      isCompletedCoaching,
      averageAttendance
    });

    if (averageAttendance.length) {
      filters.name = '';
    }

    dispatch({ type: ChurchListActionTypes.CloseApplyFiltersModal, payload: { filters } });
  };

  return (
    <Modal
      open={state.isApplyFiltersModalOpened}
      toggleClose={closeModal}
      className="church-list-filters-modal"
      title={t('TEXT_APPLY_FILTERS')}
    >
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        {(props: FormikProps<FormikValues>): ReactElement => {
          useEffect(() => {
            updateClearFiltersButtonVisibility(props.values);
          }, [props.values.isCompletedCoaching]);

          return (
            <>
              <Row>
                <Col size={50}>
                  <div className="churches-filters-modal-content-completed">
                    <Field
                      name="isCompletedCoaching"
                      component={FormCheckboxGroup}
                      label={t('TEXT_EXCLUDE')}
                      touched={props.touched.isCompletedCoaching}
                      value={props.values.isCompletedCoaching}
                      checked={props.values.isCompletedCoaching}
                    >
                      {t('TEXT_EXCLUDE_COMPLETED_PEAK_CHURCHES')}
                    </Field>
                  </div>

                  <Field
                    name="averageAttendance"
                    component={FormCheckboxesGroups}
                    label={t('TEXT_AVERAGE_ATTENDANCE')}
                    onChange={() => updateClearFiltersButtonVisibility(props.values)}
                    items={getAverageAttendanceSelectValues()}
                    touched={props.touched.averageAttendance}
                    value={props.values.averageAttendance}
                  />
                </Col>
                <Col size={50}>
                  <Field
                    name="tags"
                    component={FormCheckboxesGroups}
                    label={t('TEXT_TAGS')}
                    onChange={() => updateClearFiltersButtonVisibility(props.values)}
                    items={getTagsSelectValues()}
                    touched={props.touched.tags}
                    value={props.values.tags}
                  />
                </Col>
              </Row>

              <FormButtons align="right">
                <Animation type="fade-in" animate={isClearButtonVisible}>
                  <Button color="blue-gray" onClick={() => resetForm(props.setFieldValue)}>
                    {t('BUTTON_CLEAR_FILTERS')}
                  </Button>
                </Animation>
                <Button color="green" type="submit">
                  {t('BUTTON_UPDATE_FILTERS')}
                </Button>
              </FormButtons>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}
