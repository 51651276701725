import React, { ReactElement } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Modal.scss';

interface Props {
  children: ReactElement;
  title?: string;
  isDisabled?: boolean;
  open?: boolean;
  toggleClose?: () => void;
  toggleOpen?: () => void;
  className?: string;
}

export default function Modal({ children, title, isDisabled, open, toggleClose, toggleOpen, className }: Props): ReactElement {
  return (
    <Popup
      open={open}
      disabled={isDisabled}
      onOpen={toggleOpen}
      position="center center"
      className={className}
      modal
      nested
      closeOnEscape
      closeOnDocumentClick
      onClose={toggleClose}
    >
      <a onClick={toggleClose} className="modal-close"></a>
      <div className="modal">
        <div className="modal-header">
          {title}
        </div>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </Popup>
  );
}
