import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContextProvider';
import Icon, { IconVariant } from '../../../components/Icon/Icon';
import Loader from '../../../components/Loader/Loader';
import Table from '../../../components/Table/Table';
import TableCell from '../../../components/Table/TableCell';
import TableHead from '../../../components/Table/TableHead';
import TableRow from '../../../components/Table/TableRow';
import { ChurchStatus } from '../../../enums/ChurchStatus';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import { Church } from '../../../models/church/Church';
import { ChurchDistrict } from '../../../models/church/ChurchDistrict';
import { ChurchesFilters } from '../../../models/church/ChurchesFilters';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchListContextProvider';
import ChurchListTableRow from '../ChurchListTableRow';
import supabase from '../../../supabaseClient';
import { plainToClass } from 'class-transformer';

export default function ChurchListTableRows(): ReactElement {
  const { state, dispatch } = useContext(ChurchListContext);
  const { state: appState } = useContext(AppContext);

  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_TABLE_ROW');

  const [districts, setDistrictsInState] = useState<Array<ChurchDistrict>>([]);
  const [churches, setChurches] = useState<Array<Church>>([]);

  useEffect(() => {
    setDistricts();
  }, []);

  useEffect(() => {
    if (districts.length) {
      fetchChurches();
    }
  }, [districts, state.currentPage, state.fetchTrigger, state.filters]);

  useEffect(() => {
    dispatch({
      type: ChurchListActionTypes.SetChurches,
      payload: { churches: churches }
    });
    dispatch({
      type: ChurchListActionTypes.SetIsLoading,
      payload: { value: false }
    });
  }, [churches]);

  const fetchChurches = async () => {
    dispatch({ type: ChurchListActionTypes.SetIsLoading, payload: { value: true } });
    try {
      const pageSize = state.churchesPerPage; // Number of items per page
      const pageIndex = state.currentPage - 1; // Supabase uses zero-based indexing for pages
      const offset = pageIndex * pageSize;

      let query = supabase
        .from('churches')
        // TODO - future: Do we need all columns?
        .select('*, districts(name)', { count: 'exact' }) // Include count for total pagination calculation
        .range(offset, offset + pageSize - 1);

      if (state.filters.name) {
        query = query.ilike('name', `%${state.filters.name}%`);
      }

      if (state.filters.district?.id) {
        query = query.eq('district_id', state.filters.district.id);
      }

      if (state.filters.status) {
        query = query.eq('status', state.filters.status);
      }

      const attendanceFilter = buildAttendanceFilterQueryString();
      if (attendanceFilter?.length > 0) {
        query = query.or(attendanceFilter);
      }

      // TODO - future: In the future, fix this somehow. When user checks 'filter by completed peak' checkbox, it should filter by the checkbox
      // if (state.filters?.isCompletedCoaching) {
      //   filteredChurches = filteredChurches.filter((item) => item?.isCoachingCompleted === true);
      // }

      if (state.filters.tags && state.filters.tags.length > 0) {
        const tagIds = state.filters.tags.map((tag) => tag.id);

        const { data } = await supabase
          .from('tag_to_church')
          .select('church_id')
          .in('tag_id', tagIds);

        if (data?.length > 0) {
          const churchIds = data.map((item) => item.church_id);

          query = query.in('church_id', churchIds);
        }
      }

      if (state.filters.orderBy) {
        const [field, direction] = state.filters.orderBy;
        query = query.order(field, { ascending: direction === 'asc' });
      } else {
        query = query.order('church_id', { ascending: false });
      }

      // Apply district filter for district users
      if (appState.userClaims.isDistrictUser && appState.userClaims.districtId) {
        query = query.eq('district_id', appState.userClaims.districtId);
      }

      const { data: loadedChurches, error, count } = await query;

      if (error) {
        console.error('Error when trying to load churches:', error);
        dispatch({ type: ChurchListActionTypes.SetIsLoading, payload: { value: false } });
        return;
      }

      const churchIds = loadedChurches
        .filter((church) => church.church_id !== null)
        .map((church) => church.church_id);

      // Fetch tags by their IDs
      let tagsByChurchId = [];
      const tagMappings = await fetchTagMappings(churchIds);
      if (tagMappings) {
        tagsByChurchId = transformTagMappings(tagMappings);
      }
      // Apply the joined district data to the church object
      const processedChurches = loadedChurches.map((church) => {
        church.tags = tagsByChurchId[church.church_id] ? tagsByChurchId[church.church_id] : [];
        church.district = church.districts
          ? { name: church.districts.name, id: church.district_id }
          : null;

        delete church.districts;
        return church;
      });

      setChurches(
        processedChurches
          .map((church) => {
            try {
              return plainToClass(Church, church);
            } catch (error) {
              console.error('Error transforming church:', church, error);
              return null;
            }
          })
          .filter((church) => church !== null)
      );

      if (count === 0) {
        dispatch({
          type: ChurchListActionTypes.SetTotalPages,
          payload: { totalPages: 1 }
        });
      } else {
        dispatch({
          type: ChurchListActionTypes.SetTotalPages,
          payload: { totalPages: Math.ceil(count / pageSize) }
        });
      }

      dispatch({
        type: ChurchListActionTypes.SetIsLoading,
        payload: { value: false }
      });
    } catch (error) {
      console.error('Error when trying to load churches:', error);
    }
    dispatch({ type: ChurchListActionTypes.SetIsLoading, payload: { value: false } });
  };

  function buildAttendanceFilterQueryString() {
    if (state.filters.averageAttendance?.length) {
      const conditions = state.filters.averageAttendance
        .map(([min, max]) => {
          if (min !== null && max !== null) {
            return `or(and(average_adult_attendance.gte.${min},average_adult_attendance.lte.${max}))`;
          } else if (min !== null) {
            return `or(average_adult_attendance.gte.${min})`;
          } else if (max !== null) {
            return `or(average_adult_attendance.lte.${max})`;
          }
        })
        .join(',');
      return conditions;
    }
  }
  function transformTagMappings(tagMappings) {
    const tagsByChurchId = tagMappings.reduce((acc, curr) => {
      const { church_id, tag_id, tags } = curr;
      if (!acc[church_id]) {
        acc[church_id] = [];
      }
      if (tags && tag_id) {
        acc[church_id].push({ id: tag_id, tagId: tag_id, name: tags.name });
      }
      return acc;
    }, {});

    return tagsByChurchId;
  }

  async function fetchTagMappings(churchIds) {
    const { data: tagMappings } = await supabase
      .from('tag_to_church')
      .select('tag_id, tags(name), church_id')
      .in('church_id', churchIds);

    return tagMappings;
  }

  const setDistricts = async (): Promise<void> => {
    try {
      const { data: districtsData, error } = await supabase
        .from('districts')
        .select('name, id')
        .order('name', { ascending: true });

      if (error) {
        throw error;
      }

      const formattedDistricts = districtsData.map(
        (item) =>
          new ChurchDistrict({
            name: item.name,
            id: item.id
          })
      );

      setDistrictsInState(formattedDistricts);
    } catch (error) {
      console.error('Error when trying to load districts:', error);
    }
  };

  const getOrderByValue = (fieldName: string): 'asc' | 'desc' => {
    if (state.filters.orderBy) {
      const [field, direction] = state.filters.orderBy;
      return field === fieldName ? (direction as 'asc' | 'desc') || 'asc' : 'asc';
    } else {
      return 'asc';
    }
  };

  const getOrderByIcon = (fieldName: string): IconVariant => {
    const orderByValue = getOrderByValue(fieldName);
    return orderByValue === 'desc' ? 'arrow-up' : 'arrow-down';
  };

  const changeOrderByFilter = (fieldName: string): void => {
    const currentOrderBy = state.filters.orderBy;
    const newDirection =
      currentOrderBy && currentOrderBy[0] === fieldName && currentOrderBy[1] === 'asc'
        ? 'desc'
        : 'asc';

    const filters = new ChurchesFilters({
      ...state.filters,
      orderBy: [fieldName, newDirection],
      limit: state.churchesPerPage // Reset limit to initial page size
    });

    dispatch({ type: ChurchListActionTypes.SetFilters, payload: { filters } });
    dispatch({
      type: ChurchListActionTypes.SetCurrentPage,
      payload: { page: 1 }
    });
    dispatch({ type: ChurchListActionTypes.TriggerFetch });
  };

  const getBasicTableHeadCells = (
    churchNameCellWidth: number,
    districtCellWidth: number,
    peakStatusCellWidth: number,
    tagsCellWidth: number
  ) => {
    const isDistrictCellVisible =
      !state.filters?.district?.name && !appState.userClaims?.isDistrictUser;
    const additionalWidth = isDistrictCellVisible ? 0 : districtCellWidth / 3;

    return [
      <TableCell
        key="church-name-head"
        label={t('TEXT_CHURCH')}
        width={churchNameCellWidth + additionalWidth}
        isClickable={true}
        icon={<Icon variant={getOrderByIcon('name')} />}
        onClick={() => changeOrderByFilter('name')}
        isHead
      />,
      isDistrictCellVisible && (
        <TableCell
          key="district-head"
          label={t('TEXT_DISTRICT')}
          width={districtCellWidth}
          isHead
        />
      ),
      <TableCell
        key="peak-status-head"
        label={t('TEXT_PEAK_STATUS')}
        width={peakStatusCellWidth + additionalWidth}
        isHead
      />,
      <TableCell
        key="tags-head"
        label={t('TEXT_TAGS')}
        width={tagsCellWidth + additionalWidth}
        isHead
      />
    ];
  };

  const renderTableHeadCells = () => {
    switch (state?.filters?.status) {
      case ChurchStatus.INQUIRY:
        return [
          ...getBasicTableHeadCells(17, 18, 15, 20),
          <TableCell
            key="inquiry-date-head"
            label={t('TEXT_INQUIRY')}
            width={15}
            isHead
            isClickable={true}
            icon={<Icon variant={getOrderByIcon('inquiry_form_date_created')} />}
            onClick={() => changeOrderByFilter('inquiry_form_date_created')}
          />,
          <TableCell
            key="intake-sent-head"
            label={t('TEXT_INTAKE_SENT')}
            width={15}
            align="center"
            isHead
          />
        ];
      case ChurchStatus.INTAKE:
        return [
          ...getBasicTableHeadCells(17, 18, 15, 20),
          <TableCell
            key="intake-date-head"
            label={t('TEXT_INTAKE')}
            width={15}
            isHead
            icon={<Icon variant={getOrderByIcon('intake_form_date_created')} />}
            isClickable={true}
            onClick={() => changeOrderByFilter('intake_form_date_created')}
          />,
          <TableCell
            key="intake-reviewed-head"
            label={t('TEXT_INTAKE_REVIEWED')}
            width={15}
            align="center"
            isHead
          />
        ];
      case ChurchStatus.COACHING:
        return [
          ...getBasicTableHeadCells(10, 10, 10, 15),
          <TableCell
            key="report-reviewed-head"
            label={t('TEXT_REPORT_REVIEWED')}
            width={13}
            align="center"
            isHead
          />,
          <TableCell
            key="coaching-meeting-date-head"
            label={t('TEXT_COACHING_MEETING')}
            width={15}
            isHead
            icon={<Icon variant={getOrderByIcon('coach_meeting_date')} />}
            isClickable={true}
            onClick={() => changeOrderByFilter('coach_meeting_date')}
          />,
          <TableCell key="coach-head" label={t('TEXT_COACH')} width={14} isHead />,
          <TableCell
            key="completed-coaching-head"
            label={t('TEXT_COMPLETED_COACHING')}
            width={13}
            align="center"
            isHead
          />
        ];
      case ChurchStatus.SURVEY:
        return [
          ...getBasicTableHeadCells(10, 9, 10, 10),
          <TableCell key="survey-status-head" label={t('TEXT_SURVEY_STATUS')} width={15} isHead />,
          <TableCell
            key="open-date-head"
            label={t('TEXT_OPEN')}
            width={10}
            isHead
            icon={<Icon variant={getOrderByIcon('survey_form_open_date')} />}
            isClickable={true}
            onClick={() => changeOrderByFilter('survey_form_open_date')}
          />,
          <TableCell
            key="close-date-head"
            label={t('TEXT_CLOSE')}
            width={10}
            isHead
            icon={<Icon variant={getOrderByIcon('survey_form_close_date')} />}
            isClickable={true}
            onClick={() => changeOrderByFilter('survey_form_close_date')}
          />,
          <TableCell key="complete-head" label={`% ${t('TEXT_COMPLETE')}`} width={13} isHead />,
          <TableCell
            key="survey-sent-head"
            label={t('TEXT_SURVEY_SENT')}
            width={13}
            align="center"
            isHead
          />
        ];
      default:
        return [
          ...getBasicTableHeadCells(20, 18, 15, 18),
          <TableCell
            key="survey-open-head"
            label={t('TEXT_SURVEY_OPEN')}
            width={16}
            isHead
            isClickable={true}
            icon={<Icon variant={getOrderByIcon('survey_form_open_date')} />}
            onClick={() => changeOrderByFilter('survey_form_open_date')}
          />,
          <TableCell
            key="attendance-head"
            label={t('TEXT_ATTENDANCE')}
            width={13}
            align="center"
            isHead
          />
        ];
    }
  };

  const onRefresh = useCallback(() => dispatch({ type: ChurchListActionTypes.TriggerFetch }), []);
  const onSelect = useCallback((item) => {
    dispatch({
      type: ChurchListActionTypes.SetSelectedChurch,
      payload: { church: item }
    });
  }, []);

  const churchList = useMemo(
    () =>
      state.churches.map((item) => (
        <ChurchListTableRow
          item={item}
          key={item.churchId}
          itemKey={item.churchId}
          isDistrictCellVisible={
            !state.filters?.district?.name && !appState.userClaims?.isDistrictUser
          }
          currentStatus={state.filters?.status}
          isSelected={item.churchId === state?.selectedChurch?.churchId}
          onRefresh={onRefresh}
          onSelect={onSelect}
        />
      )),
    [
      state.churches,
      state.filters?.district?.name,
      appState.userClaims?.isDistrictUser,
      state.filters?.status,
      state?.selectedChurch?.churchId
    ]
  );

  return (
    <div className="church-table-rows">
      {state.isLoading ? (
        <Loader />
      ) : (
        <Table>
          <TableHead>
            <TableRow key="table-head-row">{renderTableHeadCells()}</TableRow>
          </TableHead>
          {churchList}
        </Table>
      )}
    </div>
  );
}
