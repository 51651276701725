import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import Text from '../Svg/Elements/Text';
import View from '../Svg/Elements/View';
import { ElementUseFor } from '../Svg/Svg';

export default function ValueList({ values, useFor = ElementUseFor.PDF }: { values: Array<{value: number, label: string}>; useFor: ElementUseFor }): ReactElement {
  const pdfStyles = StyleSheet.create({
    list: {
      fontSize: 12
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 15
    },
    value: {
      width: 30,
      textAlign: 'right',
      marginRight: 15
    },
    label: {}
  });

  const browserStyles = {
    ...pdfStyles,
    list: {
      ...pdfStyles.list,
      height: '100%',
      overflowY: 'auto'
    },
    item: {
      display: 'block',
      marginBottom: 10,
      width: '100%'
    },
    value: {
      display: 'inline-block',
      width: 'calc(20% - 15px)',
      textAlign: 'right',
      marginRight: '15px',
      verticalAlign: 'middle'
    },
    label: {
      ...pdfStyles.label,
      width: '80%',
      display: 'inline-block',
      lineHeight: '14px',
      height: '14px',
      overflow: 'hidden',
      verticalAlign: 'middle'
    }
  };

  const styles = useFor === ElementUseFor.PDF ? pdfStyles : browserStyles;

  return (
    <View wrap={false} style={styles.list} useFor={useFor}>
      {values.map((item) => <View key={item.label} style={styles.item} useFor={useFor}>
        <Text style={styles.value} useFor={useFor} insideSVG={false}>{item.value}</Text>
        <Text style={styles.label} useFor={useFor} insideSVG={false}>{item.label}</Text>
      </View>)}
    </View>
  );
}
