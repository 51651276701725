import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ElementData } from '../../models/report/element-data';
import GraphChart from '../Charts/GraphChart/GraphChart';
import View from '../Svg/Elements/View';
import { ElementUseFor } from '../Svg/Svg';

export default function ElementBox({ element, useFor = ElementUseFor.PDF }: { element: ElementData; useFor?: ElementUseFor }): ReactElement {
  const styles = StyleSheet.create({
    element: {}
  });

  return (
    <>
      <View style={styles.element} useFor={useFor}>
        <GraphChart values={element.values}
        labels={element.labels} useFor={useFor}/>
      </View>
    </>
  );
}
