import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import DateTransformer from '../../helpers/DateTransformer';
import { ChurchFormResponse } from './ChurchFormResponse';

export class ChurchFormResult {
  @Type(() => ChurchFormResponse)
  @Expose()
  public responses!: Array<ChurchFormResponse>;

  @Expose({ name: 'date_created' })
  @Transform(DateTransformer)
  public dateCreated: DateTime;

  constructor(data: Partial<ChurchFormResult> = {}) {
    Object.assign(this, data);
  }
}
