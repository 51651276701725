import { Rect as ReactPDFRect } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ElementUseFor, ISVG } from '../Svg';

export interface ISVGRectProperties extends ISVG<any> {
  x: number;
  y: number;
  width: number;
  height: number;
  strokeDasharray?: string;
  fillRule?: any;
  textAnchor?: any;
  visibility?: any;
  dominantBaseline?: any;
}

export default function Rect(props: ISVGRectProperties): ReactElement {
  const { useFor, ...restProperties } = props;

  return useFor === ElementUseFor.PDF ? <ReactPDFRect {...restProperties} /> : <rect {...restProperties} />;
}
