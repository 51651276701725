import { Expose, Type } from 'class-transformer';
import { ChurchFormQuestionSubType } from '../../enums/ChurchFormQuestionSubType';
import { ChurchFormQuestionType } from '../../enums/ChurchFormQuestionType';
import { ChurchSurveyFormReportAnswer } from './answer';

export class ChurchSurveyFormReportQuestion {
  @Expose()
  public id!: string;

  @Expose({ name: 'origin_id' })
  public originID!: string;

  @Expose({ name: 'page_id' })
  public pageID!: string;

  @Expose({ name: 'page_position' })
  public pagePosition!: number;

  @Expose()
  public position!: number;

  @Expose()
  public question!: string;

  @Expose()
  public type!: ChurchFormQuestionType;

  @Expose({ name: 'sub_type' })
  public subType!: ChurchFormQuestionSubType;

  @Expose({ name: 'answer_options' })
  @Type(() => ChurchSurveyFormReportAnswer)
  public answerOptions!: Array<ChurchSurveyFormReportAnswer> | null;

  @Expose({ name: 'sub_questions' })
  @Type(() => ChurchSurveyFormReportQuestion)
  public subQuestions!: Array<ChurchSurveyFormReportQuestion> | null;

  @Type(() => Number)
  @Expose()
  public answered!: number;

  @Type(() => Number)
  @Expose()
  public skipped!: number;

  @Type(() => Number)
  @Expose()
  public value!: number | null;

  @Expose()
  public texts!: Array<string> | null;

  constructor(data: Partial<ChurchSurveyFormReportQuestion> = {}) {
    Object.assign(this, data);
  }
}
