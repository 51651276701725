import { G as ReactPDFG } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ElementUseFor, ISVG } from '../Svg';

export interface ISVGGProperties extends ISVG<any> {
  strokeDasharray?: string;
  fillRule?: any;
  textAnchor?: any;
  visibility?: any;
  dominantBaseline?: any;
}

export default function G(props: ISVGGProperties): ReactElement {
  const { useFor, ...restProperties } = props;

  return useFor === ElementUseFor.PDF ? <ReactPDFG {...restProperties} /> : <g {...restProperties} />;
}
