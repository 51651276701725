import React, { ReactElement, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { SelectItem } from '../../models/SelectItem';
import useOnclickOutside from 'react-cool-onclickoutside';
import './Dropdown.scss';

interface Props {
  children: ReactNode;
  direction: 'left' | 'right';
  items: Array<SelectItem>;
  selectItem: (_item: SelectItem) => void;
}

Dropdown.defaultProps = {
  direction: 'right'
};

function Dropdown({ children, direction, items, selectItem }: Props): ReactElement {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const iconClasses = classNames(
    'icon', 'icon-arrow-down', 'icon-mask', 'icon-mask-black', { 'icon-arrow-top': isDropdownOpen }
  );

  const ref = useOnclickOutside(() => {
    setIsDropdownOpen(false);
  });

  return (
    <div className={classNames('dropdown', { 'open': isDropdownOpen })}>
      <div
        className="dropdown-trigger"
        onClick={() => toggleDropdown()}
      >
        {children}
        <span className={iconClasses} />
      </div>

      <div className={classNames('dropdown-content', `dropdown-content-${direction}`)} ref={ref}>
        {
          items.map((item, index) => (
            <a
              className="dropdown-content-item"
              key={index}
              onClick={() => { selectItem(item); toggleDropdown(); }}
            >
              {item.label}
            </a>
          ))
        }
      </div>
    </div>
  );
}

export default Dropdown;
