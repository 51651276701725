import { useState, useEffect, useRef, useContext } from 'react';
import supabase from './supabaseClient';
import { AppContext, AppActionTypes } from './AppContextProvider';
import { UserClaims } from './models/user/UserClaims';

export function useSupabaseUser(): any | null {
    const [user, setUser] = useState(null);
    const isComponentMounted = useRef(true);
    const { dispatch: appDispatch } = useContext(AppContext);

    useEffect(() => {
        isComponentMounted.current = true;

        const updateUser = async (session) => {
            if (session?.user) {
                const userWithDisplayName = { ...session.user, displayName: '' };
                const { data: userData, error } = await supabase
                    .from('users')
                    .select('*')
                    .eq('email', session.user.email)
                    .single();

                if (error) {
                    console.error('Error fetching user data:', error);
                } else {
                    userWithDisplayName.displayName = `${userData.first_name} ${userData.last_name}`;
                    if (isComponentMounted.current) {
                        setUser(userWithDisplayName);

                        const userClaims = new UserClaims({
                            districtId: userData.district_id,
                            role: userData.role,
                            type: userData.type
                        });

                        appDispatch({ type: AppActionTypes.SetUserClaims, payload: { userClaims } });
                    }
                }
            } else {
                if (isComponentMounted.current) {
                    setUser(null);
                }
            }
        };

        // Subscribe to auth changes
        const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
            updateUser(session);
        });

        // Cleanup function
        return () => {
            // Unsubscribe from auth changes
            listener?.subscription?.unsubscribe();
            isComponentMounted.current = false;
        };
    }, []);

    return user;
}
