import React, { ReactElement, ReactNode } from 'react';
import './PageLayout.scss';

export interface Props {
  children: ReactNode;
}

export default function AuthPageLayout(props: Props): ReactElement {
  return (
    <div className="page-layout page-layout-auth">
      <div className="page-layout-auth-logo">
        <img src={'/img/peak-logo.png'} alt={'PEAK'} />
      </div>

      <div className="page-layout-auth-content">
        {props.children}
      </div>
    </div>
  );
}
