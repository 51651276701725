import { Field, Formik, FormikProps, FormikValues } from 'formik';
import { DateTime } from 'luxon';
import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import CompletedProgressBar from '../../components/CompletedProgressBar/CompletedProgressBar';
import FormCheckboxGroup from '../../components/Form/FormCheckboxGroup';
import Status from '../../components/Status/Status';
import TableCell from '../../components/Table/TableCell';
import TableRow from '../../components/Table/TableRow';
import Tag from '../../components/Tag/Tag';
import { ChurchStatus } from '../../enums/ChurchStatus';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { Church } from '../../models/church/Church';
import supabase from '../../supabaseClient';
import { callSupabaseEdgeFunction } from '../../helpers/SupabaseFunctions';
import { ChurchListActionTypes, ChurchListContext } from './ChurchListContextProvider';

interface Props {
  item: Church;
  itemKey: number;
  isDistrictCellVisible: boolean;
  currentStatus: ChurchStatus;
  isSelected: boolean;
  onRefresh: () => void;
  onSelect: (item: Church) => void;
}

function ChurchListTableRow({
  item,
  itemKey,
  isDistrictCellVisible,
  currentStatus,
  isSelected,
  onRefresh,
  onSelect
}: Props): ReactElement {
  const { dispatch } = useContext(ChurchListContext);
  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_TABLE_ROW');

  const renderCheckboxColumn = (
    columnLabel: string,
    itemValue: boolean,
    cellWidth: number
  ): ReactElement =>
    item && (
      <TableCell key={columnLabel} label={columnLabel} width={cellWidth} align="center">
        <Formik
          initialValues={{ value: itemValue !== undefined ? itemValue : false }}
          onSubmit={() => null}
        >
          {(props: FormikProps<FormikValues>): ReactElement => {
            const { values } = props;
            const isFirstRun = useRef(true);

            // Note, in the original firebase data those settings were kept in the form.
            // Since the transition to PostgreSQL those values are now flattened
            const updateIntakeFormIsReviewedValue = async (value: boolean) => {
              dispatch({ type: ChurchListActionTypes.SetIsLoading, payload: { value: true } });

              if (value) {
                await callSupabaseEdgeFunction('on-church-marked-as-reviewed', 'POST', { item });
              }

              const { data: intakeFormData } = await supabase
                .from('churches')
                .select('intake_form')
                .eq('church_id', item.churchId)
                .single();

              const updatedIntakeForm = {
                ...intakeFormData.intake_form,
                result: {
                  ...intakeFormData.intake_form.result,
                  is_reviewed: value
                }
              };

              console.log('updating status to survey ');
              await supabase
                .from('churches')
                .update({
                  status: 'survey',
                  intake_form_is_reviewed: value,
                  intake_form: updatedIntakeForm
                })
                .eq('church_id', item.churchId);
              onRefresh();
              dispatch({ type: ChurchListActionTypes.SetIsLoading, payload: { value: false } });
            };

            const updateSurveyFormIsSentValue = async (value: boolean) => {
              await supabase
                .from('churches')
                .update({ survey_form_is_sent: value })
                .eq('church_id', item.churchId);
              // TODO: Also edit this: doc?.survey_form?.survey_date?.is_sent
            };

            const updateIntakeFormIsSentValue = async (value: boolean) => {
              await supabase
                .from('churches')
                .update({ intake_form_is_sent: value })
                .eq('church_id', item.churchId);
              // TODO: Also edit this: doc?.intake_form?.result?.is_sent
            };

            useEffect(() => {
              if (isFirstRun.current) {
                isFirstRun.current = false;
                return;
              }

              switch (item.status) {
                case ChurchStatus.INQUIRY:
                  updateIntakeFormIsSentValue(values.value);
                  break;
                case ChurchStatus.INTAKE:
                  updateIntakeFormIsReviewedValue(values.value);
                  break;
                case ChurchStatus.SURVEY:
                  updateSurveyFormIsSentValue(values.value);
                  break;
                default:
                  break;
              }
            }, [values]);

            return (
              <Field
                name="value"
                component={FormCheckboxGroup}
                onChange={props.handleChange}
                type="checkbox"
                error={props.errors.value}
                touched={props.touched.value}
                value={props.values.value || ''}
                checked={props.values.value}
              />
            );
          }}
        </Formik>
      </TableCell>
    );

  const getBasicTableRowCells = (
    churchNameCellWidth: number,
    districtCellWidth: number,
    peakStatusCellWidth: number,
    tagsCellWidth: number
  ) => {
    const additionalWidth = isDistrictCellVisible ? 0 : districtCellWidth / 3;

    return [
      <TableCell
        key="church-name"
        label={t('TEXT_CHURCH')}
        width={churchNameCellWidth + additionalWidth}
      >
        {item.name}
      </TableCell>,
      isDistrictCellVisible && (
        <TableCell key="district" label={t('TEXT_DISTRICT')} width={districtCellWidth}>
          {item.district?.name}
        </TableCell>
      ),
      <TableCell
        key="peak-status"
        label={t('TEXT_PEAK_STATUS')}
        width={peakStatusCellWidth + additionalWidth}
      >
        <Status type={item.status} />
      </TableCell>,
      <TableCell key="tags" label={t('TEXT_TAGS')} width={tagsCellWidth + additionalWidth}>
        {item.tags
          ?.filter((tag) => tag.name !== 'auto')
          .map((tag, tagIndex) => (
            <Tag key={'tag-' + itemKey + tagIndex} tagText={tag.name} />
          ))}
      </TableCell>
    ];
  };

  const formatDate = (date: DateTime): string => date.isValid && date.toFormat('M/d/yyyy');

  const renderCells = () => {
    switch (currentStatus) {
      case ChurchStatus.INQUIRY:
        return [
          ...getBasicTableRowCells(17, 18, 15, 20),
          <TableCell key="inquiry-date" label={t('TEXT_INQUIRY')} width={15}>
            {!!item.inquiryForm?.result?.dateCreated &&
              formatDate(item.inquiryForm.result.dateCreated)}
          </TableCell>,
          renderCheckboxColumn(t('TEXT_INTAKE_SENT'), item?.intakeFormIsSent, 15)
        ];
      case ChurchStatus.INTAKE:
        return [
          ...getBasicTableRowCells(17, 18, 15, 20),
          <TableCell key="intake-date" label={t('TEXT_INTAKE')} width={15}>
            {item.intakeForm?.result?.dateCreated && formatDate(item.intakeForm.result.dateCreated)}
          </TableCell>,
          renderCheckboxColumn(t('TEXT_INTAKE_REVIEWED'), item?.intakeFormIsReviewed, 15)
        ];
      case ChurchStatus.COACHING:
        return [
          ...getBasicTableRowCells(10, 10, 10, 15),
          renderCheckboxColumn(t('TEXT_REPORT_REVIEWED'), item.reportReviewed, 13),
          <TableCell key="coaching-meeting-date" label={t('TEXT_COACHING_MEETING')} width={15}>
            {item.coachMeetingDate && formatDate(item.coachMeetingDate)}
          </TableCell>,
          <TableCell key="coach" label={t('TEXT_COACH')} width={14}>
            {item?.coachName}
          </TableCell>,
          renderCheckboxColumn(t('TEXT_COMPLETED_COACHING'), item?.isCoachingCompleted, 13)
        ];
      case ChurchStatus.SURVEY:
        return [
          ...getBasicTableRowCells(10, 9, 10, 10),
          <TableCell key="survey-status" label={t('TEXT_SURVEY_STATUS')} width={15}>
            <Status type={item.surveyFormStatus} />
          </TableCell>,
          <TableCell key="open-date" label={t('TEXT_OPEN')} width={10}>
            {item.surveyFormOpenDate && formatDate(item.surveyFormOpenDate)}
          </TableCell>,
          <TableCell key="close-date" label={t('TEXT_CLOSE')} width={10}>
            {item.surveyFormCloseDate && formatDate(item.surveyFormCloseDate)}
          </TableCell>,
          <TableCell key="complete" label={`% ${t('TEXT_COMPLETE')}`} width={13}>
            <CompletedProgressBar
              title={`${item.surveyForm?.result?.completedSurveys || 0} ${t('TEXT_OF')} ${
                item.averageAdultAttendance || 0
              } -- ${Math.floor(
                ((item.surveyForm?.result?.completedSurveys || 0) /
                  (item.averageAdultAttendance || 0)) *
                  100
              )}%`}
              fillPercentage={Math.floor(
                ((item.surveyForm?.result?.completedSurveys || 0) /
                  (item.averageAdultAttendance || 0)) *
                  100
              )}
              limitOfSuccess={30}
            />
          </TableCell>,
          renderCheckboxColumn(t('TEXT_SURVEY_SENT'), item.surveyForm?.isSent, 13)
        ];
      default:
        return [
          ...getBasicTableRowCells(20, 18, 15, 18),
          <TableCell key="survey-open" label={t('TEXT_SURVEY_OPEN')} width={16}>
            {item.surveyFormOpenDate && formatDate(item.surveyFormOpenDate)}
          </TableCell>,
          <TableCell key="attendance" label={t('TEXT_ATTENDANCE')} width={13} align="center">
            {item.averageAdultAttendance}
          </TableCell>
        ];
    }
  };

  return (
    item && (
      <TableRow
        key={'table-body-row' + itemKey}
        rowOnClick={() => !isSelected && onSelect(item)}
        isSelected={isSelected}
        withLink
      >
        {renderCells()}
      </TableRow>
    )
  );
}

export default React.memo(ChurchListTableRow);
