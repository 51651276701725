import React, { ReactElement, useContext } from 'react';
import './ChurchListPagination.scss';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchListContextProvider';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import ReactPaginate from 'react-paginate';

export default function ChurchListPagination(): ReactElement {
  const { state, dispatch } = useContext(ChurchListContext);
  const { currentPage, totalPages } = state;
  const t = useTranslationByKey('CONTAINERS.CHURCH_LIST.CHURCH_LIST_PAGINATION');

  const handlePageClick = (selectedItem: { selected: number }) => {
    const newPage = selectedItem.selected + 1; // react-paginate is 0-indexed
    dispatch({ type: ChurchListActionTypes.SetCurrentPage, payload: { page: newPage } });
    dispatch({ type: ChurchListActionTypes.TriggerFetch });
  };

  return (

    <div className="church-pagination">
      <ReactPaginate
        previousLabel={t('BUTTON_PREVIOUS_PAGE')}
        nextLabel={t('BUTTON_NEXT_PAGE')}
        breakLabel={'...'}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
        forcePage={currentPage - 1}
      />
    </div>
  );
}
