import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function reGenerateReport(churchId: string, surveyID: string, collectorID: string, closeSurvey: boolean) {

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_REGENERATION_SERVER_URL,
        // baseURL: 'https://peak-report-generator-dseby2eyma-uc.a.run.app',
        //  baseURL: 'https://peak-report-generator-oexzmsgmia-uc.a.run.app',
        timeout: 180000
    });
    //regenerate survey
    console.log(`Start regenerate report for church_id: ${churchId} survey #: ${surveyID}`);

    try {
        await axiosClient.post('/regenerate', [
            {
                churchId: churchId,
                surveyFormID: surveyID,
                collectorID,
                closeSurvey
            }
        ]);
    } catch (e) {
        console.log(`error: ${JSON.stringify(e)}`);
    }
}

