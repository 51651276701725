import { Path as ReactPDFPath } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ElementUseFor, ISVG } from '../Svg';

export interface ISVGPathProperties extends ISVG<any> {
  d: string;
  strokeDasharray?: string;
  fillRule?: any;
  textAnchor?: any;
  visibility?: any;
  dominantBaseline?: any;
}

export default function Path(props: ISVGPathProperties): ReactElement {
  const { useFor, ...restProperties } = props;

  return useFor === ElementUseFor.PDF ? <ReactPDFPath {...restProperties} /> : <path {...restProperties} />;
}
