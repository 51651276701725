import React, { ReactElement } from 'react';
import './Form.scss';

export interface Props {
  errorMessage: string;
}

export default function FormServerError({ errorMessage }: Props): ReactElement {
  return (
    <div className="form-errors">
      <div className="form-error">{errorMessage}</div>
    </div>
  );
}
