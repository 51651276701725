import { Exclude, Expose } from 'class-transformer';
import { UserRole } from '../../enums/UserRole';
import { UserType } from '../../enums/UserType';

export class UserClaims {
  @Expose()
  public districtId: number;

  @Expose()
  public district: string;

  @Expose()
  public role: UserRole;

  @Expose()
  public type: UserType;

  @Exclude()
  public get isAdmin(): boolean {
    return this.role === UserRole.ADMIN;
  }

  @Exclude()
  public get isFullAccessUser(): boolean {
    return this.role === UserRole.FULL;
  }

  @Exclude()
  public get isDistrictUser(): boolean {
    return this.type === UserType.DISTRICT;
  }

  constructor(model: Partial<UserClaims> = {}) {
    Object.assign(this, model);
  }
}
