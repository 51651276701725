import { Push, push } from 'connected-react-router';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import FormGroup from '../../components/Form/FormGroup';
import FormServerError from '../../components/Form/FormServerError';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link/Link';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import supabase from '../../supabaseClient';

interface Props {
  push: Push;
}

function AuthForm(props: Props): ReactElement {
  const t = useTranslationByKey('CONTAINERS.AUTH_FORM');

  const [errorResponse, setErrorResponse] = useState<Error | null>(null);
  const [isTryingSignIn, setIsTryingSignIn] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('ERRORS.TEXT_INVALID_EMAIL_ADDRESS'))
      .required(t('ERRORS.TEXT_EMAIL_IS_REQUIRED')),
    password: Yup.string().required(t('ERRORS.TEXT_PASSWORD_IS_REQUIRED'))
  });

  const onSubmit = async (values: FormikValues) => {
    setErrorResponse(null);
    setIsTryingSignIn(true);

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password
      });

      console.log('login error:');
      console.log(error);

      if (error) {
        throw new Error('Unable to login. Try again later.');
      }
      props.push('/dashboard');
    } catch (error) {
      setErrorResponse(error);
      setIsTryingSignIn(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props: FormikProps<FormikValues>): ReactElement => {
        const { values } = props;

        useEffect(() => {
          setErrorResponse(null);
        }, [values]);

        return (
          <Form className="form auth-form">
            <h1>{t('TEXT_LOGIN')}</h1>

            <Field
              name="email"
              component={FormGroup}
              icon={<Icon variant="user" />}
              onChange={props.handleChange}
              type="email"
              placeholder={t('TEXT_EMAIL')}
              error={props.errors.email}
              touched={props.touched.email}
              value={props.values.email}
            />
            <Field
              name="password"
              component={FormGroup}
              icon={<Icon variant="lock" />}
              onChange={props.handleChange}
              type="password"
              placeholder={t('TEXT_PASSWORD')}
              error={props.errors.password}
              touched={props.touched.password}
              value={props.values.password}
            />

            {errorResponse && <FormServerError errorMessage={errorResponse.message} />}

            <div className="form-buttons">
              <Button color="blue-gray" isBlock={true} isLoading={isTryingSignIn} type="submit">
                {t('BUTTON_SIGN_IN')}
              </Button>

              <Link to="/reset-password" variant="light-gray">
                {t('LINK_CHANGE_YOUR_PASSWORD')}
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default connect(null, { push })(AuthForm);
