import React, { ChangeEvent, ReactElement, useReducer } from 'react';
import { FieldInputProps } from 'formik/dist/types';
import { SelectItem } from '../../models/SelectItem';
import { find, isEqual, remove } from 'lodash';
import './Form.scss';
import FormLabel from './FormLabel';
import FormCheckboxGroup from './FormCheckboxGroup';

interface Props {
  field: FieldInputProps<Array<typeof SelectItem.prototype.value>>;
  items: Array<SelectItem>;
  onChange?: (_value: Array<typeof SelectItem.prototype.value>) => void;
  label: string;
}

export default function FormCheckboxesGroups({
  field,
  items,
  label,
  onChange
}: Props): ReactElement {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const isChecked = (value: typeof SelectItem.prototype.value): boolean =>
    !!find(field.value, (fieldValue) => isEqual(fieldValue, value));

  const onChecked = (
    e: ChangeEvent<HTMLInputElement>,
    value: typeof SelectItem.prototype.value
  ) => {
    if (e.target.checked) {
      field.value.push(value);
    } else {
      remove(field.value, (fieldValue) => isEqual(fieldValue, value));
    }

    if (onChange) {
      onChange(field.value);
    }

    forceUpdate();
  };

  return (
    <div className="form-group-checkboxes">
      {label && <FormLabel>{label}</FormLabel>}
      <div className="form-control-container">
        {items.map((item, index) => (
          <FormCheckboxGroup
            key={index}
            checked={isChecked(item.value)}
            field={{
              name: field.name + index,
              value: item.value,
              onChange: (e) => onChecked(e, item.value),
              onBlur: field.onBlur
            }}
          >
            {item.label}
          </FormCheckboxGroup>
        ))}
      </div>
    </div>
  );
}
