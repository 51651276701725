import { Exclude, Expose, Transform } from 'class-transformer';
import { UserRole } from '../../enums/UserRole';
import { UserType } from '../../enums/UserType';
import DateTransformer from '../../helpers/DateTransformer';
import { DateTime } from 'luxon';

export class User {
  @Expose()
  public id: number;

  @Expose()
  public email: string;

  @Expose({ name: 'first_name' })
  public firstName: string;

  @Expose({ name: 'last_name' })
  public lastName: string;

  @Expose()
  public role: UserRole;

  @Expose()
  public type: UserType;

  @Expose({ name: 'district_id' })
  public districtId: number;

  @Expose({ name: 'created_at' })
  @Transform(DateTransformer)
  public createdAt: DateTime;

  @Exclude({ toPlainOnly: true })
  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(model: Partial<User> = {}) {
    Object.assign(this, model);
  }
}
