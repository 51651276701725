import { ChurchStatus } from '../../enums/ChurchStatus';
import { ChurchDistrict } from './ChurchDistrict';
import { ChurchTag } from './ChurchTag';

export class ChurchesFilters {
  public name: string;
  public status: ChurchStatus;
  public district: ChurchDistrict;
  public orderBy: [string, string];
  public tags: Array<ChurchTag>;
  public isCompletedCoaching: boolean;
  public averageAttendance: Array<[number, number]>;
  public limit?: number;
  public pageSize: number;
  public pageIndex: number;

  constructor(model: Partial<ChurchesFilters> = {}) {
    Object.assign(this, model);
  }
}
