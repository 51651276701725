import { Expose, Type } from 'class-transformer';
import { ChurchCollector } from './ChurchCollector';
import { ChurchFormQuestion } from './ChurchFormQuestion';
import { ChurchFormResult } from './ChurchFormResult';

export class ChurchForm {
  @Expose()
  public id!: string;

  @Type(() => ChurchFormResult)
  @Expose()
  public result: ChurchFormResult;

  @Expose()
  @Type(() => ChurchFormQuestion)
  public questions: Array<ChurchFormQuestion>;

  @Type(() => ChurchCollector)
  @Expose()
  public collector: ChurchCollector;

  constructor(data: Partial<ChurchForm> = {}) {
    Object.assign(this, data);
  }
}
