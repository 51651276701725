import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Animation.scss';

interface Props {
  type: AnimationType;
  animate: boolean;
  children: ReactNode;
}

export type AnimationType = 'fade-in-down' | 'fade-in';

export default function Animation({ type, animate, children }: Props): ReactElement {
  return(
    <div className={classNames(
      `animation-${type}`,
      { 'is-animating': animate }
    )}>
      {children}
    </div>
  );
}
