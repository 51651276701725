import { Expose, Type } from 'class-transformer';

export class ChurchFormAnswer {
  @Expose({ name: 'question_id' })
  public questionID!: string;

  @Expose({ name: 'origin_question_id' })
  public originQuestionID!: string;

  @Expose({ name: 'texts' })
  public texts!: Array<string> | null;

  @Expose({ name: 'selected_options_ids' })
  public selectedOptionsIDS!: Array<string> | null;

  @Expose({ name: 'origin_selected_options_ids' })
  public originSelectedOptionsIDS!: Array<string> | null;

  @Expose({ name: 'answers_to_sub_questions' })
  @Type(() => ChurchFormAnswer)
  public answersToSubQuestions!: Array<ChurchFormAnswer> | null;

  constructor(data: Partial<ChurchFormAnswer> = {}) {
    Object.assign(this, data);
  }
}
