import React, { ReactElement, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { plainToClass } from 'class-transformer';
import 'yup-phone';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Modal from '../../components/Modal/Modal';
import {
  ChurchListActionTypes,
  ChurchListContext
} from '../../containers/ChurchList/ChurchListContextProvider';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { Progress } from '../../models/Progress/Progress';
import './ProgressBar.scss';
import supabase from '../../supabaseClient';
import { Church } from '../../models/church/Church';
import { toast } from 'react-toastify';

export default function ProgressBar(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL.PROGRESS_MODAL');
  const { state } = useContext(ChurchListContext);
  const { state: appState, dispatch } = useContext(AppContext);
  const { dispatch: churchListDispatch } = useContext(ChurchListContext);
  const selectedChurch = state.selectedChurch ? state.selectedChurch : null;
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [hasFetchedInitialProgress, setHasFetchedInitialProgress] = useState(false);

  // Function to close the modal programmatically (when process completes)
  const closeModalProgrammatically = useCallback((): void => {
    if (!isModalClosed) {
      dispatch({ type: AppActionTypes.CloseProgressBarModal });
      toast.success('Process completed successfully!', {
        autoClose: 5000, // Automatically closes the toast after 5 seconds
        pauseOnFocusLoss: false // Ensures the toast shows even if the tab is not in focus
      });
      setIsModalClosed(true);
      // Clear the interval when modal is closed
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [dispatch, isModalClosed]);

  // Function to close the modal manually (when user closes it)
  const closeModalManually = useCallback((): void => {
    if (!isModalClosed) {
      dispatch({ type: AppActionTypes.CloseProgressBarModal });
      setIsModalClosed(true);
      // Clear the interval when modal is closed
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [dispatch, isModalClosed]);

  useEffect(() => {
    if (!selectedChurch) {
      return;
    }

    const checkProgress = async () => {
      try {
        const { data: query, error } = await supabase
          .from('progress')
          .select('*')
          .eq('church', selectedChurch.name)
          .order('auto_increment_id', { ascending: false })
          .limit(1)
          .maybeSingle();

        if (error) {
          if (error.code === 'PGRST116') {
            if (hasFetchedInitialProgress) {
              // No rows returned after initial fetch, assume process complete
              setCurrentValue(maxValue || 0);
              closeModalProgrammatically();
            } else {
              // First fetch, no progress data yet. Maybe process hasn't started.
              // Optionally, you can decide to close or keep the modal open based on your logic
            }
          } else {
            // Handle other errors if necessary
            setErrorMessage('An error occurred while fetching progress.');
            // Optionally close the modal or keep it open to show the error
          }
          return;
        }

        if (query) {
          const progress = plainToClass(Progress, query);

          // Check if there is an error message
          if (progress.error_message) {
            if (progress.error_message === 'Too many requests were made, try again later.') {
              setErrorMessage(
                'Too many requests were made to Survey Monkey.\n\nYou might have too many "Collect responses" processes running. Wait a minute and try again.'
              );
            } else {
              setErrorMessage(progress.error_message);
            }
            return;
          }

          // Mark that initial progress has been fetched
          if (!hasFetchedInitialProgress) {
            setHasFetchedInitialProgress(true);
          }

          // Set maxValue if not set
          if (maxValue === null) {
            setMaxValue(progress.max);
          }

          setCurrentValue(progress.value);

          // Determine if progress is complete
          if (progress.value >= (maxValue || progress.max)) {
            closeModalProgrammatically();

            // Fetch updated church data
            const { data: updatedChurch, error: updateError } = await supabase
              .from('churches')
              .select('*')
              .eq('church_id', selectedChurch.churchId)
              .single();

            if (updateError) {
              setErrorMessage('Failed to update church information.');
              return;
            }

            if (updatedChurch) {
              const mappedChurch: Church = plainToClass(Church, updatedChurch);

              churchListDispatch({
                type: ChurchListActionTypes.SetSelectedChurch,
                payload: { church: mappedChurch }
              });
              churchListDispatch({ type: ChurchListActionTypes.TriggerFetch });
            }
          }
        }
      } catch (err) {
        setErrorMessage('An unexpected error occurred.');
        console.error('Unexpected error in checkProgress:', err);
      }
    };

    // Initial check
    checkProgress();

    // Set up polling every 10 seconds
    intervalRef.current = setInterval(checkProgress, 10000);

    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [
    selectedChurch,
    maxValue,
    closeModalProgrammatically,
    churchListDispatch,
    hasFetchedInitialProgress
  ]);

  return (
    <Modal
      open={appState.isProgressBarModalOpened}
      toggleClose={closeModalManually} // User can close the modal manually via this function
      className="church-close-survey-modal"
      title={t('TEXT_TITLE')}
    >
      {errorMessage ? (
        <div className="error-message">{errorMessage}</div>
      ) : (
        <>
          <progress className="progress-holder" value={currentValue || 0} max={maxValue || 0} />
          <br />
          <div className="loading-spinner"></div>{' '}
          {maxValue && currentValue ? (
            <span>{Math.round((currentValue / maxValue) * 100)}%</span>
          ) : (
            <span>0%</span>
          )}
        </>
      )}
    </Modal>
  );
}
