import { Expose, Type } from 'class-transformer';
import { ChurchForm } from './ChurchForm';
import { ChurchInquiryForm } from './ChurchInquiryForm';
import { ChurchIntakeFormResult } from './ChurchIntakeFormResult';

export class ChurchIntakeForm extends ChurchForm {
  @Expose()
  @Type(() => ChurchIntakeFormResult)
  public result: ChurchIntakeFormResult;

  constructor(data: Partial<ChurchInquiryForm> = {}) {
    super(data);
    Object.assign(this, data);
  }
}
