import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { ReactElement } from 'react';
import { ChurchStatus } from '../../enums/ChurchStatus';
import { ChurchSurveyStatus } from '../../enums/ChurchSurveyStatus';
import './Status.scss';

export interface Props {
  type: ChurchStatus | ChurchSurveyStatus
}

export default function Status({ type }: Props): ReactElement {
  return (
    <span
      className={classNames(
        'status',
        {
          'status-inquiry': type === ChurchStatus.INQUIRY,
          'status-intake': type === ChurchStatus.INTAKE,
          'status-survey': type === ChurchStatus.SURVEY,
          'status-coaching': type === ChurchStatus.COACHING,
          'status-pre': type === ChurchSurveyStatus.PRE,
          'status-active': type === ChurchSurveyStatus.ACTIVE,
          'status-extended': type === ChurchSurveyStatus.EXTENDED,
          'status-closed': type === ChurchSurveyStatus.CLOSED
        }
      )}
    >
      {capitalize(type)}
    </span>
  );
}
