import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import sagas from './sagas';
import { logger } from 'redux-logger';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const rootReducer = (history) => combineReducers({
  router: connectRouter(history)
});

function* rootSaga() {
  yield all([sagas()]);
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), thunkMiddleware, sagaMiddleware];
const enhancer = (process.env.NODE_ENV === 'development')
  ? composeWithDevTools(applyMiddleware(...middlewares, logger))
  : compose(applyMiddleware(...middlewares));

const store = createStore(
  rootReducer(history),
  enhancer
);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof rootReducer>;
export default store;
