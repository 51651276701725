import { FieldInputProps, FormikProps, FormikValues } from 'formik/dist/types';
import React, { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import FormControl, { FormControlSize } from './FormControl';
import FormError from './FormError';
import FormLabel from './FormLabel';
import FormSelectControl from './FormSelectControl';
import { OptionsType } from 'react-select';
import { SelectItem } from '../../models/SelectItem';
import classNames from 'classnames';
import './Form.scss';
import FormDatePickerControl from './FormDatePickerControl';

interface Props {
  field?: FieldInputProps<string | number | typeof SelectItem.prototype.value>;
  input?: InputHTMLAttributes<HTMLInputElement>;
  form?: FormikProps<FormikValues>;
  error?: string;
  touched?: boolean;
  type?: string;
  label?: string;
  placeholder?: string;
  size?: FormControlSize
  icon?: ReactNode;
  options?: OptionsType<SelectItem>;
  className?: string;
  selectedByDefaultItemIndex?: number;
  isDisabled?: boolean;
}

export default function FormGroup({ label, error, type, className, touched, field, ...inputProps }: Props): ReactElement {
  const renderControl = () => {
    const inputPropsWithError = {
      ...inputProps,
      isError: !!(touched && error)
    };

    switch(type) {
      case 'select':
        return (
          <FormSelectControl
            field={field as FieldInputProps<typeof SelectItem.prototype.value>}
            {...inputPropsWithError}
          />
        );
      case 'date':
        return (
          <FormDatePickerControl
            field={field as FieldInputProps<string>}
            {...inputPropsWithError}
          />
        );
      default:
        return (
          <FormControl
            field={field as FieldInputProps<string | number>}
            type={type}
            {...inputPropsWithError}
          />
        );
    }
  };

  return (
    <div className={classNames(
      'form-group',
      className ?? ''
    )}>
      {label && <FormLabel>{label}</FormLabel>}
      {renderControl()}
      {touched && error && <FormError>{error}</FormError>}
    </div>
  );
}
