import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { Church } from '../../../models/church/Church';
import { ReportPanelType } from '../ChurchReportModalPanel/ChurchReportModalPanel';
import './ChurchReportModalComment.scss';
import CommentBox from './CommentBox/CommentBox';

export default function ChurchReportModalComment({ church, type }: { church: Church; type: ReportPanelType; }): ReactElement {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>();
  const commentIconClassName: string = classNames('church-report-modal-comment-icon', church?.reportComments?.[type]?.length > 0 ? 'church-report-modal-comment-icon-fill' : 'church-report-modal-comment-icon-not-fill');

  return (
    <Popover
        content={<CommentBox church={church} type={type} />}
        isOpen={isPopoverOpen}
        positions={['bottom']}
        align="end"
        containerClassName="church-report-modal-comment-container"
      >
        <div 
          className={commentIconClassName}
          onClick={(): void => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        >
        </div>
    </Popover>
  );
}
