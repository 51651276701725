import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Table.scss';

export interface Props {
  label: string;
  width: number;
  align?: 'right' | 'left' | 'center';
  children?: ReactNode;
  icon?: ReactNode;
  isHead?: boolean;
  isClickable?: boolean;
  onClick?: () => void;
}

export default function TableCell({ label, width, children, isHead, align, icon, isClickable, onClick }: Props): ReactElement {
  return (
    <div
      className={classNames(
        'table-cell',
        {
          'table-cell-align-right': align === 'right',
          'table-cell-align-left': align === 'left',
          'table-cell-align-center': align === 'center',
          'is-clickable': isClickable
        }
      )}
      style={{ width: `${width}%` }}
      onClick={() => isClickable && onClick()}
    >
      <div className="table-cell-title">
        {
          (isHead)
            ? label
            : [
              <span key="mobile-label" className="table-mobile-label">{label}</span>,
              <span key="mobile-content" className="table-mobile-content">{children}</span>
            ]
        }
        {icon}
      </div>
    </div>
  );
}
