import { Expose, Type } from 'class-transformer';
import { ChurchSurveyFormReportQuestion } from '../report/question';

export class ChurchSurveyFormReportData {
  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose()
  public questions!: Array<ChurchSurveyFormReportQuestion>;

  constructor(data: Partial<ChurchSurveyFormReportData> = {}) {
    Object.assign(this, data);
  }
}
