import React, { ReactElement, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import LoginPage from '../../pages/Login/Login';
import { useSupabaseUser } from '../../useSupabaseUser';

interface Props extends RouteProps {
  component: React.ComponentType<any>;
  title: string;
}

function PrivateRoute(props: Props): ReactElement {
  const { component: PageComponent, title } = props;
  const user = useSupabaseUser();

  useEffect(() => {
    document.title = title;
  }, []);

  if (!user) {
    return <LoginPage />;
  }

  return <PageComponent {...props} />;
}

export default PrivateRoute;
