import { Text as ReactPDFText } from '@react-pdf/renderer';
import { omit } from 'lodash';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { ElementUseFor, ISVG } from '../Svg';

export interface ITextProperties extends ISVG<any> {
  insideSVG?: boolean;
  render?(param: {
    pageNumber: number;
    totalPages: number;
    subPageNumber: number;
    subPageTotalPages: number;
  }): ReactNode;
}

export default function Text(props: ITextProperties): ReactElement {
  const { useFor, insideSVG = true, ...restProperties } = props;
  const textBrowserProperties = omit(restProperties, 'render');
  const defaultStyles: CSSProperties = {
    display: 'block',
    ...props.style
  };
  const browserView: ReactElement = insideSVG ? <text {...textBrowserProperties} style={defaultStyles} /> : <span {...textBrowserProperties} style={defaultStyles} />;

  return useFor === ElementUseFor.PDF ? <ReactPDFText {...restProperties} /> : browserView;
}
