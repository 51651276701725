import { Circle as ReactPDFCircle } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ElementUseFor, ISVG } from '../Svg';

export interface ISVGCircleProperties extends ISVG<any> {
  cx: number;
  cy: number;
  r: number;
  strokeDasharray?: string;
  fillRule?: any;
  textAnchor?: any;
  visibility?: any;
  dominantBaseline?: any;
}

export default function Circle(props: ISVGCircleProperties): ReactElement {
  const { useFor, ...restProperties } = props;

  return useFor === ElementUseFor.PDF ? <ReactPDFCircle {...restProperties} /> : <circle {...restProperties} />;
}
