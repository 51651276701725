import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement, ReactNode } from 'react';
import { Angle } from '../../models/angle';
import { ChartData } from '../../models/report/chart-data';
import Legends from './Legends';
import Circle from '../Svg/Elements/Circle';
import G from '../Svg/Elements/G';
import Path from '../Svg/Elements/Path';
import Text from '../Svg/Elements/Text';
import View from '../Svg/Elements/View';
import Svg, { ElementUseFor } from '../Svg/Svg';

export default function PieChart({ values, useFor }:{
  values: Array<ChartData>;
  useFor: ElementUseFor
}): ReactElement {
  const RADIUS = 50;
  const SHIFT_DEGREES_TO_START_POINT = -90;

  const styles = StyleSheet.create({
    pie: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    legends: {marginTop: 20},
    label: {
      fontSize: 12,
      textAnchor: 'middle',
      dominantBaseline: 'middle'
    }
  });

  function getPointByAngle(angle: number, radius: number): [number, number] {
    const x = radius + (Math.cos(angle) * radius);
    const y = radius - (Math.sin(angle) * radius);

    return [x, y];
  }

  function getValues(): Array<{ angle: Angle, color: string; value: number; startDeg: number }> {
    const sum = values.reduce((acc, item) => acc + item.value, 0);
    let shift = SHIFT_DEGREES_TO_START_POINT;
    const diagramValue = values.map((item) => {
      const newItem =  {
        ...item,
        angle: new Angle({ percent: item.value * 100 / sum }),
        startDeg: shift
      };
      shift = shift - newItem.angle.deg;
      
      return newItem;
    });

    return diagramValue;
  }

  function createSvgArc(radius: number, angle: number): string {
    const largeArc = angle <= Math.PI ? 0 : 1;
    const point = getPointByAngle(angle, radius);

    return `M ${radius} ${radius}
      L ${radius * 2} ${radius}
      A ${radius} ${radius} 0 ${largeArc} 0 ${point[0]} ${point[1]}
      Z`;
  }

  function getArc(item: { angle: Angle, color: string; value: number; startDeg: number }, isOne: boolean): ReactNode {
    const arcStyles = StyleSheet.create({
      path: {
        transform: `translate(${RADIUS}px, ${RADIUS}px) rotate(${item.startDeg}deg) translate(${-RADIUS}px, ${-RADIUS}px)`
      }
    });

    if (isOne) {
      return (
        <>
          <Circle cx={RADIUS} cy={RADIUS} r={RADIUS} fill={item.color} useFor={useFor}/>
          <Text x={RADIUS} y={RADIUS} fill="white" style={styles.label} useFor={useFor}>{item.value}</Text>
        </>
      );
    }

    function getMedianPoint (point1: [number, number], point2: [number, number]): [number, number] {
      return [point1[0] + ((point2[0] - point1[0]) / 2), point1[1] + ((point2[1] - point1[1]) / 2)];
    }

    const medianPoint = getMedianPoint([RADIUS, RADIUS], getPointByAngle((item.angle.rad / 2) - new Angle({deg: item.startDeg}).rad, RADIUS));

    return (
      <>
        <Path fill={item.color} d={createSvgArc(RADIUS, item.angle.rad)} style={useFor === ElementUseFor.BROWSER ? arcStyles.path : {}}
          transform={useFor === ElementUseFor.PDF ? `translate(${RADIUS}, ${RADIUS}) rotate(${item.startDeg}deg) translate(${-RADIUS}, ${-RADIUS})` : ''} useFor={useFor}/>
        <Text x={medianPoint[0]} y={medianPoint[1]} fill="white" style={styles.label} useFor={useFor}>
          {item.value}
        </Text>
      </>
    );
  }

  const diagramValue = getValues();

  return (
    <View wrap={false} style={styles.pie} useFor={useFor}>
      <Svg width={RADIUS * 2} height={RADIUS * 2} viewBox={`0 0 ${RADIUS * 2} ${RADIUS * 2}`} useFor={useFor}>
        {diagramValue.filter((item) => item.value > 0).map((item, _, arr) => <G key={item.color} useFor={useFor}>
          {getArc(item, arr.length === 1)}
        </G>)}
      </Svg>
      <View style={styles.legends} useFor={useFor}>
        <Legends legends={values} useFor={useFor}/>
      </View>
    </View>
  );
}
