import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import { Field, FormikProps, FormikValues } from 'formik';
import Row from '../../components/Grid/Row';
import * as Yup from 'yup';
import Col from '../../components/Grid/Col';
import FormGroup from '../../components/Form/FormGroup';
import FormButtons from '../../components/Form/FormButtons';
import FormServerError from '../../components/Form/FormServerError';
import Form from '../../components/Form/Form';
import './AddTeamMemberModal.scss';
import { SelectItem } from '../../models/SelectItem';
import { capitalize, find } from 'lodash';
import { ChurchDistrict } from '../../models/church/ChurchDistrict';
import { UserType } from '../../enums/UserType';
import { User } from '../../models/user/User';
import { UserRole } from '../../enums/UserRole';
import { plainToClass } from 'class-transformer';
import Animation from '../../components/Animation/Animation';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import FormCheckboxGroup from '../../components/Form/FormCheckboxGroup';
import supabase from '../../supabaseClient';
import { callSupabaseEdgeFunction } from '../../helpers/SupabaseFunctions';
import { AppContext } from '../../AppContextProvider';

interface Props {
  open?: boolean;
  toggleClose: () => void;
}

export default function AddTeamMemberModal({ open, toggleClose }: Props): ReactElement {
  const t = useTranslationByKey('CONTAINERS.ADD_TEAM_MEMBER_MODAL');

  const { state } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<Error | null>(null);
  const [districts, setDistricts] = useState<Array<SelectItem<ChurchDistrict>>>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [prefillUser, setPrefillUser] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({});

  const handleUserChange = (newUser: any) => {
    setSelectedUser(newUser);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('ERRORS.TEXT_FIRST_NAME_IS_REQUIRED')),
    lastName: Yup.string().required(t('ERRORS.TEXT_LAST_NAME_IS_REQUIRED')),
    email: Yup.string()
      .required(t('ERRORS.TEXT_EMAIL_IS_REQUIRED'))
      .email(t('ERRORS.TEXT_EMAIL_IS_INVALID')),
    type: Yup.string().required(t('ERRORS.TEXT_TYPE_IS_REQUIRED')),
    district: Yup.mixed().when(['user', 'type', 'deleteUser'], {
      is: (user, type, deleteUser) =>
        (user && type === UserType.DISTRICT && !deleteUser) ||
        (!user && type === UserType.DISTRICT && !deleteUser),
      then: Yup.object().required(t('ERRORS.TEXT_DISTRICT_IS_REQUIRED')),
      otherwise: Yup.mixed().nullable()
    })
  });

  useEffect(() => {
    loadDistricts();
    loadUsers();
    setSelectedUser(null);
    setPrefillUser(null);
    const defaultInitialValues = {
      user: '',
      deleteUser: false,
      firstName: '',
      lastName: '',
      email: '',
      districtId: -1,
      role: state.userClaims?.isAdmin
        ? UserRole.ADMIN
        : state.userClaims?.isFullAccessUser
        ? UserRole.FULL
        : UserRole.USER,
      type: state.userClaims?.type === UserType.DISTRICT ? UserType.DISTRICT : '',
      district:
        state.userClaims?.type === UserType.DISTRICT
          ? find(districts, (district) => district.value.id === state.userClaims.districtId)
              ?.value || ''
          : ''
    };

    setInitialValues(defaultInitialValues);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setPrefillUser(users.find((u) => u.converted.email === selectedUser));
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser && prefillUser) {
      const newInitialValues = {
        user: selectedUser,
        firstName: prefillUser.converted.firstName,
        lastName: prefillUser.converted.lastName,
        email: prefillUser.converted.email,
        role: prefillUser.converted.role,
        type: prefillUser.converted.type,
        district: prefillUser.converted.districts,
        deleteUser: false
      };
      setInitialValues(newInitialValues);
    }
  }, [prefillUser]);

  const loadDistricts = async () => {
    try {
      const { data: districtsData, error } = await supabase.from('districts').select('*');

      if (error) throw error;

      const districts = districtsData.map(
        (district) => new ChurchDistrict({ name: district.name, id: district.id })
      );

      const districtsSelectItems = districts.map(
        (district) => new SelectItem<ChurchDistrict>('district', district.name, district)
      );

      setDistricts(districtsSelectItems);
    } catch (error) {
      console.error('Error loading districts:', error);
    }
  };

  const loadUsers = async () => {
    let query = supabase
      .from('users')
      .select(
        `
      *,
      districts(name)
    `
      )
      .order('last_name', { ascending: true });

    if (state.userClaims?.type === UserType?.DISTRICT) {
      query = query.eq('district_id', state.userClaims.districtId);
    }

    const { data: users, error } = await query;
    if (error) {
      console.error('Error fetching users:', error);
      return;
    }
    const usersToList = users.map((user) => ({
      converted: plainToClass(User, user),
      unconverted: user
    }));
    setUsers(usersToList);
  };

  const getTypes = (): Array<SelectItem> =>
    Object.values(UserType).map((item) => new SelectItem('type', capitalize(item), item));

  const getUsers = (): Array<SelectItem> =>
    users.map(
      (user) =>
        new SelectItem(
          'user',
          `${user.converted.lastName}, ${user.converted.firstName}`,
          user.converted.email
        )
    );

  const getRoles = (): Array<SelectItem> =>
    Object.values(UserRole).map((item) => new SelectItem('role', capitalize(item), item));

  const closeModal = () => {
    setSelectedUser(null);
    setPrefillUser(null);
    const defaultInitialValues = {
      user: '',
      deleteUser: false,
      firstName: '',
      lastName: '',
      email: '',
      districtId: -1,
      role: state.userClaims?.isAdmin
        ? UserRole.ADMIN
        : state.userClaims?.isFullAccessUser
        ? UserRole.FULL
        : UserRole.USER,
      type: state.userClaims?.type === UserType.DISTRICT ? UserType.DISTRICT : '',
      district:
        state.userClaims?.type === UserType.DISTRICT
          ? find(districts, (district) => district.value.id === state.userClaims.districtId)
              ?.value || ''
          : ''
    };
    setInitialValues(defaultInitialValues);
    toggleClose();
  };

  // TODO - future: This method can be greatly improved, however a lot more changes will be required
  const onSubmit = async (values: FormikValues) => {
    setIsSubmitted(true);

    const districtId =
      values.type === UserType.DISTRICT
        ? parseInt(
            districts
              .find((districtItem) => districtItem.value.name === values.district?.name)
              ?.value.id.toString() || '0'
          )
        : null;

    const { deleteUser, ...userValues } = values;
    const data: User = {
      ...userValues,
      createdAt: null,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      districtId: districtId,
      role:
        values.role === UserRole.ADMIN
          ? UserRole.ADMIN
          : values.role === UserRole.USER
          ? UserRole.USER
          : UserRole.FULL,
      id: undefined,
      type: values.type === UserType.DISTRICT ? UserType.DISTRICT : UserType.NATIONAL,
      fullName: ''
    };

    delete values.user;
    delete values.deleteUser;

    const convertedData = transformUserToPlain(data);
    convertedData.district_id = districtId;

    try {
      if (selectedUser && prefillUser) {
        // Update or delete existing user logic
        if (deleteUser) {
          const { error: deleteError } = await supabase
            .from('users')
            .delete()
            .match({ email: selectedUser });

          await deleteUserAuthRecord(selectedUser);

          if (deleteError) {
            throw deleteError;
          }
        } else {
          const { error: updateError } = await supabase
            .from('users')
            .update(convertedData)
            .eq('email', selectedUser);

          if (updateError) {
            console.log('update error:', updateError);
            throw updateError;
          }
        }
      } else {
        // Check for existing user and create new if not found
        const { data: existingUsers } = await supabase
          .from('users')
          .select('*')
          .eq('email', data.email);

        delete data.id;

        if (existingUsers.length > 0) {
          throw { message: t('TEXT_USER_ALREADY_EXISTS') };
        }

        try {
          await callSupabaseEdgeFunction('create-user', 'POST', { data });
        } catch (error) {
          console.error('Error calling create-user:', error);
        }

        const { error: createError } = await supabase.from('users').insert([convertedData]);

        if (createError) {
          console.log('create error:', createError);
          throw createError;
        }
      }
      closeModal();
    } catch (error) {
      setErrorResponse(error);
    } finally {
      setIsSubmitted(false);
    }
  };

  async function deleteUserAuthRecord(userEmail: string): Promise<boolean> {
    try {
      await callSupabaseEdgeFunction('on-users-delete', 'POST', { userEmail });

      console.log('User successfully deleted');
      return true;
    } catch (error) {
      console.error('Error deleting user auth record:', error);
      return false;
    }
  }

  // For some reason, the function classToPlain doesn't work
  // TODO - future: Fix it and remove this function at some point in the future
  function transformUserToPlain(user: User): any {
    return {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      role: user.role,
      type: user.type,
      district_id: user.districtId
    };
  }
  return (
    <Modal open={open} toggleClose={closeModal} title={t('TEXT_ADD_TEAM_MEMBER')}>
      <Form
        initialValues={initialValues}
        // onSubmit={onSubmit}

        onSubmit={(values, { setSubmitting }) => {
          validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
              console.log('Validation passed');
              onSubmit(values);
            })
            .catch((error) => {
              console.log('Validation failed');
              console.log(error);
              setSubmitting(false);
            });
        }}
        updateErrorResponse={setErrorResponse}
        validationSchema={validationSchema}
        className="add-team-member-modal-content"
      >
        {(props: FormikProps<FormikValues>): ReactElement => (
          <>
            <Row>
              <Col size={50}>
                <Field
                  name="user"
                  component={FormGroup}
                  type="select"
                  label={t('TEXT_USER')}
                  options={getUsers()}
                  placeholder={t('TEXT_SELECT_USER')}
                  error={props.errors.user}
                  touched={props.touched.user}
                  value={props.values.user}
                  onChange={handleUserChange}
                />
              </Col>
              <Col size={50}>
                <Animation type="fade-in-down" animate={selectedUser && prefillUser}>
                  <Field
                    name="deleteUser"
                    component={FormCheckboxGroup}
                    onChange={props.handleChange}
                    type="checkbox"
                    error={props.errors.deleteUser}
                    touched={props.touched.deleteUser}
                    value={props.values.deleteUser}
                    checked={!!props.values.deleteUser}
                  >
                    {t('TEXT_DELETE_USER')}
                  </Field>
                </Animation>
              </Col>
            </Row>
            {!props.values.deleteUser && (
              <>
                <Row>
                  {state.userClaims?.type !== UserType.DISTRICT && (
                    <Col size={50}>
                      <Field
                        name="type"
                        component={FormGroup}
                        type="select"
                        label={t('TEXT_TYPE')}
                        options={getTypes()}
                        placeholder={t('TEXT_SELECT_TYPE')}
                        error={props.errors.type}
                        touched={props.touched.type}
                        value={props.values.type}
                      />
                    </Col>
                  )}
                  <Col size={50}>
                    <Animation
                      type="fade-in-down"
                      animate={
                        state.userClaims?.type !== UserType.DISTRICT &&
                        props.values.type === UserType.DISTRICT
                      }
                    >
                      <Field
                        name="district"
                        component={FormGroup}
                        type="select"
                        label={t('TEXT_DISTRICT')}
                        options={districts}
                        loading={!districts.length}
                        placeholder={t('TEXT_SELECT_DISTRICT')}
                        error={props.errors.district}
                        touched={props.touched.district}
                        value={props.values.district}
                      />
                    </Animation>
                  </Col>
                </Row>
                <Row>
                  <Col size={50}>
                    <Field
                      name="firstName"
                      component={FormGroup}
                      onChange={props.handleChange}
                      type="text"
                      label={t('TEXT_FIRST_NAME')}
                      placeholder={t('TEXT_ENTER_FIRST_NAME')}
                      error={props.errors.firstName}
                      touched={props.touched.firstName}
                      value={props.values.firstName}
                    />
                  </Col>
                  <Col size={50}>
                    <Field
                      name="lastName"
                      component={FormGroup}
                      onChange={props.handleChange}
                      type="text"
                      label={t('TEXT_LAST_NAME')}
                      placeholder={t('TEXT_ENTER_LAST_NAME')}
                      error={props.errors.lastName}
                      touched={props.touched.lastName}
                      value={props.values.lastName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col size={50}>
                    <Field
                      name="email"
                      component={FormGroup}
                      onChange={props.handleChange}
                      type="text"
                      label={t('TEXT_EMAIL')}
                      placeholder={t('TEXT_ENTER_EMAIL')}
                      error={props.errors.email}
                      touched={props.touched.email}
                      value={props.values.email}
                      disabled={selectedUser !== null && prefillUser !== null}
                    />
                  </Col>
                  <Col size={50}>
                    <Field
                      name="role"
                      component={FormGroup}
                      type="select"
                      label={t('TEXT_ROLE')}
                      options={getRoles()}
                      placeholder={t('TEXT_SELECT_ROLE')}
                      error={props.errors.role}
                      touched={props.touched.role}
                      value={props.values.role}
                    />
                  </Col>
                </Row>
              </>
            )}
            {errorResponse && <FormServerError errorMessage={errorResponse.message} />}

            <FormButtons align="right">
              <Button
                color={props.values.deleteUser ? 'red' : 'green'}
                isLoading={isSubmitted}
                type="submit"
              >
                {props.values.deleteUser ? t('BUTTON_DELETE') : t('BUTTON_SUBMIT')}
              </Button>
            </FormButtons>
          </>
        )}
      </Form>
    </Modal>
  );
}
