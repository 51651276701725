import { Expose } from 'class-transformer';
import { ChurchFormResult } from './ChurchFormResult';

export class ChurchSurveyFormResult extends ChurchFormResult {
  @Expose({ name: 'completed_surveys' })
  public completedSurveys: number;

  @Expose({ name: 'percentage_of_completed_surveys' })
  public percentageOfCompletedSurveys!: number;

  @Expose({ name: 'is_survey_completed' })
  public isSurveyCompleted!: boolean;

  constructor(data: Partial<ChurchSurveyFormResult> = {}) {
    super(data);
    Object.assign(this, data);
  }
}
