import { Expose, Type } from 'class-transformer';
import { ChurchForm } from './ChurchForm';
import { ChurchInquiryFormResult } from './ChurchInquiryFormResult';

export class ChurchInquiryForm extends ChurchForm {
  @Type(() => ChurchInquiryFormResult)
  @Expose()
  public result: ChurchInquiryFormResult;

  constructor(data: Partial<ChurchInquiryForm> = {}) {
    super(data);
    Object.assign(this, data);
  }
}
