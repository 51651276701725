import React, { ReactElement, ReactNode } from 'react';
import './ElementBoxHolder.scss';

export default function ElementBoxHolder({ children }: { children: ReactNode }): ReactElement {
  return (
    <div className="element-box-holder">
      <div className="element-box">
        {children}
      </div>
    </div>
  );
}
