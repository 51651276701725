import { ChartArrayData } from '../../../models/report/chart-array-data';
import { Element } from '../../../models/report/element';
import { ElementData } from '../../../models/report/element-data';

function mapElementReportData({ element }: { element: Element }): Array<number> {
  return element.children.filter((child: Element): number => child.value).map((child: Element): number => {
    const { value } = child;
    
    return Number(value.toFixed(1));
  });
}

function mapElementReportLabel({ element }: { element: Element }): Array<string> {
  return element.children.filter((child: Element): number => child.value).map((child: Element): string => child.label);
}

export function mapReportDataForBarChart({ data }: { data: { leader: Element; congregation: Element; }; }): ElementData {
  const { leader, congregation } = data;
  const leadershipData: ChartArrayData = {
    values: leader ? mapElementReportData({ element: leader }) : [],
    label: 'Leadership',
    color: '#7e8246'
  };
  const congregationData: ChartArrayData = {
    values: congregation ? mapElementReportData({ element: congregation }) : [],
    label: 'Non-Leadership',
    color: '#415159'
  };
  const labels: Array<string> = leader ? mapElementReportLabel({ element: leader }) : [];

  return {
    title: leader && leader.label,
    description: '',
    values: [leadershipData, congregationData],
    labels
  };
}

export function mapReportDataForSingleBarChart({ data, label, color }: { data: Element; color: string; label: string; }): ElementData {
  const chartData: ChartArrayData = {
    values: mapElementReportData({ element: data }),
    label,
    color
  };
  const labels: Array<string> = mapElementReportLabel({ element: data });

  return {
    title: data.label,
    description: '',
    values: [chartData],
    labels
  };
}
