import { Expose, Type } from 'class-transformer';
import { ChurchSurveyFormReportData } from './ChurchSurveyFormReportData';

export class ChurchSurveyFormReport {
  @Type(() => ChurchSurveyFormReportData)
  @Expose({ name: 'congregation_raw_report' })
  public congregationRawReport!: ChurchSurveyFormReportData;

  @Type(() => ChurchSurveyFormReportData)
  @Expose({ name: 'leader_raw_report' })
  public leaderRawReport!: ChurchSurveyFormReportData;

  constructor(data: Partial<ChurchSurveyFormReport> = {}) {
    Object.assign(this, data);
  }
}
