import * as React from 'react';
import { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import './Form.scss';

interface Props {
  children?: ReactNode;
}

export default function FormCheckbox(props: Props & InputHTMLAttributes<HTMLInputElement>): ReactElement {
  const { children, ...inputProps } = props;

  return (
      <label className="form-checkbox">
        <input {...inputProps} type="checkbox" />
        <span className="form-checkbox-icon"/>
        {!!children && <span className="form-checkbox-title">{children}</span>}
      </label>
  );
}
