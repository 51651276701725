import React, { ReactElement, ReactNode } from 'react';
import './Form.scss';

interface Props {
  children: ReactNode;
}

export default function FormError(props: Props): ReactElement {
  return <div className="form-error">{props.children}</div>;
}
