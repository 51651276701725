import React, { ReactElement, ReactNode } from 'react';
import './Container.scss';

export interface Props {
  children: ReactNode;
}

export default function Container({ children }: Props): ReactElement {
  return <div className="container">{children}</div>;
}
