import { Expose } from 'class-transformer';
import 'reflect-metadata';

export class Progress {
  @Expose()
  public church: string;

  @Expose()
  public value: number;

  @Expose()
  public max: number;

  @Expose()
  public error_message?: string;

  @Expose()
  public auto_increment?: number;
}
