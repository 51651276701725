import React, { ReactElement, ReactNode } from 'react';
import './Form.scss';

interface Props {
  children: ReactNode;
}

export default function FormLabel(props: Props): ReactElement {
  return <label className="form-label">{props.children}</label>;
}
