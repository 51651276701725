import { Expose } from 'class-transformer';

export class Tag {
  @Expose()
  public name: string;

  constructor(model: Partial<Tag> = {}) {
    Object.assign(this, model);
  }
}
