import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { useHistory } from 'react-router-dom';
import supabase from '../../supabaseClient';
import FormServerError from '../../components/Form/FormServerError';
import Button from '../../components/Button/Button';
import FormGroup from '../../components/Form/FormGroup';
import Icon from '../../components/Icon/Icon';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const PasswordResetConfirm: React.FC = (): ReactElement => {
  const t = useTranslationByKey('CONTAINERS.PASSWORD_RESET_CONFIRM');
  const [errorResponse, setErrorResponse] = useState<Error | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();

  const initialValues: FormValues = {
    password: '',
    confirmPassword: ''
  };

  const validationSchema = Yup.object({
    password: Yup.string().required(t('VALIDATION_PASSWORD_REQUIRED')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('VALIDATION_PASSWORDS_MUST_MATCH'))
      .required(t('VALIDATION_CONFIRM_PASSWORD_REQUIRED'))
  });

  const onSubmit = async (values: FormValues) => {
    setErrorResponse(null);
    setIsSubmitting(true);

    try {
      const { data, error } = await supabase.auth.updateUser({
        password: values.password
      });

      if (error) {
        setErrorResponse(new Error('There was an error updating your password: ' + error.message));
      } else if (data) {
        alert('Password updated successfully!');
        history.push('/dashboard');
      }
    } catch (error) {
      setErrorResponse(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props: FormikProps<FormValues>): ReactElement => {
        const { values } = props;

        useEffect(() => {
          setErrorResponse(null);
        }, [values]);

        return (
          <Form className="form reset-password-form">
            <h1>{t('TEXT_RESET_YOUR_PASSWORD')}</h1>
            <Field
              name="password"
              component={FormGroup}
              icon={<Icon variant="lock" />}
              onChange={props.handleChange}
              type="password"
              placeholder={t('TEXT_NEW_PASSWORD')}
              error={props.errors.password}
              touched={props.touched.password}
              value={props.values.password}
            />
            <Field
              name="confirmPassword"
              component={FormGroup}
              icon={<Icon variant="lock" />}
              onChange={props.handleChange}
              type="password"
              placeholder={t('TEXT_CONFIRM_NEW_PASSWORD')}
              error={props.errors.confirmPassword}
              touched={props.touched.confirmPassword}
              value={props.values.confirmPassword}
            />
            {errorResponse && <FormServerError errorMessage={errorResponse.message} />}
            <div className="form-buttons">
              <Button color="blue-gray" isBlock={true} isLoading={isSubmitting} type="submit">
                {t('BUTTON_RESET_PASSWORD')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordResetConfirm;
