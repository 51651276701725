import React, { ReactElement, ReactNode, useMemo, useReducer } from 'react';
import { ActionMap } from './helpers/ActionMap';
import { Survey } from './models/survey/Survey';
import { UserClaims } from './models/user/UserClaims';
import { ContextReducer } from './types/ContextReducer';
import { Church } from './models/church/Church';

export enum AppActionTypes {
  SetUserClaims = 'SET_USER_CLAIMS',
  OpenEditChurchModal = 'OPEN_EDIT_CHURCH_MODAL',
  CloseEditChurchModal = 'CLOSE_EDIT_CHURCH_MODAL',
  OpenChurchReportModal = 'OPEN_CHURCH_REPORT_MODAL',
  CloseChurchReportModal = 'CLOSE_CHURCH_REPORT_MODAL',
  OpenReportCommentsModal = 'OPEN_REPORT_COMMENTS_MODAL',
  CloseReportCommentsModal = 'CLOSE_REPORT_COMMENTS_MODAL',
  OpenChurchCloseSurveyModal = 'OPEN_CHURCH_SURVEY_CLOSE_MODAL',
  CloseChurchCloseSurveyModal = 'CLOSE_CHURCH_SURVEY_CLOSE_MODAL',
  OpenProgressBarModal = 'OPEN_PROGRESS_BAR_MODAL',
  CloseProgressBarModal = 'CLOSE_PROGRESS_BAR_MODAL',
  OpenSurveyViewModal = 'OPEN_SURVEY_VIEW_MODAL',
  CloseSurveyViewModal = 'CLOSE_SURVEY_VIEW_MODAL',
  OpenDeleteChurchModal = 'OPEN_DELETE_CHURCH_MODAL',
  CloseDeleteChurchModal = 'CLOSE_DELETE_CHURCH_MODAL',
  ReRunChurchReport = 'RE_RUN_CHURCH_REPORT',
  ResetChurchState = 'RESET_CHURCH_STATE'
}

type AppState = {
  userClaims: UserClaims;
  selectedChurch: Church;
  churchToEdit: Church;
  churchToDelete: Church;
  churchToRunReport: Church;
  surveyToView: Survey;
  isEditChurchModalOpened: boolean;
  isChurchReportModalOpened: boolean;
  isReportCommentsModalOpened: boolean;
  isChurchCloseSurveyModalOpened: boolean;
  isProgressBarModalOpened: boolean;
  isSurveyViewModalOpened: boolean;
  isDeleteChurchModalOpened: boolean;
  isReRunningChurchReport: boolean;
  resetChurchState: number;
};

type AppPayload = {
  [AppActionTypes.SetUserClaims]: {
    userClaims: UserClaims;
  };
  [AppActionTypes.OpenEditChurchModal]: {
    church?: Church;
  };
  [AppActionTypes.CloseEditChurchModal]: null;
  [AppActionTypes.OpenSurveyViewModal]: {
    survey: Survey;
  };
  [AppActionTypes.OpenProgressBarModal]: null;
  [AppActionTypes.OpenChurchReportModal]: {
    church?: Church;
  };
  [AppActionTypes.OpenReportCommentsModal]: {
    church?: Church;
  };
  [AppActionTypes.OpenChurchCloseSurveyModal]: {
    church?: Church;
  };
  [AppActionTypes.CloseChurchReportModal]: null;
  [AppActionTypes.CloseReportCommentsModal]: null;
  [AppActionTypes.CloseChurchCloseSurveyModal]: null;
  [AppActionTypes.CloseSurveyViewModal]: null;
  [AppActionTypes.CloseProgressBarModal]: null;
  [AppActionTypes.OpenDeleteChurchModal]: {
    church?: Church;
  };
  [AppActionTypes.CloseDeleteChurchModal]: null;
  [AppActionTypes.ReRunChurchReport]: {
    church?: Church;
  };
};

type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

type Props = {
  children: ReactNode;
};

const initialState: AppState = {
  userClaims: null,
  selectedChurch: null,
  churchToEdit: null,
  churchToDelete: null,
  surveyToView: null,
  churchToRunReport: null,
  isEditChurchModalOpened: false,
  isChurchReportModalOpened: false,
  isReportCommentsModalOpened: false,
  isChurchCloseSurveyModalOpened: false,
  isProgressBarModalOpened: false,
  isSurveyViewModalOpened: false,
  isDeleteChurchModalOpened: false,
  isReRunningChurchReport: false,
  resetChurchState: 0
};

export const AppContext = React.createContext<ContextReducer<AppState, AppActions>>(({
  state: initialState,
  dispatch: () => null
}));

export function AppContextProvider({ children }: Props): ReactElement {
  const [state, dispatch] = useReducer((state: AppState, action: AppActions) => {
    switch (action.type) {
      case AppActionTypes.SetUserClaims:
        return { ...state, userClaims: action.payload.userClaims };
      case AppActionTypes.OpenEditChurchModal:
        return {
          ...state,
          isEditChurchModalOpened: true,
          churchToEdit: action.payload?.church
        };
      case AppActionTypes.CloseEditChurchModal:
        return {
          ...state,
          isEditChurchModalOpened: false,
          churchToEdit: null
        };
      case AppActionTypes.OpenChurchReportModal:
        return {
          ...state,
          isChurchReportModalOpened: true,
          selectedChurch: action.payload?.church
        };
      case AppActionTypes.CloseChurchReportModal:
        return {
          ...state,
          isChurchReportModalOpened: false
        };
        case AppActionTypes.OpenReportCommentsModal:
          return {
            ...state,
            isReportCommentsModalOpened: true,
            selectedChurch: action.payload?.church
          };
          case AppActionTypes.CloseReportCommentsModal:
            return {
              ...state,
              isReportCommentsModalOpened: false
            };
      case AppActionTypes.OpenChurchCloseSurveyModal:
        return {
          ...state,
          isChurchCloseSurveyModalOpened: true,
          selectedChurch: action.payload?.church
        };
      case AppActionTypes.CloseChurchCloseSurveyModal:
        return {
          ...state,
          isChurchCloseSurveyModalOpened: false
        };
        case AppActionTypes.OpenProgressBarModal:
          return {
            ...state,
            isProgressBarModalOpened: true
          };
        case AppActionTypes.CloseProgressBarModal:
          return {
            ...state,
            isProgressBarModalOpened: false
          };
      case AppActionTypes.OpenSurveyViewModal:
        return {
          ...state,
          isSurveyViewModalOpened: true,
          surveyToView: action.payload?.survey
        };
      case AppActionTypes.CloseSurveyViewModal:
        return {
          ...state,
          isSurveyViewModalOpened: false,
          surveyToView: null
        };
      case AppActionTypes.OpenDeleteChurchModal:
        return {
          ...state,
          isDeleteChurchModalOpened: true,
          churchToDelete: action.payload?.church
        };
      case AppActionTypes.CloseDeleteChurchModal:
        return {
          ...state,
          isDeleteChurchModalOpened: false
        };
      case AppActionTypes.ReRunChurchReport:
        return {
          ...state,
          isReRunningChurchReport: !state.isReRunningChurchReport,
          churchToRunReport: action.payload?.church
        };
      default:
        return state;
    }
  }, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}
