import supabase from '../supabaseClient';


export const updateChurchTags = async (
    churchName: string,
    churchId: number | null,
    tagsToInsert: Array<{ id: number; name: string }>
): Promise<void> => {
    if (churchId === null) {
        // Example: Fetching the church ID. Adjust according to your actual data structure.
        const { data: churchData } = await supabase
            .from('churches')
            .select('id')
            .eq('name', churchName)
            .single();

        churchId = churchData?.id;
        if (!churchId) {
            console.error('No church ID found');
            return;
        }
    }

    const selectedTagIds = tagsToInsert.map((tag) => tag.id);

    // Step 1: Fetch current associations between tags and this church
    const { data: currentTagAssociations } = await supabase
        .from('tag_to_church')
        .select('tag_id')
        .eq('church_id', churchId);

    const currentTagIds = currentTagAssociations
        ? currentTagAssociations.map((item) => item.tag_id)
        : [];

    // Step 2: Determine tags to remove and to add
    const tagsToRemove = currentTagIds.filter((tagId) => !selectedTagIds.includes(tagId));
    const tagsToAdd = selectedTagIds.filter((tagId) => !currentTagIds.includes(tagId));

    // Step 3: Remove tags no longer associated
    await Promise.all(
        tagsToRemove.map((tagId) =>
            supabase.from('tag_to_church').delete().match({ church_id: churchId, tag_id: tagId })
        )
    );

    // Step 4: Add new tags
    await Promise.all(
        tagsToAdd.map((tagId) =>
            supabase.from('tag_to_church').insert([{ church_id: churchId, tag_id: tagId }])
        )
    );
};
