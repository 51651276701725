import React, { ReactElement } from 'react';
import AuthPageLayout from '../../components/PageLayout/AuthPageLayout';
import ResetPasswordForm from '../../containers/ResetPasswordForm/ResetPasswordForm';

export default function ResetPasswordPage(): ReactElement {
  return (
    <div className="reset-password">
      <AuthPageLayout>
        <ResetPasswordForm />
      </AuthPageLayout>
    </div>
  );
}
