import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { LegendAlign } from '../../enums/LegendAlign';
import Rect from '../Svg/Elements/Rect';
import Text from '../Svg/Elements/Text';
import View from '../Svg/Elements/View';
import Svg, { ElementUseFor } from '../Svg/Svg';

export default function Legends({ 
  legends, 
  useFor,
  align = LegendAlign.END
}: {
  legends: Array<{ color: string, label: string }>;
  useFor: ElementUseFor;
  align?: LegendAlign;
}): ReactElement {
  const styles = StyleSheet.create({
    legends: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 12,
      justifyContent: align === LegendAlign.END ? 'flex-end' : 'flex-start',
      marginTop: 10
    },
    legendsItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 10
    },
    rect: {
      marginRight: 10
    }
  });
  
  return (
    <View style={styles.legends} useFor={useFor}>
      {legends.map((item) => <View key={item.label} style={styles.legendsItem} useFor={useFor}>
        <Svg width="15" height="15" style={styles.rect} useFor={useFor}>
          <Rect x={0} y={0} height={15} width={15} fill={item.color} useFor={useFor}/>
        </Svg>
        <Text useFor={useFor} insideSVG={false}>
          {item.label}
        </Text>
      </View>)}
    </View>
  );
}
