import { Svg as ReactPDFSvg } from '@react-pdf/renderer';
import React, { ReactElement, ReactNode, SVGProps } from 'react';

export enum ElementUseFor {
  PDF,
  BROWSER
}

export interface ISVG<T> extends SVGProps<T> {
  useFor: ElementUseFor;
  children?: ReactNode;
  style?: any;
  className?: string;
}

export type ISvgProperties = ISVG<any>;

export default function Svg(props: ISvgProperties): ReactElement {
  const { useFor, ...restProperties } = props;

  return useFor === ElementUseFor.PDF ? <ReactPDFSvg {...restProperties} /> : <svg {...restProperties} />;
}
