import React, { ReactElement, ReactNode } from 'react';
import './Form.scss';
import classNames from 'classnames';

export interface Props {
  children: ReactNode;
  align?: 'left' | 'right' | 'center';
}

export default function FormButtons({ children, align }: Props): ReactElement {
  return (
    <div className={classNames(
      'form-buttons',
      `form-buttons-${align}`
    )}>
      {children}
    </div>
  );
}
