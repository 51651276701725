import React, { ReactElement, ReactNode } from 'react';
import './ChurchListDetailsItem.scss';

interface Props {
  title: string;
  children: ReactNode;
}

export default function ChurchListDetailsName({ title, children }: Props): ReactElement {
  return (
    <div className="church-details-item">
      <div className="church-details-item-title">{title}:</div>
      <b style={{fontSize: 20}}>{children}</b>
    </div>
  );
}
