import { DateTime } from 'luxon';

export default function YupDateParser(_: unknown, originalValue: Date | DateTime): Date {
  if (!originalValue) {
    return null;
  }

  return DateTime.isDateTime(originalValue)
    ? originalValue.toJSDate()
    : originalValue;
}
