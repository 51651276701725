import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ChartArrayData } from '../../models/report/chart-array-data';
import LineChart from '../Charts/LineChart';
import View from '../Svg/Elements/View';
import { ElementUseFor } from '../Svg/Svg';

export default function ReviewBox({ values, labels, useFor = ElementUseFor.PDF }: { values: Array<ChartArrayData>; labels: Array<string>; useFor?: ElementUseFor; }): ReactElement {
  const styles = StyleSheet.create({
    review: {}
  });

  return (
    <>
      <View style={styles.review} useFor={useFor}>
        <LineChart
          values={values}
          labels={labels}
          useFor={useFor}
        />
      </View>
    </>
  );
}
