import { Field } from 'formik';
import React, { ReactElement, useContext, useState } from 'react';
import Form from '../../../../../components/Form/Form';
import { Church } from '../../../../../models/church/Church';
import { ReportCommentData } from '../../../../../models/report/comment-data';
import {
  ChurchListActionTypes,
  ChurchListContext
} from '../../../../ChurchList/ChurchListContextProvider';
import { ReportPanelType } from '../../../ChurchReportModalPanel/ChurchReportModalPanel';
import CommentAction from '../CommentAction/CommentAction';
import './CommentEditForm.scss';
import supabase from '../../../../../supabaseClient';

export default function CommentEditForm({
  comment,
  church,
  type,
  onCancel
}: {
  comment: ReportCommentData;
  church: Church;
  type: ReportPanelType;
  onCancel: () => void;
}): ReactElement {
  const { dispatch } = useContext(ChurchListContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { body, author, createdAt, id } = comment;

  const onEditCommentSubmit = async (values): Promise<void> => {
    setIsEditing(true);

    const updatedComment: ReportCommentData = {
      body: values.body,
      author,
      id,
      createdAt,
      updatedAt: new Date().toISOString()
    };

    const comments = [...church.reportComments[type]];
    const commentIndex = comments.findIndex((comment) => comment.id === id);
    if (commentIndex !== -1) {
      comments[commentIndex] = updatedComment;
    }

    // Update the church's report comments in Supabase
    const { error } = await supabase
      .from('churches')
      .update({ report_comments: { ...church.reportComments, [type]: comments } })
      .eq('churchCode', church?.churchCode);

    if (error) throw error;

    // Optionally fetch the updated church data if needed
    // This step is optional and depends on how you manage state in your application
    const { data: updatedChurch, error: fetchError } = await supabase
      .from('churches')
      .select('*')
      .eq('id', church?.churchId)
      .single();

    if (fetchError) throw fetchError;

    dispatch({
      type: ChurchListActionTypes.UpdateSelectedCurch,
      payload: {
        church: updatedChurch
      }
    });

    setIsEditing(false);
    onCancel();
  };

  return (
    <Form
      initialValues={{
        body
      }}
      onSubmit={onEditCommentSubmit}
      className="comment-edit-form"
    >
      {(): ReactElement => (
        <>
          <div>
            <Field as="textarea" name="body" className="comment-edit-form-input" />
            <CommentAction
              isLoading={isEditing}
              actions={[
                {
                  className: 'comment-edit-form-submit',
                  type: 'submit'
                },
                {
                  className: 'comment-edit-form-cancel',
                  callback: onCancel
                }
              ]}
            />
          </div>
        </>
      )}
    </Form>
  );
}
