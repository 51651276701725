import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ChurchStatus } from '../../enums/ChurchStatus';
import { ChurchSurveyStatus } from '../../enums/ChurchSurveyStatus';
import { ReportComment } from '../report/comment';
import { Report } from '../report/report';
import { ChurchDistrict } from './ChurchDistrict';
import { ChurchInquiryForm } from './ChurchInquiryForm';
import { ChurchIntakeForm } from './ChurchIntakeForm';
import { ChurchSurveyForm } from './ChurchSurveyForm';
import { ChurchSurveyFormReport } from './ChurchSurveyFormReport';
import { ChurchTag } from './ChurchTag';
import { DateTime } from 'luxon';
import DateTransformer from '../../helpers/DateTransformer';

export class Church {
  @Expose({ name: 'church_id' })
  public churchId: number;

  @Expose({ name: 'inquiry_form_date_created' })
  @Transform(DateTransformer)
  public inquiryFormDateCreated: DateTime;

  @Expose({ name: 'intake_form_date_created' })
  @Transform(DateTransformer)
  public intakeFormDateCreated: DateTime;

  @Expose({ name: 'intake_form_is_reviewed' })
  public intakeFormIsReviewed: boolean;

  @Expose({ name: 'intake_form_is_sent' })
  public intakeFormIsSent: boolean;

  @Expose({ name: 'survey_form_open_date' })
  @Transform(DateTransformer)
  public surveyFormOpenDate: DateTime;

  @Expose({ name: 'survey_form_close_date' })
  @Transform(DateTransformer)
  public surveyFormCloseDate: DateTime;

  @Expose()
  public name: string;

  @Expose()
  public status: ChurchStatus;

  @Expose({ name: 'district_id' })
  public districtId: number;

  @Expose({ name: 'church_code' })
  public churchCode: string;

  @Expose({ name: 'address_city' })
  public addressCity: string;

  @Expose({ name: 'address_street' })
  public addressStreet: string;

  @Expose({ name: 'address_state' })
  public addressState: string;

  @Expose({ name: 'address_zip' })
  public addressZip: string;

  @Expose({ name: 'survey_form_status' })
  public get surveyFormStatus(): ChurchSurveyStatus {
    if (this.surveyForm?.surveyDate?.isFuture) {
      return ChurchSurveyStatus.PRE;
    }

    if (this.surveyForm?.surveyDate?.isNow) {
      return ChurchSurveyStatus.ACTIVE;
    }

    if (this.surveyForm?.surveyDate?.isExpired) {
      const isCompleted = this.status === ChurchStatus.COACHING;

      return (isCompleted) ? ChurchSurveyStatus.CLOSED : ChurchSurveyStatus.EXTENDED;
    }
  }

  @Expose({ name: 'is_coaching_completed' })
  public isCoachingCompleted: boolean;

  @Expose({ name: 'coach_meeting_date' })
  @Transform(DateTransformer)
  public coachMeetingDate: DateTime;

  @Expose({ name: 'coach_name' })
  public coachName: string;

  @Expose({ name: 'coach_user' })
  public coachUser: string;

  @Expose()
  @Type(() => ChurchDistrict)
  public district: ChurchDistrict;

  @Expose({ name: 'contact_data_phone' })
  public contactDataPhone: string;

  @Expose({ name: 'contact_data_email' })
  public contactDataEmail: string;

  @Expose({ name: 'contact_data_last_name' })
  public contactDataLastName: string;

  @Expose({ name: 'contact_data_first_name' })
  public contactDataFirstName: string;

  @Expose({ name: 'inquiry_form' })
  @Type(() => ChurchInquiryForm)
  public inquiryForm: ChurchInquiryForm;

  @Expose({ name: 'intake_form' })
  @Type(() => ChurchIntakeForm)
  public intakeForm: ChurchIntakeForm;

  @Expose({ name: 'survey_form' })
  @Type(() => ChurchSurveyForm)
  public surveyForm: ChurchSurveyForm;

  @Expose()
  @Type(() => ChurchTag)
  public tags: Array<ChurchTag>;

  @Type(() => Number)
  @Expose({ name: 'average_adult_attendance' })
  public averageAdultAttendance: number;

  @Expose({ name: 'report_reviewed' })
  public reportReviewed: boolean;

  @Exclude({ toPlainOnly: true })
  public get percentageOfCompletedSurveys(): number {
    return Math.round((((this.surveyForm?.result?.completedSurveys ?? 0) / this.averageAdultAttendance) * 100) || 0);
  }

  @Exclude({ toPlainOnly: true })
  public get isQuestionsDisabled(): boolean {
    return this.status === ChurchStatus.SURVEY || this.status === ChurchStatus.COACHING;
  }

  @Type(() => Boolean)
  @Expose({ name: 'is_automatically_created' })
  public isAutomaticallyCreated!: boolean;


  @Type(() => ChurchSurveyFormReport)
  @Expose({ name: 'raw_report' })
  public rawReport!: ChurchSurveyFormReport;

  @Type(() => Report)
  @Expose({ name: 'leader_report' })
  public leaderReport!: Report;

  @Type(() => Report)
  @Expose({ name: 'congregation_report' })
  public congregationReport!: Report;

  @Type(() => Report)
  @Expose({ name: 'combined_report' })
  public combinedReport!: Report;

  @Type(() => ReportComment)
  @Expose({ name: 'report_comments' })
  public reportComments!: ReportComment;

  constructor(model: Partial<Church> = {}) {
    Object.assign(this, model);
  }
}
