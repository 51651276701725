import React, { ReactElement } from 'react';
import Container from '../../components/Container/Container';
import MainPageLayout from '../../components/PageLayout/MainPageLayout';
import { ChurchList } from '../../containers/ChurchList/ChurchList';

export default function DashboardPage(): ReactElement {
  return (
    <div className="dashboard">
      <MainPageLayout>
        <Container>
          <ChurchList />
        </Container>
      </MainPageLayout>
    </div>
  );
}
