import { Element } from '../../../models/report/element';
import { KidsReport } from '../../../models/report/kids-report';
import { ISummaryData } from './mapSummaryReportData';

export function mapKidsReviewReportData({ children, youth }: KidsReport): ISummaryData {
  const labels: string[] = [];

  const childrenValues = children.children.map((child: Element): number => {
    const {label, value} = child;

    labels.push(label);

    return Number(value.toFixed(1));
  });

  const youthValues = youth.children.map((young: Element): number => Number(young.value.toFixed(1)));


  return {
    labels,
    values: [
      {
        values: childrenValues,
        color: '#E7BA20',
        label: 'Children'
      }, 
      {
        values: youthValues,
        color: '#7F1214',
        label: 'Youth'
      }
    ]
  };
}
