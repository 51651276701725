import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { useTranslationByKey } from '../../../hooks/use-translation-by-key';
import { DemographicsData } from '../../../models/report/demographics-data';
import BarChart from '../../Charts/BarChart';
import PieChart from '../../Charts/PieChart';
import View from '../../Svg/Elements/View';
import { ElementUseFor } from '../../Svg/Svg';
import ValueList from '../ValueList';
import DemographicCard from './DemographicCard/DemographicCard';
import './DemographicBox.scss';

export default function DemographicBox({ demographics, useFor = ElementUseFor.PDF }: {
  demographics: DemographicsData;
  useFor?: ElementUseFor
}): ReactElement {
  const t = useTranslationByKey('COMPONENTS.REPORT.DEMOGRAPHICS_BOX');

  const pdfStyles = StyleSheet.create({
    demographics: {
      marginLeft: -30,
      marginRight: -30
    },
    demographicsRow: {
      display: 'flex',
      flexDirection: 'row'
    },
    card: {
      width: '25%',
      flexGrow: 0,
      flexShrink: 0,
      padding: '15px',
      height: 235,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cardTitle: {
      color: '#222222',
      fontWeight: 500,
      fontSize: 16,
      height: 55,
      textAlign: 'center',
      width: 150
    },
    cardBody: {
      height: 190
    },
    borderBottom: 
    {borderBottom: '1px solid #979797'
  },
    borderRight: {
      borderRight: '1px solid #979797'
    }
  });

  const browserStyles = {
    ...pdfStyles,
    demographics: {
      ...pdfStyles.demographics,
      height: 'calc(100% - 91px)'
    },
    demographicsRow: {
      ...pdfStyles.demographicsRow
    },
    card: {
      ...pdfStyles.card,
      height: '100%',
      boxSizing: 'border-box'
    },
    cardBody: {
      ...pdfStyles.cardBody,
      height: 'auto',
      width: '100%',
      textAlign: 'center',
      top: 'calc(50% - 55px)',
      transform: 'translateY(-50%)'
    }
  };

  const styles = useFor === ElementUseFor.PDF ? pdfStyles : browserStyles;

  return (
    <>
      <View style={styles.demographics} useFor={useFor}>
        <View className="demographics-row" style={styles.demographicsRow} useFor={useFor}>
          <DemographicCard text={t('TEXT_GENDER')} useFor={useFor}>
            <PieChart values={demographics?.gender} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_AGE')} useFor={useFor}>
            <BarChart values={demographics.age} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_ORIGIN')} useFor={useFor}>
            <ValueList values={demographics.origin} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_FAMILY_SYSTEM')} useFor={useFor}>
            <ValueList values={demographics.familySystem} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_CHURCH_MEMBERSHIP')} useFor={useFor}>
            <PieChart values={demographics.isMember} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_YEARS_AS_CHRISTIAN')} useFor={useFor}>
            <BarChart values={demographics.yearsAsChristian} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_CHURCH_ATTENDANCE')} useFor={useFor}>
            <BarChart values={demographics.yearsAsAttend} useFor={useFor}/>
          </DemographicCard>
          <DemographicCard text={t('TEXT_CHURCH_COMMUTE')} useFor={useFor}>
            <BarChart values={demographics.commute} useFor={useFor}/>
          </DemographicCard>
        </View>
      </View>
    </>
  );
}
