import { StyleSheet } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { SchoolData } from '../../models/report/school-data';
import Text from '../Svg/Elements/Text';
import View from '../Svg/Elements/View';
import { ElementUseFor } from '../Svg/Svg';

export default function KidsInfoBox({ values, useFor = ElementUseFor.PDF }: {
  values: {
    congregation: SchoolData,
    leadership: SchoolData
  };
  useFor?: ElementUseFor
}): ReactElement {
  const t = useTranslationByKey('COMPONENTS.REPORT.PEAK_KIDS_INFO_PAGE');

  const styles = StyleSheet.create({
    table: {},
    row: {
      fontSize: useFor === ElementUseFor.PDF ? 12 : 16,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10
    },
    head: {
      fontSize: useFor === ElementUseFor.PDF ? 12 : 16,
      fontWeight: 500,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 20
    },
    label: {
      width: 300
    },
    cell: {
      width: 100,
      textAlign: 'center'
    }
  });

  return (
    <>
      <View style={styles.table} useFor={useFor}>
        <View style={styles.head} useFor={useFor}>
          <View style={styles.label} useFor={useFor}></View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {t('TEXT_CONGREGATION')}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {t('TEXT_LEADERS')}
            </Text>
          </View>
        </View>
        <View style={styles.row} useFor={useFor}>
          <View style={styles.label} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {t('TEXT_PRE_SCHOOL')}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.congregation.preSchool}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.leadership.preSchool}
            </Text>
          </View>
        </View>
        <View style={styles.row} useFor={useFor}>
          <View style={styles.label} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {t('TEXT_ELEMENTARY')}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.congregation.elementary}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.leadership.elementary}
            </Text>
          </View>
        </View>
        <View style={styles.row} useFor={useFor}>
          <View style={styles.label} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {t('TEXT_MIDDLE_SCHOOL')}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.congregation.middleSchool}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.leadership.middleSchool}
            </Text>
          </View>
        </View>
        <View style={styles.row} useFor={useFor}>
          <View style={styles.label} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {t('TEXT_HIGH_SCHOOL')}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.congregation.highSchool}
            </Text>
          </View>
          <View style={styles.cell} useFor={useFor}>
            <Text useFor={useFor} insideSVG={false}>
              {values.leadership.highSchool}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
}
