import classNames from 'classnames';
import React, { ReactElement } from 'react';
import './CompletedProgressBar.scss';

export interface Props {
  title: string;
  fillPercentage: number;
  limitOfSuccess: number;
}

export default function CompletedProgressBar({
  title,
  fillPercentage,
  limitOfSuccess
}: Props): ReactElement {
  return (
    <div className="completed-progress-bar">
      <span className="completed-progress-bar-title">{title}</span>
      <div className="completed-progress-bar-field">
        <div
          className={classNames('completed-progress-bar-slider', {
            'completed-progress-bar-slider-green': fillPercentage > limitOfSuccess
          })}
          style={{ width: `${fillPercentage || 0}%` }}
        ></div>
      </div>
    </div>
  );
}
