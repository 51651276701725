import React, { ReactElement } from 'react';
import { SurveyQuestion } from '../../models/survey/Question';
import './SurveyRow.scss';

export interface Props {
  questionNumber: string;
  question: SurveyQuestion;
}

export default function SurveyRow(props: Props): ReactElement {
  const { questionNumber, question } = props;

  return (
    <div className="survey-row">
      <h3 className="survey-row-title">{questionNumber}</h3>
      <div className="survey-row-question">{question.text.replace(/<br>/g, ' ')}</div>

      {question.subQuestions?.length
        ? question.subQuestions.map((subQuestion) => (
            <div className="survey-row-answer" key={questionNumber + subQuestion.text}>
              <span className="survey-row-label">{subQuestion.text}</span>
              {subQuestion.answers.map((answer) => (
                <span className="survey-row-text" key={questionNumber + subQuestion.text + answer}>
                  {answer}
                </span>
              ))}
            </div>
          ))
        : question.answers?.map((answer) => (
            <div className="survey-row-answer" key={questionNumber + answer}>
              <span className="survey-row-text">{answer}</span>
            </div>
          ))}
    </div>
  );
}
