import { classToPlain, plainToClass } from 'class-transformer';
import { Field, FormikProps, FormikValues, getIn } from 'formik';
import { isEmpty, isNull, omitBy } from 'lodash';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Animation from '../../components/Animation/Animation';
import Button from '../../components/Button/Button';
import Form from '../../components/Form/Form';
import FormButtons from '../../components/Form/FormButtons';
import FormCheckboxesGroups from '../../components/Form/FormCheckboxesGroups';
import FormGroup from '../../components/Form/FormGroup';
import FormServerError from '../../components/Form/FormServerError';
import Col from '../../components/Grid/Col';
import Row from '../../components/Grid/Row';
import Modal from '../../components/Modal/Modal';
import { ChurchStatus } from '../../enums/ChurchStatus';
import YupDateParser from '../../helpers/YupDateParser';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { Church } from '../../models/church/Church';
import { ChurchDistrict } from '../../models/church/ChurchDistrict';
import { ChurchSurveyForm } from '../../models/church/ChurchSurveyForm';
import { ChurchSurveyFormDates } from '../../models/church/ChurchSurveyFormDates';
import { ChurchTag } from '../../models/church/ChurchTag';
import { SelectItem } from '../../models/SelectItem';
import { SurveyMonkeyDistrict } from '../../models/SurveyMonkeyDistrict';
import { User } from '../../models/user/User';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchList/ChurchListContextProvider';
import './ChurchEditModal.scss';
import supabase from '../../supabaseClient';
import { DateTime } from 'luxon';
import { callSupabaseEdgeFunction } from '../../helpers/SupabaseFunctions';
import { updateChurchTags } from '../../helpers/ChurchTagsUpdater';

export default function ChurchEditModal(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_EDIT_MODAL');

  const { state, dispatch } = useContext(AppContext);
  const { dispatch: churchListDispatch } = useContext(ChurchListContext);

  const church = state.churchToEdit ? state.churchToEdit : null;

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<Error | null>(null);
  const [coaches, setCoaches] = useState<Array<SelectItem<User>>>([]);
  const [districts, setDistrictsInState] = useState<Array<ChurchDistrict>>([]);
  const [tags, setTagsInState] = useState<Array<ChurchTag>>([]);

  useEffect(() => {
    setDistricts();
    setTags();
  }, []);

  useEffect(() => {
    const district = getInitialDistrict();
    if (district) {
      if (typeof district === 'number') {
        setCoachesByDistrict(district as number);
      } else {
        setCoachesByDistrict(district.id as number);
      }
    }
  }, [church, districts]);

  const setDistricts = async (): Promise<void> => {
    const { data: districtsData, error } = await supabase
      .from('districts')
      .select('*') // Adjust if you need specific fields
      .order('name', { ascending: true });

    if (error) {
      throw error;
    }

    const districts = districtsData.map((district) => {
      // Adjust the SurveyMonkeyDistrict and ChurchDistrict models as necessary
      const surveyMonkeyDistrict = new SurveyMonkeyDistrict({
        name: district.name
        // Other properties as needed, based on your SurveyMonkeyDistrict class structure
      });
      return new ChurchDistrict({
        name: surveyMonkeyDistrict.name,
        id: district.id
      });
    });

    setDistrictsInState(districts);
  };

  const setTags = async (): Promise<void> => {
    const { data: tagsData, error } = await supabase
      .from('tags')
      .select('*')
      .order('name', { ascending: true });

    if (error) {
      throw error;
    }

    const tags = tagsData.map(
      (tag) => new ChurchTag({ id: tag.id, tagId: tag.id, name: tag.name })
    );

    setTagsInState(tags);
  };

  const getInitialDistrict = (): ChurchDistrict | number => {
    if (church?.district) {
      return church.districtId;
    } else if (state.userClaims?.isDistrictUser) {
      const district = districts.find((d) => d.id === state.userClaims.districtId);
      return district;
    } else {
      return -1;
    }
  };

  const cleanedChurch = Object.entries(church || {}).reduce((acc, [key, value]) => {
    acc[key] = value === undefined || value === null ? '' : value;
    return acc;
  }, {});

  const initialValues = new Church({
    ...new Church({
      name: '',
      status: ChurchStatus.INQUIRY,
      churchCode: '',
      tags: [],
      averageAdultAttendance: 0 as number,
      district: getInitialDistrict() as ChurchDistrict,
      contactDataEmail: '',
      contactDataPhone: '',
      contactDataFirstName: '',
      contactDataLastName: '',
      coachName: '',
      coachUser: '', // Contains firestore id
      surveyForm: new ChurchSurveyForm({
        surveyDate: new ChurchSurveyFormDates(),
        customQuestions: []
      }),
      districtId: -1,
      addressCity: '',
      addressStreet: '',
      addressState: '',
      addressZip: '',
      surveyFormOpenDate: DateTime.min(),
      surveyFormCloseDate: DateTime.min(),
      isCoachingCompleted: false,
      coachMeetingDate: DateTime.min(),
      inquiryForm: new ChurchSurveyForm({
        surveyDate: new ChurchSurveyFormDates(),
        customQuestions: []
      }),
      reportReviewed: false,
      isAutomaticallyCreated: false
    }),
    ...cleanedChurch
  });
  if (initialValues?.district?.id != null && initialValues?.district?.id !== -1) {
    initialValues.districtId = initialValues.district.id;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('ERRORS.TEXT_CHURCH_NAME_IS_REQUIRED')),
    coachUser: Yup.mixed().nullable(),
    contactDataEmail: Yup.string()
      .email(t('ERRORS.TEXT_CONTACT_EMAIL_IS_NOT_VALID'))
      .required(t('ERRORS.TEXT_CONTACT_EMAIL_IS_REQUIRED')),
    contactDataLastName: Yup.string().required(t('ERRORS.TEXT_CONTACT_LAST_NAME_IS_REQUIRED')),
    contactDataFirstName: Yup.string().required(t('ERRORS.TEXT_CONTACT_FIRST_NAME_IS_REQUIRED')),
    contactDataPhone: Yup.string()
      .phone('US', false, t('ERRORS.TEXT_CONTACT_PHONE_NUMBER_IS_NOT_VALID'))
      .required(t('ERRORS.TEXT_CONTACT_PHONE_NUMBER_IS_REQUIRED')),
    averageAdultAttendance:
      initialValues.status === ChurchStatus.INQUIRY
        ? Yup.string().matches(/^\d+$/, t('ERRORS.TEXT_AVERAGE_ADULT_ATTENDANCE_IS_NOT_VALID'))
        : Yup.string()
            .required(t('ERRORS.TEXT_AVERAGE_ADULT_ATTENDANCE_IS_REQUIRED'))
            .matches(/^\d+$/, t('ERRORS.TEXT_AVERAGE_ADULT_ATTENDANCE_IS_NOT_VALID')),
    surveyFormOpenDate: Yup.date()
      .transform(YupDateParser)
      .when(['closeDate'], (closeDate, schema) =>
        closeDate
          ? schema.max(closeDate, t('ERRORS.TEXT_OPEN_DATE_NEEDS_TO_BE_BEFORE_CLOSE_DATE'))
          : schema
      )
      .default(null)
      .nullable(),
    surveyFormCloseDate: Yup.date()
      .transform(YupDateParser)
      .when(['openDate'], (openDate, schema) => (openDate ? schema.min(openDate, ' ') : schema))
      .default(null)
      .nullable()
  });

  const setCoachesByDistrict = async (district: any, formikProps?: FormikProps<FormikValues>) => {
    setCoaches([]);

    const districtId = typeof district === 'number' ? district : district?.id;

    if (!districtId || districtId === -1) {
      if (formikProps) {
        formikProps.setFieldValue('coachUser', null);
        formikProps.setFieldValue('coaching.coach', '');
        formikProps.setFieldValue('coaching.meetingDate', '');
      }
      return;
    }

    const { data: users, error } = await supabase
      .from('users')
      .select('*')
      .eq('district_id', districtId);

    if (error) {
      throw error;
    }

    const coaches = users.map((user) => {
      const userData = plainToClass(User, user);

      return new SelectItem('user', userData.fullName, userData);
    });

    setCoaches(coaches);

    if (formikProps) {
      formikProps.setFieldValue('coachUser', null);
      formikProps.setFieldValue('coaching.coach', '');
      formikProps.setFieldValue('coaching.meetingDate', '');
    }
  };

  const getDistrictsSelectValues = (): Array<SelectItem<ChurchDistrict>> =>
    districts.map(
      (district) => new SelectItem<ChurchDistrict>('district', district.name, district)
    );

  const getTagsSelectValues = (): Array<SelectItem<ChurchTag>> =>
    tags
      .filter((tag) => tag.name !== null)
      .map((tag) => ({
        id: tag.id.toString(),
        value: new ChurchTag({ ...tag, churchId: undefined, tagId: undefined }),
        label: tag.name
      }));

  const onSubmit = async (values: FormikValues) => {
    setIsSubmitted(true);

    try {
      const selectedCoach = coaches.find((coach) => coach.value.id === values.coachUser.id);
      const updatedChurchData = new Church({
        ...values,
        coachName: selectedCoach ? selectedCoach?.label.toString() : '',
        coachUser: selectedCoach ? selectedCoach?.value?.id.toString() : null
      });

      const plainChurch = classToPlain(updatedChurchData);
      const data = omitBy(plainChurch, isEmpty);
      const coaching = omitBy(data.coaching, isEmpty);
      const survey_date = omitBy(data.survey_form?.survey_date, (value) => !value);
      const surveyForm = omitBy({ ...data.survey_form, survey_date }, isEmpty);

      const churchData = omitBy(
        {
          ...data,
          average_adult_attendance: values.averageAdultAttendance
            ? +values.averageAdultAttendance
            : null,
          coaching: isEmpty(coaching) ? null : coaching,
          survey_form: isEmpty(surveyForm) ? null : surveyForm,
          tags: plainChurch.tags
        },
        isNull
      );

      churchData.district_id = values.district.id;
      delete churchData.district;

      const tagsToInsert = churchData.tags;
      delete churchData.tags;

      let churchId;
      if (church) {
        const { error: fetchError } = await supabase
          .from('churches')
          .update(churchData)
          .match({ church_id: church.churchId });

        if (fetchError) {
          console.log('error updating:', fetchError);
        }
        churchId = church.churchId;
      } else {
        const { data } = await supabase.from('churches').insert([churchData]).select('church_id');
        console.log(churchData);
        churchId = data[0].church_id;
        churchData.churchId = churchId;

        try {
          await callSupabaseEdgeFunction('on-churches-create', 'POST', {
            church_id: churchId,
            name: churchData.name,
            districtId: churchData.district_id,
            status: churchData.status
          });

          console.log('Church successfully created');
        } catch (error) {
          console.error('Error calling on-churches-create:', error);
          return false;
        }
      }

      await updateChurchTags(churchData.name, churchId, tagsToInsert);

      dispatch({ type: AppActionTypes.CloseEditChurchModal });
      churchListDispatch({ type: ChurchListActionTypes.TriggerFetch });
    } catch (error) {
      setErrorResponse(error);
    } finally {
      setIsSubmitted(false);
    }
  };
  return (
    <Modal
      open={state.isEditChurchModalOpened}
      toggleClose={() => dispatch({ type: AppActionTypes.CloseEditChurchModal })}
      className="church-info-modal"
      title={t('TEXT_EDIT_CHURCH_INFO')}
    >
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        updateErrorResponse={setErrorResponse}
        validationSchema={validationSchema}
        isDisabled={isSubmitted}
      >
        {(props: FormikProps<FormikValues>): ReactElement => (
          <>
            <Row>
              <Col size={50}>
                <Field
                  name="name"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CHURCH_NAME')}
                  placeholder={t('TEXT_ENTER_CHURCH_NAME')}
                  error={props.errors.name}
                  touched={props.touched.name}
                  value={props.values.name}
                />
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Field
                  name="addressStreet"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_STREET')}
                  placeholder={t('TEXT_ENTER_STREET')}
                  error={getIn(props.errors, 'addressStreet')}
                  touched={getIn(props.touched, 'addressStreet')}
                  value={getIn(props.values, 'addressStreet')}
                />
              </Col>
              <Col size={50}>
                <Field
                  name="addressCity"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CITY_TOWN')}
                  placeholder={t('TEXT_ENTER_CITY_TOWN')}
                  error={getIn(props.errors, 'addressCity')}
                  touched={getIn(props.touched, 'addressCity')}
                  value={getIn(props.values, 'addressCity')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Field
                  name="addressState"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_STATE_PROVINCE')}
                  placeholder={t('TEXT_ENTER_STATE_PROVINCE')}
                  error={getIn(props.errors, 'addressState')}
                  touched={getIn(props.touched, 'addressState')}
                  value={getIn(props.values, 'addressState')}
                />
              </Col>
              <Col size={50}>
                <Field
                  name="addressZip"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_ZIP_CODE')}
                  placeholder={t('TEXT_ENTER_ZIP_CODE')}
                  error={getIn(props.errors, 'addressZip')}
                  touched={getIn(props.touched, 'addressZip')}
                  value={getIn(props.values, 'addressZip')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Field
                  name="contactDataFirstName"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CONTACT_FIRST_NAME')}
                  placeholder={t('TEXT_ENTER_CONTACT_FIRST_NAME')}
                  error={getIn(props.errors, 'contactDataFirstName')}
                  touched={getIn(props.touched, 'contactDataFirstName')}
                  value={getIn(props.values, 'contactDataFirstName')}
                />
              </Col>
              <Col size={50}>
                <Field
                  name="contactDataLastName"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CONTACT_LAST_NAME')}
                  placeholder={t('TEXT_ENTER_CONTACT_LAST_NAME')}
                  error={getIn(props.errors, 'contactDataLastName')}
                  touched={getIn(props.touched, 'contactDataLastName')}
                  value={getIn(props.values, 'contactDataLastName')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Field
                  name="contactDataPhone"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CONTACT_PHONE_NUMBER')}
                  placeholder={t('TEXT_ENTER_CONTACT_PHONE_NUMBER')}
                  error={getIn(props.errors, 'contactDataPhone')}
                  touched={getIn(props.touched, 'contactDataPhone')}
                  value={getIn(props.values, 'contactDataPhone')}
                />
              </Col>
              <Col size={50}>
                <Field
                  name="contactDataEmail"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CONTACT_EMAIL')}
                  placeholder={t('TEXT_ENTER_CONTACT_EMAIL')}
                  error={getIn(props.errors, 'contactDataEmail')}
                  touched={getIn(props.touched, 'contactDataEmail')}
                  value={getIn(props.values, 'contactDataEmail')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Field
                  name="churchCode"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_CMS_CHURCH_CODE')}
                  placeholder={t('TEXT_ENTER_CMS_CHURCH_CODE')}
                  error={getIn(props.errors, 'churchCode')}
                  touched={getIn(props.touched, 'churchCode')}
                  value={getIn(props.values, 'churchCode')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Field
                  name="averageAdultAttendance"
                  component={FormGroup}
                  onChange={props.handleChange}
                  type="text"
                  label={t('TEXT_AVERAGE_ADULT_ATTENDANCE')}
                  placeholder={t('TEXT_ENTER_AVERAGE_ATTENDANCE')}
                  error={props.errors.averageAdultAttendance}
                  touched={props.touched.averageAdultAttendance}
                  value={props.values.averageAdultAttendance}
                />
              </Col>

              <Col size={50}>
                <Field
                  name="district"
                  component={FormGroup}
                  onChange={(district) => setCoachesByDistrict(district, props)}
                  isDisabled={state.userClaims?.isDistrictUser}
                  type="select"
                  options={getDistrictsSelectValues()}
                  label={t('TEXT_DISTRICT')}
                  placeholder={t('TEXT_SELECT_DISTRICT')}
                  touched={props.touched.district}
                  value={props.values.district}
                />
              </Col>
            </Row>

            <Animation type="fade-in-down" animate={props.values.district}>
              <Row>
                <Col size={50}>
                  <Field
                    name="coachUser"
                    component={FormGroup}
                    type="select"
                    options={coaches.map((coach) => ({
                      value: coach.value.id,
                      label: coach.label
                    }))}
                    label={t('TEXT_COACH')}
                    placeholder={t('TEXT_SELECT_COACH')}
                    touched={props.touched.coachUser}
                    value={props.values.coachUser ? props.values.coachUser.id : ''}
                    onChange={(option) =>
                      props.setFieldValue('coachUser', option ? { id: option } : '')
                    }
                  />
                </Col>
                <Col size={50}>
                  <Animation type="fade-in" animate={props.values.coachUser}>
                    <Field
                      name="coachMeetingDate"
                      component={FormGroup}
                      type="date"
                      label={t('TEXT_COACHING_MEETING_DATE')}
                      placeholder={t('TEXT_ENTER_COACHING_MEETING_DATE')}
                      touched={getIn(props.touched, 'coachMeetingDate')}
                      value={getIn(props.values, 'coachMeetingDate')}
                    />
                  </Animation>
                </Col>
              </Row>
            </Animation>

            <div className="survey-dates">
              <Row>
                <Col size={50}>
                  <Field
                    name="surveyFormOpenDate"
                    component={FormGroup}
                    type="date"
                    label={t('TEXT_SURVEY_DATE')}
                    placeholder={t('TEXT_OPEN_DATE')}
                    error={getIn(props.errors, 'surveyFormOpenDate')}
                    touched={getIn(props.touched, 'surveyFormOpenDate')}
                    value={getIn(props.values, 'surveyFormOpenDate')}
                  />
                </Col>
                <Col size={50}>
                  <Field
                    name="surveyFormCloseDate"
                    component={FormGroup}
                    type="date"
                    className="form-group-survey-close-date"
                    placeholder={t('TEXT_CLOSE_DATE')}
                    error={getIn(props.errors, 'surveyFormCloseDate')}
                    touched={getIn(props.touched, 'surveyFormCloseDate')}
                    value={getIn(props.values, 'surveyFormCloseDate')}
                  />
                </Col>
              </Row>
            </div>
            <Field
              name="tags"
              component={FormCheckboxesGroups}
              label={t('TEXT_TAGS')}
              items={getTagsSelectValues()}
              error={props.errors.tags}
              touched={props.touched.tags}
              value={props.values.tags}
              checkedItems={props.values.tags}
            />

            <Field
              name="surveyForm.customQuestions[0]"
              component={FormGroup}
              onChange={props.handleChange}
              type="text"
              label={t('TEXT_QUESTION_1')}
              placeholder={t('TEXT_ENTER_QUESTION_1')}
              disabled={church?.isQuestionsDisabled}
              value={getIn(props.values, 'surveyForm.customQuestions[0]')}
            />

            <Field
              name="surveyForm.customQuestions[1]"
              component={FormGroup}
              onChange={props.handleChange}
              type="text"
              label={t('TEXT_QUESTION_2')}
              placeholder={t('TEXT_ENTER_QUESTION_2')}
              disabled={church?.isQuestionsDisabled}
              value={getIn(props.values, 'surveyForm.customQuestions[1]')}
            />

            <Field
              name="surveyForm.customQuestions[2]"
              component={FormGroup}
              onChange={props.handleChange}
              type="text"
              label={t('TEXT_QUESTION_3')}
              placeholder={t('TEXT_ENTER_QUESTION_3')}
              disabled={church?.isQuestionsDisabled}
              value={getIn(props.values, 'surveyForm.customQuestions[2]')}
            />

            {errorResponse && <FormServerError errorMessage={errorResponse.message} />}

            <FormButtons align="right">
              <Button color="green" isLoading={isSubmitted} type="submit">
                {t('BUTTON_SUBMIT')}
              </Button>
            </FormButtons>
          </>
        )}
      </Form>
    </Modal>
  );
}
