import { View as ReactPDFView } from '@react-pdf/renderer';
import { omit } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';
import { ElementUseFor } from '../Svg';

export interface IViewProperties {
  id?: string;
  wrap?: boolean;
  debug?: boolean;
  style?: any;
  useFor: ElementUseFor;
  children?: ReactNode;
  className?: string;
}

export default function View(props: IViewProperties): ReactElement {
  const { useFor, ...restProperties } = props;
  const divProperties = omit(restProperties, 'wrap', 'debug');

  return useFor === ElementUseFor.PDF ? <ReactPDFView {...restProperties} /> : <div {...divProperties} style={{ position: 'relative', ...props.style }}/>;
}
