import { Expose, Type } from 'class-transformer';
import { ChurchSurveyFormReportQuestion } from './question';

export class Demographics {
  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose()
  public gender!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose()
  public age!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'country_of_origin' })
  public countryOfOrigin!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'asian_country_of_origin' })
  public asianCountryOfOrigin!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'martial_status'})
  public martialStatus!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'accepted_salvation' })
  public acceptedSalvation!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'how_long_have_you_been_christian' })
  public howLongHaveBeenChristian!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'how_long_have__uou_attended_this_church' })
  public howLongHaveUouAttendedThisChurch!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'is_membership' })
  public isMembership!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose()
  public involvement!: ChurchSurveyFormReportQuestion;

  @Type(() => ChurchSurveyFormReportQuestion)
  @Expose({ name: 'how_long_is_your_commute_to_church'})
  public howLongIsYourCommuteToChurch!: ChurchSurveyFormReportQuestion;

  constructor(data: Partial<Demographics> = {}) {
    Object.assign(this, data);
  }
}
