import { Field } from 'formik';
import React, { Fragment, ReactElement, ReactNode, useContext, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Form from '../../../../components/Form/Form';
import { useTranslationByKey } from '../../../../hooks/use-translation-by-key';
import { ReportCommentData } from '../../../../models/report/comment-data';
import CommentEditForm from './CommentEditForm/CommentEditForm';
import './CommentBox.scss';
import CommentAction from './CommentAction/CommentAction';
import { ReportPanelType } from '../../ChurchReportModalPanel/ChurchReportModalPanel';
import { Church } from '../../../../models/church/Church';
import {
  ChurchListActionTypes,
  ChurchListContext
} from '../../../ChurchList/ChurchListContextProvider';
import supabase from '../../../../supabaseClient';
import { useSupabaseUser } from '../../../../useSupabaseUser';

export default function CommentBox({
  church,
  type
}: {
  church: Church;
  type: ReportPanelType;
}): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL.COMMENT_BOX');
  const user = useSupabaseUser();

  const { dispatch } = useContext(ChurchListContext);
  const [editingCommentIndex, setEditingCommentIndex] = useState<number>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<any>([]);

  const comments: Array<ReportCommentData> =
    church.reportComments?.[type] ?? ([] as Array<ReportCommentData>);

  const onCommentDelete = async (commentID: string, index: number): Promise<void> => {
    setIsDeleting({ [index]: true });

    const updatedReportComments = {
      ...church.reportComments,
      [type]: church.reportComments[type].filter((reportComment) => reportComment.id !== commentID)
    };

    const { error: updateError } = await supabase
      .from('churches')
      .update({ report_comments: updatedReportComments })
      .eq('churchCode', church?.churchCode);

    if (updateError) throw updateError;

    const { data: newChurch } = await supabase
      .from('churches')
      .select('*')
      .eq('churchCode', church?.churchCode)
      .single();

    dispatch({
      type: ChurchListActionTypes.UpdateSelectedCurch,
      payload: {
        church: newChurch
      }
    });

    setIsDeleting({ [index]: false });
  };

  const onCommentSubmit = async (values, { resetForm }): Promise<void> => {
    setIsSubmitting(true);
    const newComment: ReportCommentData = {
      body: values.body,
      author: user?.displayName,
      id: (Math.random() * 999999).toFixed(0),
      createdAt: new Date().toISOString(),
      updatedAt: ''
    };

    const reportComments = {
      ...church.reportComments,
      [type]: [...church.reportComments[type], newComment]
    };

    const { error: updateError } = await supabase
      .from('churches')
      .update({ report_comments: reportComments })
      .eq('churchCode', church?.churchCode);

    if (updateError) {
      throw updateError;
    }

    const { data: newChurch } = await supabase
      .from('churches')
      .select('*')
      .eq('churchCode', church?.churchCode)
      .single();

    dispatch({
      type: ChurchListActionTypes.UpdateSelectedCurch,
      payload: {
        church: newChurch
      }
    });

    setIsSubmitting(false);
    resetForm();
  };

  return (
    <div className="comment-box">
      <div className="comment-box-content">
        <p>
          <b>{t('TEXT_COMMENTS_TITLE')}</b>
        </p>
        <ul className="comment-box-list">
          {comments.map((comment: ReportCommentData, index: number): ReactNode => {
            const { body, author } = comment;
            const CommentEditFormView = (
              <CommentEditForm
                church={church}
                type={type}
                comment={comment}
                onCancel={(): void => {
                  setEditingCommentIndex(null);
                }}
              />
            );
            const CommentItemView = (
              <Fragment>
                {body} ( {author} )
                <CommentAction
                  isLoading={isDeleting[index]}
                  actions={[
                    {
                      className: 'comment-box-edit',
                      callback: (): void => {
                        setEditingCommentIndex(index);
                      }
                    },
                    {
                      className: 'comment-box-delete',
                      callback: (): void => {
                        onCommentDelete(comment.id, index);
                      }
                    }
                  ]}
                />
              </Fragment>
            );

            return (
              <Fragment key={index}>
                <li className="comment-box-list-item">
                  {editingCommentIndex === index ? CommentEditFormView : CommentItemView}
                </li>
                {index < comments.length - 1 ? <hr /> : <></>}
              </Fragment>
            );
          })}
        </ul>
      </div>
      <Form
        className="comment-box-form"
        initialValues={{
          body: ''
        }}
        onSubmit={onCommentSubmit}
      >
        {(): ReactElement => (
          <>
            <Field
              as="textarea"
              name="body"
              className="comment-box-form-body-input"
              placeholder={t('TEXT_COMMENT_BODY_PLACEHOLDER')}
            />
            <div className="comment-box-form-button-holder">
              <Button type="submit" color="blue-gray" size="small" isLoading={isSubmitting}>
                {t('BUTTON_SUBMIT')}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}
