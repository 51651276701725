import { ReactNode } from 'react';

export class SelectItem<T = number | string> {
  public id: string;
  public value: T;
  public label: string | ReactNode;

  constructor(id: string, label: string | ReactNode, value?: T) {
    this.id = id;
    this.value = value;
    this.label = label || value.toString();
  }
}
