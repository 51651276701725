import React, { InputHTMLAttributes, ReactElement, useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FieldInputProps, FormikProps } from 'formik/dist/types';
import { FormikValues } from 'formik';
import { FormControlSize } from './FormControl';
import { DateTime } from 'luxon';
import { DateUtils } from 'react-day-picker';
import classNames from 'classnames';
import './Form.scss';
import Icon from '../Icon/Icon';

interface Props {
  field?: FieldInputProps<string>;
  input?: InputHTMLAttributes<HTMLInputElement>;
  form?: FormikProps<FormikValues>;
  error?: string;
  touched?: boolean;
  upward?: boolean;
  onChange?: (_value: DateTime) => void;
  value?: DateTime;
  isError?: boolean;
  placeholder?: string;
  size?: FormControlSize;
}

export default function FormDatePickerControl(props: Props): ReactElement {
  const [isFilled, setFilled] = useState<boolean>(false);

  useEffect(
    () => setFilled(!!props.field.value)
  );

  const parseDate = (date: DateTime | string): Date => {
    const parsed = (date instanceof DateTime)
      ? date.toJSDate()
      : DateTime.fromFormat(date, 'M/d/yyyy').toJSDate();

    if (DateUtils.isDate(parsed)) {
      return parsed;
    }

    return undefined;
  };

  const formatDate = (date: Date | DateTime): string => (date instanceof DateTime)
    ? date.toFormat('M/d/yyyy')
    : DateTime.fromJSDate(date).toFormat('M/d/yyyy');

  const onChangeFormValue = (date: Date): void => {
    const formatDate = DateTime.fromJSDate(date);
    props.form.setFieldValue(props.field.name, formatDate);

    if (props.onChange) {
      props.onChange(formatDate);
    }
  };

  const getInputProps = (): Record<string, string> => ({
    readOnly: 'readonly',
    className: classNames(
      'form-control',
      'form-control-date-picker-input',
      `form-control-${props.size ?? 'normal'}`,
      {
        'form-control-filled': isFilled,
        'form-control-error': props.isError
      }
    )
  });

  return (
    <div className="form-control-date-picker-container">
      <div className={classNames('form-control-icon', 'form-control-icon-left')}>
        <Icon variant="calendar" />
      </div>
      <DayPickerInput
        {...props.field}
        value={(props?.value) ? props.value.toFormat('M/d/yyyy') : ''}
        formatDate={formatDate}
        inputProps={getInputProps()}
        parseDate={parseDate}
        onDayChange={onChangeFormValue}
        placeholder={props.placeholder}
        format="M/d/yyyy"
        classNames={{
          container: 'form-control-container',
          overlay: 'form-control-date-picker-overlay',
          overlayWrapper: 'form-control-date-picker'
        }}
      />
      <div className={classNames('form-control-icon', 'form-control-icon-right')}>
        <Icon variant="arrow-down" mask="blue-gray" />
      </div>
    </div>
  );
}
