import { StyleSheet } from '@react-pdf/renderer';
import { range } from 'lodash';
import React, { ReactElement } from 'react';
import { ChartArrayData } from '../../models/report/chart-array-data';
import Legends from './Legends';
import G from '../Svg/Elements/G';
import Line from '../Svg/Elements/Line';
import Path from '../Svg/Elements/Path';
import Rect from '../Svg/Elements/Rect';
import Text from '../Svg/Elements/Text';
import View from '../Svg/Elements/View';
import Svg, { ElementUseFor } from '../Svg/Svg';

export default function LineChart({ values, labels, useFor }: {
  values: Array<ChartArrayData>;
  labels: Array<string>;
  useFor: ElementUseFor;
}): ReactElement {
  const IS_MIN_WINDOW_WIDTH = window.innerWidth < 1300;
  const PADDING = 20;
  const STEP_GRAPH = IS_MIN_WINDOW_WIDTH ? 55 : 80;
  const SCALE_GRAPH = useFor === ElementUseFor.PDF ? 50 : 80;
  const SCALE_NUMBER = 5;
  const VALUE_LABEL_SPACE = PADDING * 2;
  const GRAPH_POINT_SIZE = 6;
  const LABEL_MARGIN = 5;

  const svgWidth = VALUE_LABEL_SPACE + PADDING + (STEP_GRAPH * labels.length) + PADDING;
  const svgHeight = PADDING + (SCALE_GRAPH * (SCALE_NUMBER - 1)) + PADDING;
  
  const styles = StyleSheet.create({
    chart: {
      padding: '0 15px'
    },
    labels: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: (PADDING + VALUE_LABEL_SPACE) - (STEP_GRAPH / 3),
      width: useFor === ElementUseFor.PDF ? 'auto' : 'calc(100% - 100px)'
    },
    label: {
      width: STEP_GRAPH,
      fontSize: IS_MIN_WINDOW_WIDTH ? 9 : 11,
      textAlign: 'center',
      padding: '0 5px'
    },
    value: {
      fontSize: 11,
      textAnchor: 'middle',
      dominantBaseline: 'text-after-edge'
    },
    valueLabel: {
      fontSize: 12,
      dominantBaseline: 'middle'
    },
    legends: {
      marginRight: SCALE_GRAPH
    }
  });

  function buildPath(values: Array<[number, number]>): string {
    return values.map((item, i) => `${(i === 0) ? 'M' : 'L'} ${item[0]} ${item[1]}`).join(' ');
  }

  function getPoints(values: Array<number>): Array<{ value: number, point: [number, number]}> {
    let shift = PADDING + VALUE_LABEL_SPACE;

    return values.map((value) => {
      const point: [number, number] = [shift, (SCALE_GRAPH * SCALE_NUMBER) - (value * SCALE_GRAPH) + PADDING];
      shift = shift + STEP_GRAPH + 6;

      return { value, point };
    });
  }

  function getValueLabelPosition(position: number): {x: number, y: number} {
    return { x: 0, y: PADDING + (SCALE_GRAPH * position) };
  }

  function getScaleLinePoints(position: number, size: number): { x1: number, y1: number, x2: number, y2: number } {
    return {
      x1: VALUE_LABEL_SPACE,
      y1: PADDING + (SCALE_GRAPH * position),
      x2: VALUE_LABEL_SPACE + PADDING + ((size) * STEP_GRAPH) + PADDING,
      y2: PADDING + (SCALE_GRAPH * position)
    };
  }

  return (
    <View wrap={false} style={styles.chart} useFor={useFor}>
      <Svg className="line-chart" width={svgWidth} height={svgHeight} useFor={useFor}>
        {range(1,6).reverse().map((item) => item.toFixed(1)).map((item, i) => <G key={item} useFor={useFor}>
          <Text {...getValueLabelPosition(i)} style={styles.valueLabel} useFor={useFor}>{item}</Text>
          <Line {...getScaleLinePoints(i, values[0].values.length)} stroke="#979797" useFor={useFor}/>
        </G>)}
        {values.map((item, i) => <Path key={i} d={buildPath(getPoints(item.values).map((item) => item.point))} stroke={item.color}
          strokeWidth="2" useFor={useFor} fill={useFor === ElementUseFor.BROWSER ? 'transparent' : ''}/>)}
        {values.map((item, i) => <G key={i} useFor={useFor}>
          {getPoints(item.values).map((nestedItem, i) => <G key={i} useFor={useFor}>
            <Text x={nestedItem.point[0]} y={nestedItem.point[1] - LABEL_MARGIN} fill={item.color} style={styles.value} useFor={useFor}>{nestedItem.value.toFixed(1)}</Text>
            <Rect x={nestedItem.point[0] - 3} y={nestedItem.point[1] - (GRAPH_POINT_SIZE / 2)} width={GRAPH_POINT_SIZE} height={GRAPH_POINT_SIZE} fill={item.color} useFor={useFor}/>
          </G>
          )}
        </G>)}
      </Svg>
      <View style={styles.labels} useFor={useFor}>
        {labels.map((item) => <View key={item} style={styles.label} useFor={useFor}>
          <Text useFor={useFor} insideSVG={false}>{item.split(' ')[0]}</Text>
          <Text useFor={useFor} insideSVG={false}>{item.split(' ')[1]}</Text>
        </View>)}
      </View>
      {values.length > 1 && <View style={styles.legends} useFor={useFor}><Legends legends={values} useFor={useFor} /></View>}
    </View>
  );
}
