import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import DateTransformer from '../../helpers/DateTransformer';
import { ChurchFormAnswer } from './ChurchFormAnswer';

export class ChurchFormResponse {
  @Expose()
  public id!: string;

  @Transform(DateTransformer)
  @Expose({ name: 'date_created' })
  public dateCreated!: DateTime;

  @Expose({ name: 'analyze_url' })
  public analyzeUrl!: string;

  @Expose()
  @Type(() => ChurchFormAnswer)
  public answers!: Array<ChurchFormAnswer>;

  constructor(data: Partial<ChurchFormResponse> = {}) {
    Object.assign(this, data);
  }
}
