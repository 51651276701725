import React, { ReactElement, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../components/Form/FormGroup';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import Icon from '../../components/Icon/Icon';
import FormServerError from '../../components/Form/FormServerError';
import { connect } from 'react-redux';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import supabase from '../../supabaseClient';

function ResetPasswordForm(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.RESET_PASSWORD_FORM');

  const [errorResponse, setErrorResponse] = useState<Error | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('ERRORS.TEXT_INVALID_EMAIL_ADDRESS'))
      .required(t('ERRORS.TEXT_EMAIL_IS_REQUIRED'))
  });

  const onSubmit = async (values: FormikValues) => {
    setErrorResponse(null);
    setIsEmailSent(false);
    setIsSubmitting(true);

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(values.email, {
        redirectTo: `${window.location.origin}/password-reset-confirm`
      });
      if (error) {
        console.log('error :>> ', error);
      }
      setIsEmailSent(true);
    } catch (error) {
      setErrorResponse(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props: FormikProps<FormikValues>): ReactElement => {
        const { values } = props;

        useEffect(() => {
          setErrorResponse(null);
        }, [values]);

        return (
          <Form className="form reset-password-form">
            <h1>{t('TEXT_RESET_PASSWORD')}</h1>

            <Field
              name="email"
              component={FormGroup}
              icon={<Icon variant="user" />}
              onChange={props.handleChange}
              type="email"
              placeholder={t('TEXT_EMAIL')}
              error={props.errors.email}
              touched={props.touched.email}
              value={props.values.email}
            />

            {errorResponse && <FormServerError errorMessage={errorResponse.message} />}

            {isEmailSent && (
              <div className="auth-form-response">
                {t('TEXT_YOU_WILL_RECEIVE_A_PASSWORD_RECOVERY_LINK')}
              </div>
            )}

            <div className="form-buttons">
              <Button color="blue-gray" isBlock={true} isLoading={isSubmitting} type="submit">
                {t('BUTTON_RESET_PASSWORD')}
              </Button>

              <Link to="/login" variant="light-gray">
                {t('TEXT_SIGN_IN')}
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default connect(null, null)(ResetPasswordForm);
