import { StyleSheet } from '@react-pdf/renderer';
import { max } from 'lodash';
import React, { ReactElement } from 'react';
import { ChartData } from '../../models/report/chart-data';
import G from '../Svg/Elements/G';
import Rect from '../Svg/Elements/Rect';
import Text from '../Svg/Elements/Text';
import Svg, { ElementUseFor } from '../Svg/Svg';

export default function BarChart({ values, useFor }: { values: Array<ChartData>; useFor: ElementUseFor; }): ReactElement {
  const SVG_WIDTH = 180;
  const SVG_HEIGHT = 150;
  const GAP_BETWEEN_BAR = 25;
  const WIDTH_BAR = 10;
  const MAX_SIZE_BAR = SVG_HEIGHT - 20;
  const TOP_PADDING = 15;
  const TEXT_PADDING = 5;

  const styles = StyleSheet.create({
    chart: {},
    label: {
      fontSize: 12,
      textAnchor: 'middle',
      dominantBaseline: 'text-before-edge'
    },
    value: {
      fontSize: 12,
      textAnchor: 'middle'
    }
  });

  function getValues(): Array<{ value: number, color: string, label?: string, height: number, shift: number }> {
    const maxValue = max(values.map((item) => item.value));
    const shift = (SVG_WIDTH - ((values.length * WIDTH_BAR) + ((values.length - 1) * GAP_BETWEEN_BAR))) / 2;

    return values.map((item, i) => ({
      ...item,
      height: (MAX_SIZE_BAR - TOP_PADDING) * item.value / maxValue,
      shift: (i * GAP_BETWEEN_BAR) + (i * WIDTH_BAR) + shift
    }));
  }

  function getValuePosition(shift: number, height: number): { x: number, y: number } {
    return { x: shift + (WIDTH_BAR / 2), y: MAX_SIZE_BAR - (height + TEXT_PADDING) };
  }

  function getLabelPosition(shift: number): { x: number, y: number } {
    return { x: shift + (WIDTH_BAR / 2), y: MAX_SIZE_BAR + TEXT_PADDING };
  }

  function getBarProps(shift: number, height: number): { x: number, y: number, width: number, height: number} {
    return {
      x: shift,
      y: MAX_SIZE_BAR - height,
      width: WIDTH_BAR,
      height: height
    };
  }

  return (
    <Svg width={SVG_WIDTH} height={SVG_HEIGHT} style={styles.chart} useFor={useFor}>
      {getValues().map((item) => <G key={item.label} useFor={useFor}>
        <Text {...getValuePosition(item.shift, item.height)} style={styles.value} useFor={useFor}>{item.value}</Text>
        <Rect {...getBarProps(item.shift, item.height)} fill={item.color} useFor={useFor}/>
        <Text {...getLabelPosition(item.shift)} style={styles.label} useFor={useFor}>{item.label}</Text>
      </G>)}
    </Svg>
  );
}
