import { Expose, Type } from 'class-transformer';
import { ChurchForm } from './ChurchForm';
import { ChurchSurveyFormDates } from './ChurchSurveyFormDates';
import { ChurchSurveyFormResult } from './ChurchSurveyFormResult';

export class ChurchSurveyForm extends ChurchForm {
  @Expose({ name: 'sent_curves' })
  public sentCurves: boolean;

  @Expose({ name: 'is_sent' })
  public isSent: boolean;

  @Type(() => ChurchSurveyFormDates)
  @Expose({ name: 'survey_date' })
  public surveyDate!: ChurchSurveyFormDates;

  @Expose({ name: 'custom_questions' })
  public customQuestions: Array<string>;

  @Expose({ name: 'responses_ids' })
  public responsesIDs: Array<string>;

  @Expose()
  @Type(() => ChurchSurveyFormResult)
  public result: ChurchSurveyFormResult;

  constructor(data: Partial<ChurchSurveyForm> = {}) {
    super(data);
    Object.assign(this, data);
  }
}
