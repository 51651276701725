import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import './CommentAction.scss';

interface ICommentAction {
  type?: 'submit' | 'button';
  className: string;
  callback?(): void;
}

export default function CommentAction({ actions, isLoading = false }: { actions: Array<ICommentAction>; isLoading?: boolean }): ReactElement {
  return (
  <div className="comment-action">
    { isLoading 
      ?
      <svg className="comment-action-button" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#0a0a0a" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
      : 
      actions.map((action: ICommentAction, index: number): ReactNode => {
        const { type = 'button', className, callback } = action;
        const actionClassName = classNames('comment-action-button', className);

        return <button type={type} className={actionClassName} onClick={callback} key={index}></button>;
      })
    }
  </div>
);
}
