import { Exclude, Expose, Transform } from 'class-transformer';
import { DateTime, Interval } from 'luxon';
import DateTransformer from '../../helpers/DateTransformer';

export class ChurchSurveyFormDates {
  @Expose({ name: 'open_date' })
  @Transform(DateTransformer)
  public openDate: DateTime;

  @Expose({ name: 'close_date' })
  @Transform(DateTransformer)
  public closeDate: DateTime;

  @Exclude()
  public get isNow(): boolean {
    return this.interval?.contains(DateTime.utc());
  }

  @Exclude()
  public get isFuture(): boolean {
    return this.interval?.isAfter(DateTime.utc());
  }

  @Exclude()
  public get isExpired(): boolean {
    return this.interval?.isBefore(DateTime.utc());
  }

  @Exclude()
  private get interval(): Interval {
    if (this.openDate && this.closeDate) {
      return Interval.fromDateTimes(this.openDate, this.closeDate);
    }
  }

  constructor(data: Partial<ChurchSurveyFormDates> = {}) {
    Object.assign(this, data);
  }
}
