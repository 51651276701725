import { DateTime } from 'luxon';
import React, { ReactElement, useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import 'yup-phone';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Modal from '../../components/Modal/Modal';
import SurveyRow from '../../components/SurveyRow/SurveyRow';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import './SurveyViewModal.scss';

export default function SurveyViewModal(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.SURVEY_VIEW_MODAL');

  const { state, dispatch } = useContext(AppContext);
  const formatDate = (date: DateTime): string => date.isValid && date.toFormat('M/d/yyyy');

  const survey = state.surveyToView;

  return (
    <Modal
      open={state.isSurveyViewModalOpened}
      toggleClose={() => dispatch({ type: AppActionTypes.CloseSurveyViewModal })}
      className="survey-view-modal"
      title={`${t('TEXT_CHURCH')}: ${survey?.churchName}`}
    >
      <Scrollbars style={{ width: '100%', height: '100%' }} autoHide autoHideTimeout={100}>
        {!!survey && (
          <>
            <h2 className="survey-view-modal-title">
              {t('TEXT_FORM')}: {survey.formName}
            </h2>
            <h3>
              {t('DATE_CREATED')}:{' '}
              {formatDate(survey.dateCreated) ? formatDate(survey.dateCreated) : 'N/A'}
            </h3>
            <br />
            <br />
            {survey.questionsWithAnswers.map((item, index) => (
              <SurveyRow
                key={item.text}
                questionNumber={t('TEXT_QUESTION_NUMBER') + (index + 1)}
                question={item}
              />
            ))}
          </>
        )}
      </Scrollbars>
    </Modal>
  );
}
